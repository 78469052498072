const actions = {
  FETCH_CONNECTION: "FETCH_CONNECTION",
  FETCH_CONNECTION_SUCCESS: "FETCH_CONNECTION_SUCCESS",
  FETCH_CONNECTION_FAILURE: "FETCH_CONNECTION_FAILURE",

  ADD_CONNECTION: "ADD_CONNECTION",
  ADD_CONNECTION_SUCCESS: "ADD_CONNECTION_SUCCESS",
  ADD_CONNECTION_FAILURE: "ADD_CONNECTION_FAILURE",

  UPDATE_CONNECTION: "UPDATE_CONNECTION",
  UPDATE_CONNECTION_SUCCESS: "UPDATE_CONNECTION_SUCCESS",
  UPDATE_CONNECTION_FAILURE: "UPDATE_CONNECTION_FAILURE",

  DELETE_CONNECTION: "DELETE_CONNECTION",
  DELETE_CONNECTION_SUCCESS: "DELETE_CONNECTION_SUCCESS",
  DELETE_CONNECTION_FAILURE: "DELETE_CONNECTION_FAILURE",
};

export default actions;
