import actions from "./actions";

const initialState = {
  isRequested: false,
  user: null,
  isRequesting: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.REQUESTRESET:
      return {
        ...state,
        isRequested: true,
      };
    case actions.REQUESTRESET_SUCCESS:
      return {
        ...state,
        isRequested: true,
        isRequesting: false,
        user: action.payload,
      };
    case actions.REQUESTRESET_FAILURE:
      return { ...state, isRequested: false, isRequesting: false };

    default:
      return state;
  }
}

export default Reducer;
