import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { deleteRequest, getRequest, postRequest, putRequest } from "utils/axios-client";

function* getCentralType() {
  try {
    const response = yield callApi(() => getRequest('cms/fire-alarm/central-type'))
    yield put({
      type: actions.FETCH_CENTRAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_CENTRAL_FAILURE });
  }
}

function* addCentralType(action) {
  try {
    yield callApi(() => postRequest('cms/fire-alarm/central-type', action.payload))
    yield put({
      type: actions.ADD_CENTRAL_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_CENTRAL_FAILURE });
  }
}

function* editCentralType(action) {
  try {
    yield callApi(() => putRequest(`cms/fire-alarm/central-type?id=${action.id}`, action.payload))
    yield put({
      type: actions.UPDATE_CENTRAL_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_CENTRAL_FAILURE });
  }
}

function* deleteCentralType(action) {
  try {
    yield callApi(() => deleteRequest(`cms/fire-alarm/central-type?id=${action.id}`))
    yield put({
      type: actions.DELETE_CENTRAL_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_CENTRAL_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_CENTRAL, getCentralType)]);
  yield all([takeLatest(actions.ADD_CENTRAL, addCentralType)]);
  yield all([takeLatest(actions.UPDATE_CENTRAL, editCentralType)]);
  yield all([takeLatest(actions.DELETE_CENTRAL, deleteCentralType)]);

}
