import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequestNoToken,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* getCmsPosition() {
  try {
    const response = yield callApi(() => getRequestNoToken("cms/position"));
    yield put({
      type: actions.FETCH_CMS_POSITION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_CMS_POSITION_FAILURE });
  }
}

function* addCmsPosition(action) {
  try {
    yield callApi(() => postRequest("cms/position", action.payload));
    yield put({
      type: actions.ADD_CMS_POSITION_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_CMS_POSITION_FAILURE });
  }
}

function* editCmsPosition(action) {
  try {
    yield callApi(() =>
      putRequest(`cms/position?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_CMS_POSITION_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_CMS_POSITION_FAILURE });
  }
}

function* deleteCmsPosition(action) {
  try {
    yield callApi(() => deleteRequest(`cms/position?id=${action.id}`));
    yield put({
      type: actions.DELETE_CMS_POSITION_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_CMS_POSITION_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_CMS_POSITION, getCmsPosition)]);
  yield all([takeLatest(actions.ADD_CMS_POSITION, addCmsPosition)]);
  yield all([takeLatest(actions.UPDATE_CMS_POSITION, editCmsPosition)]);
  yield all([takeLatest(actions.DELETE_CMS_POSITION, deleteCmsPosition)]);
}
