import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest } from "utils/axios-client";

function* fetchUserStatistics(action) {
  try {
    const response = yield callApi(() =>
      getRequest(
        `user/statistics?startDate=${action.startDate}&endDate=${action.endDate}`
      )
    );
    yield put({
      type: actions.FETCH_STATISTICS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_STATISTICS_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_STATISTICS, fetchUserStatistics)]);
}
