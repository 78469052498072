import actions from "./actions";

const initialState = {
  buildingChecklist: [],
  isFetchingBuildingChecklist: false,
  isChangedAddBuildingChecklist: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_CHECKLIST:
      return {
        ...state,
        buildingChecklist: [],
        isFetchingBuildingChecklist: true,
        isChangedAddBuildingChecklist: null,
      };
    case actions.FETCH_BUILDING_CHECKLIST_SUCCESS:
      return {
        ...state,
        isFetchingBuildingChecklist: false,
        buildingChecklist: action.payload,
      };
    case actions.FETCH_BUILDING_CHECKLIST_FAILURE:
      return { ...state, isFetchingBuildingChecklist: false };

    case actions.ADD_BUILDING_CHECKLIST:
      return { ...state, isChangedAddBuildingChecklist: null };
    case actions.ADD_BUILDING_CHECKLIST_SUCCESS:
      return { ...state, isChangedAddBuildingChecklist: true };
    case actions.ADD_BUILDING_CHECKLIST_FAILURE:
      return { ...state, isChangedAddBuildingChecklist: false };

    case actions.UPDATE_BUILDING_CHECKLIST:
      return { ...state, isChangedAddBuildingChecklist: null };
    case actions.UPDATE_BUILDING_CHECKLIST_SUCCESS:
      return { ...state, isChangedAddBuildingChecklist: true };
    case actions.UPDATE_BUILDING_CHECKLIST_FAILURE:
      return { ...state, isChangedAddBuildingChecklist: false };

    case actions.DELETE_BUILDING_CHECKLIST:
      return { ...state, isChangedAddBuildingChecklist: null };
    case actions.DELETE_BUILDING_CHECKLIST_SUCCESS:
      return { ...state, isChangedAddBuildingChecklist: true };
    case actions.DELETE_BUILDING_CHECKLIST_FAILURE:
      return { ...state, isChangedAddBuildingChecklist: false };

    case actions.RESET_BUILDING_CHECKLIST:
      return { ...state, isChangedAddBuildingChecklist: null };

    default:
      return state;
  }
}

export default Reducer;
