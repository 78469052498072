const actions = {
  FETCH_BUILDING_MANAGER: "FETCH_BUILDING_MANAGER",
  FETCH_BUILDING_MANAGER_SUCCESS: "FETCH_BUILDING_MANAGER_SUCCESS",
  FETCH_BUILDING_MANAGER_FAILURE: "FETCH_BUILDING_MANAGER_FAILURE",

  CREATE_BUILDING_MANAGER: "CREATE_BUILDING_MANAGER",
  CREATE_BUILDING_MANAGER_SUCCESS: "CREATE_BUILDING_MANAGER_SUCCESS",
  CREATE_BUILDING_MANAGER_FAILURE: "CREATE_BUILDING_MANAGER_FAILURE",

  UPDATE_BUILDING_MANAGER: "UPDATE_BUILDING_MANAGER",
  UPDATE_BUILDING_MANAGER_SUCCESS: "UPDATE_BUILDING_MANAGER_SUCCESS",
  UPDATE_BUILDING_MANAGER_FAILURE: "UPDATE_BUILDING_MANAGER_FAILURE",  
};

export default actions;
