const actions = {
  FETCH_BUILDING_OWNER: "FETCH_BUILDING_OWNER",
  FETCH_BUILDING_OWNER_SUCCESS: "FETCH_BUILDING_OWNER_SUCCESS",
  FETCH_BUILDING_OWNER_FAILURE: "FETCH_BUILDING_OWNER_FAILURE",

  CREATE_BUILDING_OWNER: "CREATE_BUILDING_OWNER",
  CREATE_BUILDING_OWNER_SUCCESS: "CREATE_BUILDING_OWNER_SUCCESS",
  CREATE_BUILDING_OWNER_FAILURE: "CREATE_BUILDING_OWNER_FAILURE",

  UPDATE_BUILDING_OWNER: "UPDATE_BUILDING_OWNER",
  UPDATE_BUILDING_OWNER_SUCCESS: "UPDATE_BUILDING_OWNER_SUCCESS",
  UPDATE_BUILDING_OWNER_FAILURE: "UPDATE_BUILDING_OWNER_FAILURE",  
};

export default actions;
