const actions = {
  CREATE_TOKENS: "CREATE_TOKENS",
  CREATE_TOKENS_SUCCESS: "CREATE_TOKENS_SUCCESS",
  CREATE_TOKENS_FAILURE: "CREATE_TOKENS_FAILURE",

  CREATE_EVENT: "CREATE_EVENT",
  CREATE_EVENT_SUCCESS: "CREATE_EVENT_SUCCESS",
  CREATE_EVENT_FAILURE: "CREATE_EVENT_FAILURE",
};

export default actions;
