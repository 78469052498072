import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* getBuildingChecklist(action) {
  try {
    const response = action.buildingId
      ? yield callApi(() =>
          getRequest(`building/checklist?buildingId=${action.buildingId}`)
        )
      : yield callApi(() => getRequest(`building/checklist`));
    yield put({
      type: actions.FETCH_BUILDING_CHECKLIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_CHECKLIST_FAILURE });
  }
}

function* addBuildingChecklist(action) {
  try {
    yield callApi(() => postRequest(`building/checklist`, action.payload));
    yield put({
      type: actions.ADD_BUILDING_CHECKLIST_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_BUILDING_CHECKLIST_FAILURE });
  }
}

function* editBuildingChecklist(action) {
  try {
    yield callApi(() =>
      putRequest(`building/checklist?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_BUILDING_CHECKLIST_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_CHECKLIST_FAILURE });
  }
}

function* deleteBuildingChecklist(action) {
  try {
    action.id
      ? yield callApi(() => deleteRequest(`building/checklist?id=${action.id}`))
      : yield callApi(() =>
          deleteRequest(`building/checklist?buildingId=${action.buildingId}`)
        );
    yield put({
      type: actions.DELETE_BUILDING_CHECKLIST_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_BUILDING_CHECKLIST_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.FETCH_BUILDING_CHECKLIST, getBuildingChecklist),
  ]);
  yield all([takeLatest(actions.ADD_BUILDING_CHECKLIST, addBuildingChecklist)]);
  yield all([
    takeLatest(actions.UPDATE_BUILDING_CHECKLIST, editBuildingChecklist),
  ]);
  yield all([
    takeLatest(actions.DELETE_BUILDING_CHECKLIST, deleteBuildingChecklist),
  ]);
}
