import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { deleteRequest, getRequest, postRequest, putRequest } from "utils/axios-client";

function* getConnection() {
  try {
    const response = yield callApi(() => getRequest('cms/fire-alarm/connection'))
    yield put({
      type: actions.FETCH_CONNECTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_CONNECTION_FAILURE });
  }
}

function* addConnection(action) {
  try {
    yield callApi(() => postRequest('cms/fire-alarm/connection', action.payload))
    yield put({
      type: actions.ADD_CONNECTION_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_CONNECTION_FAILURE });
  }
}

function* editConnection(action) {
  try {
    yield callApi(() => putRequest(`cms/fire-alarm/connection?id=${action.id}`, action.payload))
    yield put({
      type: actions.UPDATE_CONNECTION_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONNECTION_FAILURE });
  }
}

function* deleteConnection(action) {
  try {
    yield callApi(() => deleteRequest(`cms/fire-alarm/connection?id=${action.id}`))
    yield put({
      type: actions.DELETE_CONNECTION_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_CONNECTION_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_CONNECTION, getConnection)]);
  yield all([takeLatest(actions.ADD_CONNECTION, addConnection)]);
  yield all([takeLatest(actions.UPDATE_CONNECTION, editConnection)]);
  yield all([takeLatest(actions.DELETE_CONNECTION, deleteConnection)]);

}
