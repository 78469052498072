import actions from "./actions";

const initialState = {
  subscriptions: [],
  subscription_plans: [],
  subscription_roles: [],
  isFetchingSubscriptions: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        isFetchingSubscriptions: true,
      };
    case actions.FETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isFetchingSubscriptions: false,
        subscriptions: action.payload,
        subscription_plans: action.payload_plan,
        subscription_roles: action.payload_role,
      };
    case actions.FETCH_SUBSCRIPTIONS_FAILURE:
      return { ...state, isFetchingSubscriptions: false };

    case actions.CREATE_SUBSCRIPTIONS:
      return { ...state, isFetchingSubscriptions : true}

    case actions.CREATE_SUBSCRIPTIONS_SUCCESS: 
      return { ...state, isFetchingSubscriptions: false}

    case actions.CREATE_SUBSCRIPTIONS_FAILURE:
      return { ...state, isFetchingSubscriptions: false}
      
    default:
      return state;
  }
}

export default Reducer;
