import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* getBuildingCondition(action) {
  try {
    const response = yield callApi(() =>
      getRequest(`building/condition?buildingId=${action.buildingId}`)
    );
    yield put({
      type: actions.FETCH_BUILDING_CONDITION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_CONDITION_FAILURE });
  }
}

function* addBuildingCondition(action) {
  try {
    yield callApi(() => postRequest("building/condition", action.payload));
    yield put({
      type: actions.ADD_BUILDING_CONDITION_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_BUILDING_CONDITION_FAILURE });
  }
}

function* editBuildingCondition(action) {
  try {
    yield callApi(() => putRequest(`building/condition`, action.payload));
    yield put({
      type: actions.UPDATE_BUILDING_CONDITION_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_CONDITION_FAILURE });
  }
}

function* deleteBuildingCondition(action) {
  try {
    yield callApi(() => deleteRequest(`building/condition?id=${action.id}`));
    yield put({
      type: actions.DELETE_BUILDING_CONDITION_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_BUILDING_CONDITION_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.FETCH_BUILDING_CONDITION, getBuildingCondition),
  ]);
  yield all([takeLatest(actions.ADD_BUILDING_CONDITION, addBuildingCondition)]);
  yield all([
    takeLatest(actions.UPDATE_BUILDING_CONDITION, editBuildingCondition),
  ]);
  yield all([
    takeLatest(actions.DELETE_BUILDING_CONDITION, deleteBuildingCondition),
  ]);
}
