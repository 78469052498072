import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchBuildingManager(action) {
  try {
    const response = yield callApi(() => getRequest(`building/manager?buildingId=${action.payload}`));
    yield put({
      type: actions.FETCH_BUILDING_MANAGER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_MANAGER_FAILURE });
  }
}

function* createBuildingManager(action) {
  try {
    const response = yield callApi(() => postRequest('building/manager', action.payload))
    yield put({
      type: actions.CREATE_BUILDING_MANAGER_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.CREATE_BUILDING_MANAGER_FAILURE });
  }
}

function* updateBuildingManager(action) {
  try {
    const response = yield callApi(() => putRequest('building/manager', action.payload))
    yield put({
      type: actions.UPDATE_BUILDING_MANAGER_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_MANAGER_FAILURE });
  }
}


export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_BUILDING_MANAGER, fetchBuildingManager)]);
  yield all([takeLatest(actions.CREATE_BUILDING_MANAGER, createBuildingManager)]);
  yield all([takeLatest(actions.UPDATE_BUILDING_MANAGER, updateBuildingManager)]);
}
