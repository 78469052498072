import actions from "./actions";

const initialState = {
  buildingConformityReport: [],
  isFecthingBuildingConformityReport: false,
  buildingCreatedId: null,
  buildingConformityReportFile: null,
  buildingConformityReportCustomers: [],
  buildingConformityReportHistories: [],
  isFecthingBuildingConformityReportHistories: false,
  isFinishedBuildingConformityReports: null,
  isFinishedBuildingConformityReportsFile: null,
  isFinishedBuildingConformityReportsFileConfirm: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_CONFORMITY_REPORT:
      return {
        ...state,
        isFecthingBuildingConformityReport: true,
      };
    case actions.FETCH_BUILDING_CONFORMITY_REPORT_SUCCESS:
      return {
        ...state,
        isFecthingBuildingConformityReport: false,
        buildingConformityReport: action.payload,
        buildingConformityReportCustomers: action.payloadCustomer,
      };
    case actions.FETCH_BUILDING_CONFORMITY_REPORT_FAILURE:
      return { ...state, isFecthingBuildingConformityReport: false };

    case actions.FETCH_BUILDING_FIRE_HISTORIES:
      return {
        ...state,
        isFecthingBuildingConformityReportHistories: true,
      };
    case actions.FETCH_BUILDING_FIRE_HISTORIES_SUCCESS:
      return {
        ...state,
        isFecthingBuildingConformityReportHistories: false,
        buildingConformityReportHistories: action.payload,
      };
    case actions.FETCH_BUILDING_FIRE_HISTORIES_FAILURE:
      return { ...state, isFecthingBuildingConformityReportHistories: false };

    case actions.CREATE_BUILDING_CONFORMITY_REPORT:
      return { ...state, isFinishedBuildingConformityReports: null };
    case actions.CREATE_BUILDING_CONFORMITY_REPORT_SUCCESS:
      return { ...state, isFinishedBuildingConformityReports: true, buildingCreatedId: action.payload };
    case actions.CREATE_BUILDING_CONFORMITY_REPORT_FAILURE:
      return { ...state, isFinishedBuildingConformityReports: false };

    case actions.UPDATE_BUILDING_CONFORMITY_REPORT:
      return { ...state, isFinishedBuildingConformityReports: null };
    case actions.UPDATE_BUILDING_CONFORMITY_REPORT_SUCCESS:
      return { ...state, isFinishedBuildingConformityReports: true };
    case actions.UPDATE_BUILDING_CONFORMITY_REPORT_FAILURE:
      return { ...state, isFinishedBuildingConformityReports: false };

    default:
      return state;
  }
}

export default Reducer;
