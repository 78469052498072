import actions from "./actions";

const initialState = {
  exerciseUser: [],
  isFetchingExerciseUser: false,

  isCreatingExerciseUserByFile: false,
  isCreatingExerciseUser: false,
  isUpdatingExerciseUser: false,
  isCreatedExerciseUser: null,
  isDeletingExerciseUser: false,
  isFetchingExerciseUserFile: false,
  isUpdateAllExerciseUser: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_EXERCISE_USER:
      return {
        ...state,
        isFetchingExerciseUser: true,
      };
    case actions.FETCH_EXERCISE_USER_SUCCESS:
      return {
        ...state,
        isFetchingExerciseUser: false,
        exerciseUser: action.payload,
      };
    case actions.FETCH_EXERCISE_USER_FAILURE:
      return { ...state, isFetchingExerciseUser: false };

    case actions.CREATE_EXERCISE_USER_BY_FILE:
      return {
        ...state,
        isCreatingExerciseUserByFile: true,
        isCreatedExerciseUser: null,
      };
    case actions.CREATE_EXERCISE_USER_BY_FILE_SUCCESS:
      return {
        ...state,
        isCreatingExerciseUserByFile: false,
        isCreatedExerciseUser: true,
      };
    case actions.CREATE_EXERCISE_USER_BY_FILE_FAILURE:
      return {
        ...state,
        isCreatingExerciseUserByFile: false,
        isCreatedExerciseUser: false,
      };

    case actions.CREATE_EXERCISE_USER:
      return {
        ...state,
        isCreatingExerciseUser: true,
        isCreatedExerciseUser: null,
      };
    case actions.CREATE_EXERCISE_USER_SUCCESS:
      return {
        ...state,
        isCreatingExerciseUser: false,
        isCreatedExerciseUser: true,
      };
    case actions.CREATE_EXERCISE_USER_FAILURE:
      return {
        ...state,
        isCreatingExerciseUser: false,
        isCreatedExerciseUser: false,
      };

    case actions.UPDATE_EXERCISE_USER:
      return {
        ...state,
        isUpdatingExerciseUser: true,
        isCreatedExerciseUser: null,
      };
    case actions.UPDATE_EXERCISE_USER_SUCCESS:
      return {
        ...state,
        isUpdatingExerciseUser: false,
        isCreatedExerciseUser: true,
      };
    case actions.UPDATE_EXERCISE_USER_FAILURE:
      return {
        ...state,
        isUpdatingExerciseUser: false,
        isCreatedExerciseUser: false,
      };

    case actions.DELETE_EXERCISE_USER:
      return {
        ...state,
        isDeletingExerciseUser: true,
        isCreatedExerciseUser: null,
      };
    case actions.DELETE_EXERCISE_USER_SUCCESS:
      return {
        ...state,
        isDeletingExerciseUser: false,
        isCreatedExerciseUser: true,
      };
    case actions.DELETE_EXERCISE_USER_FAILURE:
      return {
        ...state,
        isDeletingExerciseUser: false,
        isCreatedExerciseUser: false,
      };

    case actions.FETCH_EXERCISE_USER_FILE:
      return { ...state, isFetchingExerciseUserFile: true };
    case actions.FETCH_EXERCISE_USER_FILE_SUCCESS:
      return { ...state, isFetchingExerciseUserFile: false };
    case actions.FETCH_EXERCISE_USER_FILE_FAILURE:
      return { ...state, isFetchingExerciseUserFile: false };

    case actions.UPDATE_ALL_EXERCISE_USER:
      return { ...state, isUpdateAllExerciseUser: true };
    case actions.UPDATE_ALL_EXERCISE_USER_SUCCESS:
      return { ...state, isUpdateAllExerciseUser: false };
    case actions.UPDATE_ALL_EXERCISE_USER_FAILURE:
      return { ...state, isUpdateAllExerciseUser: false };

    default:
      return state;
  }
}

export default Reducer;
