import actions from "./actions";

const initialState = {
  cmsInformations: [],
  isFetchingCmsInformations: false,

  isChangedCmsInformations: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_CMS_INFORMATIONS:
      return {
        ...state,
        isFetchingCmsInformations: true,
        isChangedCmsInformations: null,
      };
    case actions.FETCH_CMS_INFORMATIONS_SUCCESS:
      return {
        ...state,
        isFetchingCmsInformations: false,
        cmsInformations: action.payload,
      };
    case actions.FETCH_CMS_INFORMATIONS_FAILURE:
      return { ...state, isFetchingCmsInformations: false };

    case actions.ADD_CMS_INFORMATIONS:
      return { ...state, isChangedCmsInformations: null };
    case actions.ADD_CMS_INFORMATIONS_SUCCESS:
      return { ...state, isChangedCmsInformations: true };
    case actions.ADD_CMS_INFORMATIONS_FAILURE:
      return { ...state, isChangedCmsInformations: false };

    case actions.UPDATE_CMS_INFORMATIONS:
      return { ...state, isChangedCmsInformations: null };
    case actions.UPDATE_CMS_INFORMATIONS_SUCCESS:
      return { ...state, isChangedCmsInformations: true };
    case actions.UPDATE_CMS_INFORMATIONS_FAILURE:
      return { ...state, isChangedCmsInformations: false };

    case actions.DELETE_CMS_INFORMATIONS:
      return { ...state, isChangedCmsInformations: null };
    case actions.DELETE_CMS_INFORMATIONS_SUCCESS:
      return { ...state, isChangedCmsInformations: true };
    case actions.DELETE_CMS_INFORMATIONS_FAILURE:
      return { ...state, isChangedCmsInformations: false };

    case actions.INIT_STATE:
      return {
        ...state,
        isChangedCmsInformations: null,
      };
    default:
      return state;
  }
}

export default Reducer;
