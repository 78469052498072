import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { deleteRequest, putRequest } from "utils/axios-client";

function* changePlan(action) {
  try {
    const response = yield callApi(() => putRequest(`crm/plan?userId=${action.userId}&planId=${action.planId}`))
    yield put({
      type: actions.CHANGE_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CHANGE_PLAN_FAILURE });
  }
}

function* cancelPlan(action) {
  try {
    yield callApi(() => deleteRequest(`crm/plan?userId=${action.userId}`))
    yield put({
      type: actions.CANCEL_PLAN_SUCCESS,
      // payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CANCEL_PLAN_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.CHANGE_PLAN, changePlan)]);
  yield all([takeLatest(actions.CANCEL_PLAN, cancelPlan)]);
}
