import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { deleteRequest, postRequest, postRequestNoToken } from "utils/axios-client";

function* signup(action) {
  try {
    const response = yield callApi(() => postRequestNoToken('auth/signup', action.payload))
    yield put({
      type: actions.SIGNUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.SIGNUP_FAILURE });
  }
}

function* signupFromSubscription(action) {
  const id = Number(action.payload.id);
  delete action.payload.id;
  try {
    const response = yield callApi(() => postRequest('auth/signup', action.payload));
    yield callApi(() => deleteRequest(`subscription?id=${id}`, action.payload))
    yield put({
      type: actions.SIGNUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.SIGNUP_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.SIGNUP, signup)]);
  yield all([takeLatest(actions.SIGNUPFROMSUBSCRIPTION, signupFromSubscription)]);
}
