import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* getBuildingFireFiles(action) {
  try {
    const response = yield callApi(() =>
      getRequest(`building/fire-files?buildingId=${action.buildingId}`)
    );
    yield put({
      type: actions.FETCH_BUILDING_FIRE_FILES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_FIRE_FILES_FAILURE });
  }
}

function* addBuildingFireFiles(action) {
  try {
    yield callApi(() => postRequest(`building/fire-files`, action.payload));
    yield put({
      type: actions.ADD_BUILDING_FIRE_FILES_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_BUILDING_FIRE_FILES_FAILURE });
  }
}

function* editBuildingFireFiles(action) {
  try {
    yield callApi(() =>
      putRequest(`building/fire-files?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_BUILDING_FIRE_FILES_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_FIRE_FILES_FAILURE });
  }
}

function* deleteBuildingFireFiles(action) {
  try {
    yield callApi(() => deleteRequest(`building/fire-files?id=${action.id}`));
    yield put({
      type: actions.DELETE_BUILDING_FIRE_FILES_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_BUILDING_FIRE_FILES_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.FETCH_BUILDING_FIRE_FILES, getBuildingFireFiles),
  ]);
  yield all([
    takeLatest(actions.ADD_BUILDING_FIRE_FILES, addBuildingFireFiles),
  ]);
  yield all([
    takeLatest(actions.UPDATE_BUILDING_FIRE_FILES, editBuildingFireFiles),
  ]);
  yield all([
    takeLatest(actions.DELETE_BUILDING_FIRE_FILES, deleteBuildingFireFiles),
  ]);
}
