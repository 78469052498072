import actions from "./actions";

const initialState = {
  cmsLaws: [],
  isFetchingCmsLaws: false,

  isChangedCmsLaws: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_CMS_LAWS:
      return {
        ...state,
        isFetchingCmsLaws: true,
        isChangedCmsLaws: null,
      };
    case actions.FETCH_CMS_LAWS_SUCCESS:
      return {
        ...state,
        isFetchingCmsLaws: false,
        cmsLaws: action.payload,
      };
    case actions.FETCH_CMS_LAWS_FAILURE:
      return { ...state, isFetchingCmsLaws: false };

    case actions.ADD_CMS_LAWS:
      return { ...state, isChangedCmsLaws: null };
    case actions.ADD_CMS_LAWS_SUCCESS:
      return { ...state, isChangedCmsLaws: true };
    case actions.ADD_CMS_LAWS_FAILURE:
      return { ...state, isChangedCmsLaws: false };

    case actions.UPDATE_CMS_LAWS:
      return { ...state, isChangedCmsLaws: null };
    case actions.UPDATE_CMS_LAWS_SUCCESS:
      return { ...state, isChangedCmsLaws: true };
    case actions.UPDATE_CMS_LAWS_FAILURE:
      return { ...state, isChangedCmsLaws: false };

    case actions.DELETE_CMS_LAWS:
      return { ...state, isChangedCmsLaws: null };
    case actions.DELETE_CMS_LAWS_SUCCESS:
      return { ...state, isChangedCmsLaws: true };
    case actions.DELETE_CMS_LAWS_FAILURE:
      return { ...state, isChangedCmsLaws: false };

    case actions.INIT_STATE:
      return {
        ...state,
        isChangedCmsLaws: null,
      }
    default:
      return state;
  }
}

export default Reducer;
