import actions from "./actions";

const initialState = {
  requirementAccording: [],
  isFetchingRequirementAccording: false,

  isChangedAddRequirementAccording: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_REQUIREMENT_ACCORDING:
      return {
        ...state,
        isFetchingRequirementAccording: true,
      };
    case actions.FETCH_REQUIREMENT_ACCORDING_SUCCESS:
      return {
        ...state,
        isFetchingRequirementAccording: false,
        requirementAccording: action.payload,
      };
    case actions.FETCH_REQUIREMENT_ACCORDING_FAILURE:
      return { ...state, isFetchingRequirementAccording: false };

    case actions.ADD_REQUIREMENT_ACCORDING:
      return { ...state, isChangedAddRequirementAccording: null };
    case actions.ADD_REQUIREMENT_ACCORDING_SUCCESS:
      return { ...state, isChangedAddRequirementAccording: true };
    case actions.ADD_REQUIREMENT_ACCORDING_FAILURE:
      return { ...state, isChangedAddRequirementAccording: false };

    case actions.UPDATE_REQUIREMENT_ACCORDING:
      return { ...state, isChangedAddRequirementAccording: null };
    case actions.UPDATE_REQUIREMENT_ACCORDING_SUCCESS:
      return { ...state, isChangedAddRequirementAccording: true };
    case actions.UPDATE_REQUIREMENT_ACCORDING_FAILURE:
      return { ...state, isChangedAddRequirementAccording: false };

    case actions.DELETE_REQUIREMENT_ACCORDING:
      return { ...state, isChangedAddRequirementAccording: null };
    case actions.DELETE_REQUIREMENT_ACCORDING_SUCCESS:
      return { ...state, isChangedAddRequirementAccording: true };
    case actions.DELETE_REQUIREMENT_ACCORDING_FAILURE:
      return { ...state, isChangedAddRequirementAccording: false };

    default:
      return state;
  }
}

export default Reducer;
