import actions from "./actions";

const initialState = {
  isCreatedTenantInvite: null,

  isCreatedTenantUserByInvitation: null,
  createdTenantUserByInvitation: [],

  invitedInfo: null,
  isFetchingInvitedInfo: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_TENANT_INVITE:
      return {
        ...state,
        isCreatedTenantInvite: null,
      };

    case actions.CREATE_TENANT_INVITE_SUCCESS:
      return {
        ...state,
        isCreatedTenantInvite: true,
      };
    case actions.CREATE_TENANT_INVITE_FAILURE:
      return {
        ...state,
        isCreatedTenantInvite: false,
      };

    case actions.CREATE_TENANT_USER_BY_INVITATION:
      return {
        ...state,
        isCreatedTenantUserByInvitation: null,
      };
    case actions.CREATE_TENANT_USER_BY_INVITATION_SUCCESS:
      return {
        ...state,
        isCreatedTenantUserByInvitation: true,
        createdTenantUserByInvitation: action.payload,
      };
    case actions.CREATE_TENANT_USER_BY_INVITATION_FAILURE:
      return {
        ...state,
        isCreatedTenantUserByInvitation: false,
      };

    case actions.INITIALIZE_STATES:
      return {
        ...state,
        isCreatedTenantInvite: null,
        isCreatedTenantUserByInvitation: null,
        createdTenantUserByInvitation: [],
      };

    case actions.FETCH_INVITED_INFO:
      return {
        ...state,
        invitedInfo: null,
        isFetchingInvitedInfo: true,
      };

    case actions.FETCH_INVITED_INFO_SUCCESS:
      return {
        ...state,
        invitedInfo: action.payload,
        isFetchingInvitedInfo: false,
      };

    case actions.FETCH_INVITED_INFO_FAILURE:
      return {
        ...state,
        invitedInfo: null,
        isFetchingInvitedInfo: false,
      };
    default:
      return state;
  }
}

export default Reducer;
