import actions from "./actions";

const initialState = {
  isRead: false,
  user: [],
  isReading: false,
  isUpdateDone: null,
};



function Reducer(state = initialState, action) {
  switch (action.type) {
    // GET ROLES
    case actions.GETROLE:
      return {
        ...state,
        isReading: true,
      };
    case actions.GETROLE_SUCCESS:
      return {
        ...state,
        isRead: true,
        isReading: false,
        user: action.payload,
      };
    case actions.GETROLE_FAILURE:
      return { ...state, isRead: false, isReading: false };

    // PUT ROLES
    case actions.ADDROLE:
      return {
        ...state,
        isUpdateDone: null,
        isReading: true,
      };
    case actions.ADDROLE_SUCCESS:
      return {
        ...state,
        isRead: true,
        isReading: false,
        isUpdateDone: true,
      };
    case actions.ADDROLE_FAILURE:
      return { ...state, isRead: false, isReading: false, isUpdateDone: false };


    // EDIT ROLES
    case actions.EDITROLE:
      return {
        ...state,
        isReading: true,
        isUpdateDone: null,
      };
    case actions.EDITROLE_SUCCESS:
      return {
        ...state,
        isRead: true,
        isReading: false,
        isUpdateDone: true,
      };
    case actions.EDITROLE_FAILURE:
      return { ...state, isRead: false, isReading: false, isUpdateDone: false };

    // DELETE ROLES
    case actions.DELETEROLE:
      return {
        ...state,
        isReading: true,
        isUpdateDone: null,
      };
    case actions.DELETEROLE_SUCCESS:
      return {
        ...state,
        isRead: true,
        isReading: false,
        isUpdateDone: true,
      };
    case actions.DELETEROLE_FAILURE:
      return { ...state, isRead: false, isReading: false, isUpdateDone: false  };

    // // GET PERMISSION
    // case actions.GETPERMISSION:
    //   return {
    //     ...state,
    //     isReading: true,
    //   };
    // case actions.GETPERMISSION_SUCCESS:
    //   return {
    //     ...state,
    //     isRead: true,
    //     isReading: false,
    //     user: action.payload,
    //   };
    // case actions.GETPERMISSION_FAILURE:
    //   return { ...state, isRead: false, isReading: false };

    // PUT PERMISSIONS
    case actions.ADDPERMISSION:
      return {
        ...state,
        isReading: true,
        isUpdateDone: null 
      };
    case actions.ADDPERMISSION_SUCCESS:
      return {
        ...state,
        isRead: true,
        isReading: false,
        isUpdateDone: true,
      };
    case actions.ADDPERMISSION_FAILURE:
      return { ...state, isRead: false, isReading: false, isUpdateDone: false  };


    // EDIT PERMISSIONS
    case actions.EDITPERMISSION:
      return {
        ...state,
        isReading: true,
        isUpdateDone: null,
      };
    case actions.EDITPERMISSION_SUCCESS:
      return {
        ...state,
        isRead: true,
        isReading: false,
        isUpdateDone: true,
      };
    case actions.EDITPERMISSION_FAILURE:
      return { ...state, isRead: false, isReading: false, isUpdateDone: false };

    // // DELETE PERMISSIONS
    // case actions.DELETEPERMISSION:
    //   return {
    //     ...state,
    //     isReading: true,
    //   };
    // case actions.DELETEPERMISSION_SUCCESS:
    //   return {
    //     ...state,
    //     isRead: true,
    //     isReading: false,
    //     user: action.payload,
    //   };
    // case actions.DELETEPERMISSION_FAILURE:
    //   return { ...state, isRead: false, isReading: false };



    //       // GET PERMISSION
    // case actions.GETROLEPERMISSION:
    //   return {
    //     ...state,
    //     isReading: true,
    //   };
    // case actions.GETROLEPERMISSION_SUCCESS:
    //   return {
    //     ...state,
    //     isRead: true,
    //     isReading: false,
    //     user: action.payload,
    //   };
    // case actions.GETROLEPERMISSION_FAILURE:
    //   return { ...state, isRead: false, isReading: false };

    // PUT ROLEPERMISSIONS
    case actions.ADDROLEPERMISSION:
      return {
        ...state,
        isReading: true,
      };
    case actions.ADDROLEPERMISSION_SUCCESS:
      return {
        ...state,
        isRead: true,
        isReading: false,
      };
    case actions.ADDROLEPERMISSION_FAILURE:
      return { ...state, isRead: false, isReading: false };
    

    default:
      return state;
  }
}

export default Reducer;
