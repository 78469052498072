import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchBasicData() {
  console.log('equipment api integration test2')
  try {
    const responseCentralType = yield callApi(() => getRequest('cms/fire-alarm/central-type'));
    const responseCoverageArea = yield callApi(() => getRequest('cms/coverage-area'));
    const responseTaker = yield callApi(() => getRequest('cms/fire-alarm/connection'));
    yield put({
      type: actions.FETCH_BASIC_DATA_SUCCESS,
      payloadCentralType: responseCentralType.data,
      payloadCoverageArea: responseCoverageArea.data,
      payloadTaker: responseTaker.data
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BASIC_DATA_FAILURE });
  }
}

function* fetchBuildingFireAlarm(action) {
  try {
    const responseFireAlarm = yield callApi(() => getRequest(`building/fire-alarm?buildingId=${action.payload}`))
    yield put({
      type: actions.FETCH_BUILDING_INSTALLATION_FIRE_ALARM_SUCCESS,
      payloadFireAlarm: responseFireAlarm.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_INSTALLATION_FIRE_ALARM_FAILURE });
  }
}

function* createBuildingFireAlarm(action) {
  try {
    const response = yield callApi(() => postRequest(`building/fire-alarm?buildingId=${action.buildingId}`, action.payload))
    yield put({
      type: actions.CREATE_BUILDING_INSTALLATION_FIRE_ALARM_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.CREATE_BUILDING_INSTALLATION_FIRE_ALARM_FAILURE });
  }
}

function* updateBuildingFireAlarm(action) {
  try {
    const response = yield callApi(() => putRequest(`building/fire-alarm?id=${action.id}`, action.payload))
    yield put({
      type: actions.UPDATE_BUILDING_INSTALLATION_FIRE_ALARM_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_INSTALLATION_FIRE_ALARM_FAILURE });
  }
}


export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_BASIC_DATA, fetchBasicData)]);
  yield all([takeLatest(actions.FETCH_BUILDING_INSTALLATION_FIRE_ALARM, fetchBuildingFireAlarm)]);
  yield all([takeLatest(actions.CREATE_BUILDING_INSTALLATION_FIRE_ALARM, createBuildingFireAlarm)]);
  yield all([takeLatest(actions.UPDATE_BUILDING_INSTALLATION_FIRE_ALARM, updateBuildingFireAlarm)]);
}
