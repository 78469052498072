const actions = {
  FETCH_MAIN_THEME: "FETCH_MAIN_THEME",
  FETCH_MAIN_THEME_SUCCESS: "FETCH_MAIN_THEME_SUCCESS",
  FETCH_MAIN_THEME_FAILURE: "FETCH_MAIN_THEME_FAILURE",

  UPDATE_MAIN_THEME: "UPDATE_MAIN_THEME",
  UPDATE_MAIN_THEME_SUCCESS: "UPDATE_MAIN_THEME_SUCCESS",
  UPDATE_MAIN_THEME_FAILURE: "UPDATE_MAIN_THEME_FAILURE",

  CREATE_MAIN_THEME: "CREATE_MAIN_THEME",
  CREATE_MAIN_THEME_SUCCESS: "CREATE_MAIN_THEME_SUCCESS",
  CREATE_MAIN_THEME_FAILURE: "CREATE_MAIN_THEME_FAILURE",

  DELETE_MAIN_THEME: "DELETE_MAIN_THEME",
  DELETE_MAIN_THEME_SUCCESS: "DELETE_MAIN_THEME_SUCCESS",
  DELETE_MAIN_THEME_FAILURE: "DELETE_MAIN_THEME_FAILURE",

  FETCH_SUB_THEME: "FETCH_SUB_THEME",
  FETCH_SUB_THEME_SUCCESS: "FETCH_SUB_THEME_SUCCESS",
  FETCH_SUB_THEME_FAILURE: "FETCH_SUB_THEME_FAILURE",

  UPDATE_SUB_THEME: "UPDATE_SUB_THEME",
  UPDATE_SUB_THEME_SUCCESS: "UPDATE_SUB_THEME_SUCCESS",
  UPDATE_SUB_THEME_FAILURE: "UPDATE_SUB_THEME_FAILURE",

  DELETE_SUB_THEME: "DELETE_SUB_THEME",
  DELETE_SUB_THEME_SUCCESS: "DELETE_SUB_THEME_SUCCESS",
  DELETE_SUB_THEME_FAILURE: "DELETE_SUB_THEME_FAILURE",

  CREATE_SUB_THEME: "CREATE_SUB_THEME",
  CREATE_SUB_THEME_SUCCESS: "CREATE_SUB_THEME_SUCCESS",
  CREATE_SUB_THEME_FAILURE: "CREATE_SUB_THEME_FAILURE",
};

export default actions;
