const actions = {
  FETCH_BUILDING_CONTACT: "FETCH_BUILDING_CONTACT",
  FETCH_BUILDING_CONTACT_SUCCESS: "FETCH_BUILDING_CONTACT_SUCCESS",
  FETCH_BUILDING_CONTACT_FAILURE: "FETCH_BUILDING_CONTACT_FAILURE",

  FETCH_BUILDING_CONNECTIONS: "FETCH_BUILDING_CONNECTIONS",
  FETCH_BUILDING_CONNECTIONS_SUCCESS: "FETCH_BUILDING_CONNECTIONS_SUCCESS",
  FETCH_BUILDING_CONNECTIONS_FAILURE: "FETCH_BUILDING_CONNECTIONS_FAILURE",

  ADD_BUILDING_CONTACT: "ADD_BUILDING_CONTACT",
  ADD_BUILDING_CONTACT_SUCCESS: "ADD_BUILDING_CONTACT_SUCCESS",
  ADD_BUILDING_CONTACT_FAILURE: "ADD_BUILDING_CONTACT_FAILURE",

  UPDATE_BUILDING_CONTACT: "UPDATE_BUILDING_CONTACT",
  UPDATE_BUILDING_CONTACT_SUCCESS: "UPDATE_BUILDING_CONTACT_SUCCESS",
  UPDATE_BUILDING_CONTACT_FAILURE: "UPDATE_BUILDING_CONTACT_FAILURE",

  DELETE_BUILDING_CONTACT: "DELETE_BUILDING_CONTACT",
  DELETE_BUILDING_CONTACT_SUCCESS: "DELETE_BUILDING_CONTACT_SUCCESS",
  DELETE_BUILDING_CONTACT_FAILURE: "DELETE_BUILDING_CONTACT_FAILURE",

  RESET_BUILDING_CONTACT: "RESET_BUILDING_CONTACT",
};

export default actions;
