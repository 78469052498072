export const actions = {
  FETCH_TICKETS: "FETCH_TICKETS",
  FETCH_TICKETS_SUCCESS: "FETCH_TICKETS_SUCCESS",
  FETCH_TICKETS_FAILURE: "FETCH_TICKETS_FAILURE",
  
  CREATE_TICKETS: "CREATE_TICKETS",
  CREATE_TICKETS_SUCCESS: "CREATE_TICKETS_SUCCESS",
  CREATE_TICKETS_FAILURE: "CREATE_TICKETS_FAILURE",
  
  DELETE_TICKETS: "DELETE_TICKETS",
  DELETE_TICKETS_SUCCESS: "DELETE_TICKETS_SUCCESS",
  DELETE_TICKETS_FAILURE: "DELETE_TICKETS_FAILURE",

};

export default actions;
