import actions from "./actions";

const initialState = {
  buildingPress: [],
  isFecthingBuildingPress: false,

  buildingPressCustomers: [],
  buildingPressMainTheme: [],
  buildingPressServicePartner: [],
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_PRESS:
      return {
        ...state,
        isFecthingBuildingPress: true,
        buildingPress: [],
        buildingPressCustomers: [],
        buildingPressMainTheme: [],
        buildingPressServicePartner: [],
      };
    case actions.FETCH_BUILDING_PRESS_SUCCESS:
      return {
        ...state,
        isFecthingBuildingPress: false,
        buildingPress: action.payload,
        buildingPressCustomers: action.payloadCustomer,
        buildingPressMainTheme: action.payloadMainTheme,
        buildingPressServicePartner: action.payloadServicePartner,
      };
    case actions.FETCH_BUILDING_PRESS_FAILURE:
      return { ...state, isFecthingBuildingPress: false };

    default:
      return state;
  }
}

export default Reducer;
