import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { deleteRequest, getRequest, postRequestNoToken } from "utils/axios-client";

function* fetchTickets(action) {
  try {
    const response = yield callApi(() => getRequest('crm/ticket'))
    yield put({
      type: actions.FETCH_TICKETS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_TICKETS_FAILURE });
  }
}

function* createTicket(action) {
  try {
    yield callApi(() => postRequestNoToken('crm/ticket', action.payload))
    yield put({
      type: actions.CREATE_TICKETS_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_TICKETS_FAILURE });
  }
}

function* deleteTicket(action) {
  try {
    yield callApi(() => deleteRequest(`crm/ticket?id=${action.payload}`))
    yield put({
      type: actions.DELETE_TICKETS_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_TICKETS_FAILURE });
  }
}
export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_TICKETS, fetchTickets)]);
  yield all([takeLatest(actions.CREATE_TICKETS, createTicket)]);
  yield all([takeLatest(actions.DELETE_TICKETS, deleteTicket)]);
}
