import actions from "./actions";

const initialState = {
  cmsPackages: [],
  isFetchingcmsPackages: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_CMS_PACKAGES:
      return {
        ...state,
        isFetchingcmsPackages: true,
      };
    case actions.FETCH_CMS_PACKAGES_SUCCESS:
      return {
        ...state,
        isFetchingcmsPackages: false,
        cmsPackages: action.payload,
      };
    case actions.FETCH_CMS_PACKAGES_FAILURE:
      return { ...state, isFetchingcmsPackages: false };

    default:
      return state;
  }
}

export default Reducer;
