import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* fetchUserNotification(action) {
  try {
    const response = yield callApi(() => getRequest("user/notification"));
    yield put({
      type: actions.FETCH_USER_NOTIFICATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_USER_NOTIFICATION_FAILURE });
  }
}

function* createUserNotification(action) {
  try {
    const response = yield callApi(() =>
      postRequest("user/notification", action.payload)
    );
    yield put({
      type: actions.CREATE_USER_NOTIFICATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_USER_NOTIFICATION_FAILURE });
  }
}

function* updateUserNotification(action) {
  try {
    const response = yield callApi(() =>
      putRequest("user/notification", action.payload)
    );
    yield put({
      type: actions.UPDATE_USER_NOTIFICATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_USER_NOTIFICATION_FAILURE });
  }
}

function* deleteUserNotification(action) {
  try {
    const response = yield callApi(() =>
      deleteRequest(`user/notification?id=${action.payload}`)
    );
    yield put({
      type: actions.DELETE_USER_NOTIFICATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_USER_NOTIFICATION_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.FETCH_USER_NOTIFICATION, fetchUserNotification),
  ]);
  yield all([
    takeLatest(actions.UPDATE_USER_NOTIFICATION, updateUserNotification),
  ]);
  yield all([
    takeLatest(actions.DELETE_USER_NOTIFICATION, deleteUserNotification),
  ]);
  yield all([
    takeLatest(actions.CREATE_USER_NOTIFICATION, createUserNotification),
  ]);
}
