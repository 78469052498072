import actions from "./actions";

const initialState = {
  plans: [],
  isReading: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    // GET ROLES
    case actions.GETPLAN:
      return {
        ...state,
        isReading: true,
      };
    case actions.GETPLAN_SUCCESS:
      return {
        ...state,
        isReading: false,
        plans: action.payload,
      };
    case actions.GETPLAN_FAILURE:
      return { ...state, isReading: false };

    case actions.EDITPLAN:
      return {
        ...state
      };
    case actions.EDITPLAN_SUCCESS:
      return {
        ...state
      };
    case actions.EDITPLAN_FAILURE:
      return {
        ...state
      }

      case actions.DELETEPLAN:
      return {
        ...state
      };
    case actions.DELETEPLAN_SUCCESS:
      return {
        ...state
      };
    case actions.DELETEPLAN_FAILURE:
      return {
        ...state
      }

    default:
      return state;
  }
}

export default Reducer;
