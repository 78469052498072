const actions = {
  FETCH_TASK_COMMENTS: "FETCH_TASK_COMMENTS",
  FETCH_TASK_COMMENTS_SUCCESS: "FETCH_TASK_COMMENTS_SUCCESS",
  FETCH_TASK_COMMENTS_FAILURE: "FETCH_TASK_COMMENTSFAILURE",

  EDIT_TASK_COMMENT: "EDIT_TASK_COMMENT",
  EDIT_TASK_COMMENT_SUCCESS: "EDIT_TASK_COMMENT_SUCCESS",
  EDIT_TASK_COMMENT_FAILURE: "EDIT_TASK_COMMENT_FAILURE",
};

export default actions;
