const actions = {
  FETCH_BUILDING_FIRE_FILES: "FETCH_BUILDING_FIRE_FILES",
  FETCH_BUILDING_FIRE_FILES_SUCCESS: "FETCH_BUILDING_FIRE_FILES_SUCCESS",
  FETCH_BUILDING_FIRE_FILES_FAILURE: "FETCH_BUILDING_FIRE_FILES_FAILURE",

  ADD_BUILDING_FIRE_FILES: "ADD_BUILDING_FIRE_FILES",
  ADD_BUILDING_FIRE_FILES_SUCCESS: "ADD_BUILDING_FIRE_FILES_SUCCESS",
  ADD_BUILDING_FIRE_FILES_FAILURE: "ADD_BUILDING_FIRE_FILES_FAILURE",

  UPDATE_BUILDING_FIRE_FILES: "UPDATE_BUILDING_FIRE_FILES",
  UPDATE_BUILDING_FIRE_FILES_SUCCESS: "UPDATE_BUILDING_FIRE_FILES_SUCCESS",
  UPDATE_BUILDING_FIRE_FILES_FAILURE: "UPDATE_BUILDING_FIRE_FILES_FAILURE",

  DELETE_BUILDING_FIRE_FILES: "DELETE_BUILDING_FIRE_FILES",
  DELETE_BUILDING_FIRE_FILES_SUCCESS: "DELETE_BUILDING_FIRE_FILES_SUCCESS",
  DELETE_BUILDING_FIRE_FILES_FAILURE: "DELETE_BUILDING_FIRE_FILES_FAILURE",

  RESET_BUILDING_FIRE_FILES: "RESET_BUILDING_FIRE_FILES",
};

export default actions;
