const actions = {
  FETCH_BUILDING_THERMOGRAPHY_REPORT: "FETCH_BUILDING_THERMOGRAPHY_REPORT",
  FETCH_BUILDING_THERMOGRAPHY_REPORT_SUCCESS: "FETCH_BUILDING_THERMOGRAPHY_REPORT_SUCCESS",
  FETCH_BUILDING_THERMOGRAPHY_REPORT_FAILURE: "FETCH_BUILDING_THERMOGRAPHY_REPORT_FAILURE",

  FETCH_BUILDING_FIRE_HISTORIES: "FETCH_BUILDING_FIRE_HISTORIES",
  FETCH_BUILDING_FIRE_HISTORIES_SUCCESS: "FETCH_BUILDING_FIRE_HISTORIES_SUCCESS",
  FETCH_BUILDING_FIRE_HISTORIES_FAILURE: "FETCH_BUILDING_FIRE_HISTORIES_FAILURE",

  CREATE_BUILDING_THERMOGRAPHY_REPORT: "CREATE_BUILDING_THERMOGRAPHY_REPORT",
  CREATE_BUILDING_THERMOGRAPHY_REPORT_SUCCESS: "CREATE_BUILDING_THERMOGRAPHY_REPORT_SUCCESS",
  CREATE_BUILDING_THERMOGRAPHY_REPORT_FAILURE: "CREATE_BUILDING_THERMOGRAPHY_REPORT_FAILURE",

  UPDATE_BUILDING_THERMOGRAPHY_REPORT: "UPDATE_BUILDING_THERMOGRAPHY_REPORT",
  UPDATE_BUILDING_THERMOGRAPHY_REPORT_SUCCESS: "UPDATE_BUILDING_THERMOGRAPHY_REPORT_SUCCESS",
  UPDATE_BUILDING_THERMOGRAPHY_REPORT_FAILURE: "UPDATE_BUILDING_THERMOGRAPHY_REPORT_FAILURE",

};

export default actions;
