import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchTasks(action) {
  try {
    const responseTasks = yield callApi(() =>
      getRequest("workflow/task", action.payload)
    );
    const responseBuildings = yield callApi(() => getRequest("building"));
    const responseCustomers = yield callApi(() => getRequest("crm/customer"));
    yield put({
      type: actions.FETCH_TASK_TASKS_SUCCESS,
      payload: responseTasks.data,
      payload_buildings: responseBuildings.data,
      payload_customers: responseCustomers.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_TASK_TASKS_FAILURE });
  }
}

function* fetchTaskById(action) {
  try {
    const response = yield callApi(() =>
      getRequest(`workflow/task?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.FETCH_TASK_BY_ID_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_TASK_BY_ID_FAILURE });
  }
}

function* fetchTaskSatistics(action) {
  try {
    const response = yield callApi(() =>
      getRequest(
        `workflow/task/statistics?startedAt=${action.startDate}&endedAt=${action.endDate}&type=${action.fetchType}`,
        action.payload
      )
    );
    yield put({
      type: actions.FETCH_TASK_SATISTICS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_TASK_SATISTICS_FAILURE });
  }
}

function* fetchTaskSatisticsByDuedate(action) {
  try {
    const response = yield callApi(() =>
      getRequest(
        `workflow/task/statistics?startedAt=${action.startDate}&endedAt=${action.endDate}&type=duedate`,
        action.payload
      )
    );
    yield put({
      type: actions.FETCH_TASK_SATISTICS_BY_DUEDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_TASK_SATISTICS_BY_DUEDATE_FAILURE });
  }
}

function* fetchBuildings() {
  try {
    const response = yield callApi(() => getRequest("building"));
    yield put({
      type: actions.FETCH_BUILDINGS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDINGS_FAILURE });
  }
}

function* addTasks(action) {
  try {
    const response = yield callApi(() =>
      postRequest("workflow/task", action.payload)
    );
    yield put({
      type: actions.ADD_TASK_TASKS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.ADD_TASK_TASKS_FAILURE });
  }
}

function* updateTasks(action) {
  try {
    const response = yield callApi(() =>
      putRequest(`workflow/task?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_TASK_TASKS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_TASK_TASKS_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_TASK_TASKS, fetchTasks)]);
  yield all([takeLatest(actions.FETCH_TASK_BY_ID, fetchTaskById)]);
  yield all([takeLatest(actions.FETCH_TASK_SATISTICS, fetchTaskSatistics)]);
  yield all([
    takeLatest(
      actions.FETCH_TASK_SATISTICS_BY_DUEDATE,
      fetchTaskSatisticsByDuedate
    ),
  ]);
  yield all([takeLatest(actions.FETCH_BUILDINGS, fetchBuildings)]);
  yield all([takeLatest(actions.ADD_TASK_TASKS, addTasks)]);
  yield all([takeLatest(actions.UPDATE_TASK_TASKS, updateTasks)]);
}
