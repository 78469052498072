import actions from "./actions";

const initialState = {
  cmsPosition: [],
  isFetchingCmsPosition: false,

  isChangedCmsPosition: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_CMS_POSITION:
      return {
        ...state,
        isFetchingCmsPosition: true,
        isChangedCmsPosition: null,
      };
    case actions.FETCH_CMS_POSITION_SUCCESS:
      return {
        ...state,
        isFetchingCmsPosition: false,
        cmsPosition: action.payload,
      };
    case actions.FETCH_CMS_POSITION_FAILURE:
      return { ...state, isFetchingCmsPosition: false };

    case actions.ADD_CMS_POSITION:
      return { ...state, isChangedCmsPosition: null };
    case actions.ADD_CMS_POSITION_SUCCESS:
      return { ...state, isChangedCmsPosition: true };
    case actions.ADD_CMS_POSITION_FAILURE:
      return { ...state, isChangedCmsPosition: false };

    case actions.UPDATE_CMS_POSITION:
      return { ...state, isChangedCmsPosition: null };
    case actions.UPDATE_CMS_POSITION_SUCCESS:
      return { ...state, isChangedCmsPosition: true };
    case actions.UPDATE_CMS_POSITION_FAILURE:
      return { ...state, isChangedCmsPosition: false };

    case actions.DELETE_CMS_POSITION:
      return { ...state, isChangedCmsPosition: null };
    case actions.DELETE_CMS_POSITION_SUCCESS:
      return { ...state, isChangedCmsPosition: true };
    case actions.DELETE_CMS_POSITION_FAILURE:
      return { ...state, isChangedCmsPosition: false };

    case actions.INIT_STATE:
      return {
        ...state,
        isChangedCmsPosition: null,
      };
    default:
      return state;
  }
}

export default Reducer;
