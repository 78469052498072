import actions from "./actions";

const initialState = {
  coverageArea: [],
  isFetchingCoverageArea: false,

  isChangedAddCoverageArea: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_COVERAGE_AREA:
      return {
        ...state,
        isFetchingCoverageArea: true,
      };
    case actions.FETCH_COVERAGE_AREA_SUCCESS:
      return {
        ...state,
        isFetchingCoverageArea: false,
        coverageArea: action.payload,
      };
    case actions.FETCH_COVERAGE_AREA_FAILURE:
      return { ...state, isFetchingCoverageArea: false };
    
    case actions.ADD_COVERAGE_AREA:
      return { ...state, isChangedAddCoverageArea: null};
    case actions.ADD_COVERAGE_AREA_SUCCESS:
      return {...state, isChangedAddCoverageArea: true};
    case actions.ADD_COVERAGE_AREA_FAILURE:
      return { ...state, isChangedAddCoverageArea: false};

    case actions.UPDATE_COVERAGE_AREA:
      return { ...state, isChangedAddCoverageArea: null};
    case actions.UPDATE_COVERAGE_AREA_SUCCESS:
      return {...state, isChangedAddCoverageArea: true};
    case actions.UPDATE_COVERAGE_AREA_FAILURE:
      return { ...state, isChangedAddCoverageArea: false};
      
    case actions.DELETE_COVERAGE_AREA:
      return { ...state, isChangedAddCoverageArea: null};
    case actions.DELETE_COVERAGE_AREA_SUCCESS:
      return {...state, isChangedAddCoverageArea: true};
    case actions.DELETE_COVERAGE_AREA_FAILURE:
      return { ...state, isChangedAddCoverageArea: false};

    default:
      return state;
  }
}

export default Reducer;
