const actions = {
  FETCH_DOCUMENTS: "FETCH_DOCUMENTS",
  FETCH_DOCUMENTS_SUCCESS: "FETCH_DOCUMENTS_SUCCESS",
  FETCH_DOCUMENTS_FAILURE: "FETCH_DOCUMENTS_FAILURE",

  FETCH_TASKS: "FETCH_TASKS",
  FETCH_TASKS_SUCCESS: "FETCH_TASKS_SUCCESS",
  FETCH_TASKS_FAILURE: "FETCH_TASKS_FAILURE",

  FETCH_DEVIATIONS: "FETCH_DEVIATIONS",
  FETCH_DEVIATIONS_SUCCESS: "FETCH_DEVIATIONS_SUCCESS",
  FETCH_DEVIATIONS_FAILURE: "FETCH_DEVIATIONS_FAILURE",

  FETCH_NOTIFICATIONS: "FETCH_NOTIFICATIONS",
  FETCH_NOTIFICATIONS_SUCCESS: "FETCH_NOTIFICATIONS_SUCCESS",
  FETCH_NOTIFICATIONS_FAILURE: "FETCH_NOTIFICATIONS_FAILURE",

  FETCH_NEWS: "FETCH_NEWS",
  FETCH_NEWS_SUCCESS: "FETCH_NEWS_SUCCESS",
  FETCH_NEWS_FAILURE: "FETCH_NEWS_FAILURE",
};

export default actions;
