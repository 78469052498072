const actions = {
  FETCH_EXERCISE_USER: "FETCH_EXERCISE_USER",
  FETCH_EXERCISE_USER_SUCCESS: "FETCH_EXERCISE_USER_SUCCESS",
  FETCH_EXERCISE_USER_FAILURE: "FETCH_EXERCISE_USER_FAILURE",

  CREATE_EXERCISE_USER_BY_FILE: "CREATE_EXERCISE_USER_BY_FILE",
  CREATE_EXERCISE_USER_BY_FILE_SUCCESS: "CREATE_EXERCISE_USER_BY_FILE_SUCCESS",
  CREATE_EXERCISE_USER_BY_FILE_FAILURE: "CREATE_EXERCISE_USER_BY_FILE_FAILURE",

  CREATE_EXERCISE_USER: "CREATE_EXERCISE_USER",
  CREATE_EXERCISE_USER_SUCCESS: "CREATE_EXERCISE_USER_SUCCESS",
  CREATE_EXERCISE_USER_FAILURE: "CREATE_EXERCISE_USER_FAILURE",

  UPDATE_EXERCISE_USER: "UPDATE_EXERCISE_USER",
  UPDATE_EXERCISE_USER_SUCCESS: "UPDATE_EXERCISE_USER_SUCCESS",
  UPDATE_EXERCISE_USER_FAILURE: "UPDATE_EXERCISE_USER_FAILURE",

  DELETE_EXERCISE_USER: "DELETE_EXERCISE_USER",
  DELETE_EXERCISE_USER_SUCCESS: "DELETE_EXERCISE_USER_SUCCESS",
  DELETE_EXERCISE_USER_FAILURE: "DELETE_EXERCISE_USER_FAILURE",

  FETCH_EXERCISE_USER_FILE: "FETCH_EXERCISE_USER_FILE",
  FETCH_EXERCISE_USER_FILE_SUCCESS: "FETCH_EXERCISE_USER_FILE_SUCCESS",
  FETCH_EXERCISE_USER_FILE_FAILURE: "FETCH_EXERCISE_USER_FILE_FAILURE",

  UPDATE_ALL_EXERCISE_USER: "UPDATE_ALL_EXERCISE_USER",
  UPDATE_ALL_EXERCISE_USER_SUCCESS: "UPDATE_ALL_EXERCISE_USER_SUCCESS",
  UPDATE_ALL_EXERCISE_USER_FAILURE: "UPDATE_ALL_EXERCISE_USER_FAILURE",
};

export default actions;
