const actions = {
  FETCH_BUILDING_SPRINKLER: "FETCH_BUILDING_SPRINKLER",
  FETCH_BUILDING_SPRINKLER_SUCCESS: "FETCH_BUILDING_SPRINKLER_SUCCESS",
  FETCH_BUILDING_SPRINKLER_FAILURE: "FETCH_BUILDING_SPRINKLER_FAILURE",

  ADD_BUILDING_SPRINKLER: "ADD_BUILDING_SPRINKLER",
  ADD_BUILDING_SPRINKLER_SUCCESS: "ADD_BUILDING_SPRINKLER_SUCCESS",
  ADD_BUILDING_SPRINKLER_FAILURE: "ADD_BUILDING_SPRINKLER_FAILURE",

  UPDATE_BUILDING_SPRINKLER: "UPDATE_BUILDING_SPRINKLER",
  UPDATE_BUILDING_SPRINKLER_SUCCESS: "UPDATE_BUILDING_SPRINKLER_SUCCESS",
  UPDATE_BUILDING_SPRINKLER_FAILURE: "UPDATE_BUILDING_SPRINKLER_FAILURE",

  DELETE_BUILDING_SPRINKLER: "DELETE_BUILDING_SPRINKLER",
  DELETE_BUILDING_SPRINKLER_SUCCESS: "DELETE_BUILDING_SPRINKLER_SUCCESS",
  DELETE_BUILDING_SPRINKLER_FAILURE: "DELETE_BUILDING_SPRINKLER_FAILURE",

  RESET_BUILDING_SPRINKLER: "RESET_BUILDING_SPRINKLER",
};

export default actions;
