import actions from "./actions";

const initialState = {
  buildingSprinkler: [],
  isFetchingBuildingSprinkler: false,
  isChangedAddBuildingSprinkler: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_SPRINKLER:
      return {
        ...state,
        buildingSprinkler: [],
        isFetchingBuildingSprinkler: true,
      };
    case actions.FETCH_BUILDING_SPRINKLER_SUCCESS:
      return {
        ...state,
        isFetchingBuildingSprinkler: false,
        buildingSprinkler: action.payload,
      };
    case actions.FETCH_BUILDING_SPRINKLER_FAILURE:
      return { ...state, isFetchingBuildingSprinkler: false };

    case actions.ADD_BUILDING_SPRINKLER:
      return { ...state, isChangedAddBuildingSprinkler: null };
    case actions.ADD_BUILDING_SPRINKLER_SUCCESS:
      return { ...state, isChangedAddBuildingSprinkler: true };
    case actions.ADD_BUILDING_SPRINKLER_FAILURE:
      return { ...state, isChangedAddBuildingSprinkler: false };

    case actions.UPDATE_BUILDING_SPRINKLER:
      return { ...state, isChangedAddBuildingSprinkler: null };
    case actions.UPDATE_BUILDING_SPRINKLER_SUCCESS:
      return { ...state, isChangedAddBuildingSprinkler: true };
    case actions.UPDATE_BUILDING_SPRINKLER_FAILURE:
      return { ...state, isChangedAddBuildingSprinkler: false };

    case actions.DELETE_BUILDING_SPRINKLER:
      return { ...state, isChangedAddBuildingSprinkler: null };
    case actions.DELETE_BUILDING_SPRINKLER_SUCCESS:
      return { ...state, isChangedAddBuildingSprinkler: true };
    case actions.DELETE_BUILDING_SPRINKLER_FAILURE:
      return { ...state, isChangedAddBuildingSprinkler: false };

    case actions.RESET_BUILDING_SPRINKLER:
      return { ...state, isChangedAddBuildingSprinkler: null };

    default:
      return state;
  }
}

export default Reducer;
