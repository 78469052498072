const actions = {
  FETCH_BUILDING_TENANTS: "FETCH_BUILDING_TENANTS",
  FETCH_BUILDING_TENANTS_SUCCESS: "FETCH_BUILDING_TENANTS_SUCCESS",
  FETCH_BUILDING_TENANTS_FAILURE: "FETCH_BUILDING_TENANTS_FAILURE",

  CREATE_BUILDING_TENANTS: "CREATE_BUILDING_TENANTS",
  CREATE_BUILDING_TENANTS_SUCCESS: "CREATE_BUILDING_TENANTS_SUCCESS",
  CREATE_BUILDING_TENANTS_FAILURE: "CREATE_BUILDING_TENANTS_FAILURE",

  UPDATE_BUILDING_TENANTS: "UPDATE_BUILDING_TENANTS",
  UPDATE_BUILDING_TENANTS_SUCCESS: "UPDATE_BUILDING_TENANTS_SUCCESS",
  UPDATE_BUILDING_TENANTS_FAILURE: "UPDATE_BUILDING_TENANTS_FAILURE",  
};

export default actions;
