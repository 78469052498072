const actions = {
  SEND_INVOICE: "SEND_INVOICE",
  SEND_INVOICE_SUCCESS: "SEND_INVOICE_SUCCESS",
  SEND_INVOICE_FAILURE: "SEND_INVOICE_FAILURE",

  GET_INVOICE: "GET_INVOICE",
  GET_INVOICE_SUCCESS: "GET_INVOICE_SUCCESS",
  GET_INVOICE_FAILURE: "GET_INVOICE_FAILURE",
};

export default actions;
