import actions from "./actions";

const initialState = {
  equipment: [],
  isFetchingEquipment: false,

  isChangedAddEquipment: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_EQUIPMENT:
      return {
        ...state,
        isFetchingEquipment: true,
      };
    case actions.FETCH_EQUIPMENT_SUCCESS:
      return {
        ...state,
        isFetchingEquipment: false,
        equipment: action.payload,
      };
    case actions.FETCH_EQUIPMENT_FAILURE:
      return { ...state, isFetchingEquipment: false };
    
    case actions.ADD_EQUIPMENT:
      return { ...state, isChangedAddEquipment: null};
    case actions.ADD_EQUIPMENT_SUCCESS:
      return {...state, isChangedAddEquipment: true};
    case actions.ADD_EQUIPMENT_FAILURE:
      return { ...state, isChangedAddEquipment: false};

    case actions.UPDATE_EQUIPMENT:
      return { ...state, isChangedAddEquipment: null};
    case actions.UPDATE_EQUIPMENT_SUCCESS:
      return {...state, isChangedAddEquipment: true};
    case actions.UPDATE_EQUIPMENT_FAILURE:
      return { ...state, isChangedAddEquipment: false};
      
    case actions.DELETE_EQUIPMENT:
      return { ...state, isChangedAddEquipment: null};
    case actions.DELETE_EQUIPMENT_SUCCESS:
      return {...state, isChangedAddEquipment: true};
    case actions.DELETE_EQUIPMENT_FAILURE:
      return { ...state, isChangedAddEquipment: false};

    default:
      return state;
  }
}

export default Reducer;
