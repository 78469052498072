export const actions = {
  GETPLAN: "GETPLAN",
  GETPLAN_SUCCESS: "GETPLAN_SUCCESS",
  GETPLAN_FAILURE: "GETPLAN_FAILURE",

  EDITPLAN: "EDITPLAN",
  EDITPLAN_SUCCESS: "EDITPLAN_SUCCESS",
  EDITPLAN_FAILURE: "EDITPLAN_FAILURE",

  DELETEPLAN: "DELETEPLAN",
  DELETEPLAN_SUCCESS: "DELETEPLAN_SUCCESS",
  DELETEPLAN_FAILURE: "DELETEPLAN_FAILURE",
};

export default actions;
