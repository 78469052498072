const actions = {
  FETCH_SUBSCRIPTIONS: "FETCH_SUBSCRIPTIONS",
  FETCH_SUBSCRIPTIONS_SUCCESS: "FETCH_SUBSCRIPTIONS_SUCCESS",
  FETCH_SUBSCRIPTIONS_FAILURE: "FETCH_SUBSCRIPTIONS_FAILURE",

  CREATE_SUBSCRIPTIONS: "CREATE_SUBSCRIPTIONS",
  CREATE_SUBSCRIPTIONS_SUCCESS: "CREATE_SUBSCRIPTIONS_SUCCESS",
  CREATE_SUBSCRIPTIONS_FAILURE: "CREATE_SUBSCRIPTIONS_FAILURE",
};

export default actions;
