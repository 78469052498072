import actions from "./actions";

const initialState = {
  deviations: [],
  deviation_buildings: [],
  deviation_customers: [],
  deviationStatistics: [],
  deviationStatisticsByDuedate: [],
  create_deviations: "",
  isFetchingDeviations: false,
  isFetchingDeviationSatistics: false,
  isFetchingDeviationSatisticsByDuedate: false,

  isChangedDeviations: null,
  isCreatedDeviation: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_DEVIATION_DEVIATIONS:
      return {
        ...state,
        isFetchingDeviations: true,
        isChangedDeviations: null,
      };
    case actions.FETCH_DEVIATION_DEVIATIONS_SUCCESS:
      return {
        ...state,
        isFetchingDeviations: false,
        deviations: action.payload,
        deviation_buildings: action.payload_buildings,
        deviation_customers: action.payload_customers,
      };
    case actions.FETCH_DEVIATION_DEVIATIONS_FAILURE:
      return { ...state, isFetchingDeviations: false };

    case actions.FETCH_DEVIATION_SATISTICS:
      return {
        ...state,
        isFetchingDeviationSatistics: true,
      };
    case actions.FETCH_DEVIATION_SATISTICS_SUCCESS:
      return {
        ...state,
        isFetchingDeviationSatistics: false,
        deviationStatistics: action.payload,
      };
    case actions.FETCH_DEVIATION_SATISTICS_FAILURE:
      return { ...state, isFetchingDeviationSatistics: false };

    case actions.FETCH_DEVIATION_SATISTICS_BY_DUEDATE:
      return {
        ...state,
        isFetchingDeviationSatisticsByDuedate: true,
      };
    case actions.FETCH_DEVIATION_SATISTICS_BY_DUEDATE_SUCCESS:
      return {
        ...state,
        isFetchingDeviationSatisticsByDuedate: false,
        deviationStatisticsByDuedate: action.payload,
      };
    case actions.FETCH_DEVIATION_SATISTICS_BY_DUEDATE_FAILURE:
      return { ...state, isFetchingDeviationSatisticsByDuedate: false };

    case actions.ADD_DEVIATION_DEVIATIONS:
      return {
        ...state,
        isChangedDeviations: null,
        isFetchingDeviations: true,
        isCreatedDeviation: null,
      };
    case actions.ADD_DEVIATION_DEVIATIONS_SUCCESS:
      return {
        ...state,
        isFetchingDeviations: false,
        isChangedDeviations: true,
        isCreatedDeviation: true,
        create_deviations: action.payload,
      };
    case actions.ADD_DEVIATION_DEVIATIONS_FAILURE:
      return {
        ...state,
        isFetchingDeviations: false,
        isChangedDeviations: false,
        isCreatedDeviation: false,
      };

    case actions.UPDATE_DEVIATION_DEVIATIONS:
      return {
        ...state,
        isChangedDeviations: null,
        isFetchingDeviations: true,
      };
    case actions.UPDATE_DEVIATION_DEVIATIONS_SUCCESS:
      return {
        ...state,
        isChangedDeviations: true,
        isFetchingDeviations: false,
        create_deviations: action.payload,
      };
    case actions.UPDATE_DEVIATION_DEVIATIONS_FAILURE:
      return {
        ...state,
        isFetchingDeviations: false,
        isChangedDeviations: false,
      };

    case actions.RESET_CREATE_DEVIATION:
      return {
        ...state,
        isCreatedDeviation: null,
      };
    default:
      return state;
  }
}

export default Reducer;
