import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { deleteRequest, getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchBuildingGroup(action) {
  try {
    const response = yield callApi(() => getRequest('cms/building-group'))
    yield put({
      type: actions.FETCH_BUILDING_GROUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_GROUP_FAILURE });
  }
}

function* createBuildingGroup(action) {
  try {
    const response = yield callApi(() => postRequest('cms/building-group', action.payload))
    yield put({
      type: actions.CREATE_BUILDING_GROUP_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.CREATE_BUILDING_GROUP_FAILURE });
  }
}

function* updateBuildingGroup(action) {
  try {
    const response = yield callApi(() => putRequest('cms/building-group', action.payload))
    yield put({
      type: actions.UPDATE_BUILDING_GROUP_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_GROUP_FAILURE });
  }
}

function* deleteBuildingGroup(action) {
  try {
    const response = yield callApi(() => deleteRequest(`cms/building-group?id=${action.payload}`))
    yield put({
      type: actions.DELETE_BUILDING_GROUP_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.DELETE_BUILDING_GROUP_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_BUILDING_GROUP, fetchBuildingGroup)]);
  yield all([takeLatest(actions.UPDATE_BUILDING_GROUP, updateBuildingGroup)]);
  yield all([takeLatest(actions.DELETE_BUILDING_GROUP, deleteBuildingGroup)]);
  yield all([takeLatest(actions.CREATE_BUILDING_GROUP, createBuildingGroup)]);
}
