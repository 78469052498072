import actions from "./actions";

const initialState = {
  connection: [],
  isFetchingConnection: false,

  isChangedAddConnection: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_CONNECTION:
      return {
        ...state,
        isFetchingConnection: true,
      };
    case actions.FETCH_CONNECTION_SUCCESS:
      return {
        ...state,
        isFetchingConnection: false,
        connection: action.payload,
      };
    case actions.FETCH_CONNECTION_FAILURE:
      return { ...state, isFetchingConnection: false };
    
    case actions.ADD_CONNECTION:
      return { ...state, isChangedAddConnection: null};
    case actions.ADD_CONNECTION_SUCCESS:
      return {...state, isChangedAddConnection: true};
    case actions.ADD_CONNECTION_FAILURE:
      return { ...state, isChangedAddConnection: false};

    case actions.UPDATE_CONNECTION:
      return { ...state, isChangedAddConnection: null};
    case actions.UPDATE_CONNECTION_SUCCESS:
      return {...state, isChangedAddConnection: true};
    case actions.UPDATE_CONNECTION_FAILURE:
      return { ...state, isChangedAddConnection: false};
      
    case actions.DELETE_CONNECTION:
      return { ...state, isChangedAddConnection: null};
    case actions.DELETE_CONNECTION_SUCCESS:
      return {...state, isChangedAddConnection: true};
    case actions.DELETE_CONNECTION_FAILURE:
      return { ...state, isChangedAddConnection: false};

    default:
      return state;
  }
}

export default Reducer;
