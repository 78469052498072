import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { postRequestNoToken } from "utils/axios-client";

function* resetPassword(action) {
  const accessToken = localStorage.getItem('accessToken');
  const userID = localStorage.getItem('userID');

  const payload = JSON.stringify({ ...action.payload, token: accessToken, userId: Number(userID) });
  try {
    const response = yield callApi(() => postRequestNoToken('auth/resetPassword', payload))
    yield put({
      type: actions.RESET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.RESET_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.RESET, resetPassword)]);
}
