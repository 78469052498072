import actions from "./actions";

const initialState = {
  inovice: [],
  isSendingInvoice: false,

  invoicesForSetting: [],
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SEND_INVOICE:
      return {
        ...state,
        isSendingInvoice: true,
      };
    case actions.SEND_INVOICE_SUCCESS:
      return {
        ...state,
        isSendingInvoice: false,
        inovice: action.payload,
      };
    case actions.SEND_INVOICE_FAILURE:
      return { ...state, isSendingInvoice: false };

    case actions.GET_INVOICE_SUCCESS:
      return { ...state, invoicesForSetting: action.payload};
      
    default:
      return state;
  }
}

export default Reducer;
