import actions from "./actions";

const initialState = {
  isEditPersonInfo: false,
  contact_persont: 0,
  editPersonInfo: null,
  isEditPassword: false,
  editPassword: null
};


function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.EDIT_PERSON_INFO:
      return {
        ...state,
        editPersonInfo: null,
        isEditPersonInfo: true,
      };
    case actions.EDIT_PERSON_INFO_SUCCESS:
      return {
        ...state,
        isEditPersonInfo: false,
        editPersonInfo: action.payload,
      };
    case actions.EDIT_PERSON_INFO_FAILURE:
      return { ...state, isEditPersonInfo: false,   editPersonInfo: null };

    case actions.EDIT_PASSWORD:
      return {
        ...state,
        isEditPassword: true,
      };
    case actions.EDIT_PASSWORD_SUCCESS:
      return {
        ...state,
        isEditPassword: false,
        editPassword: null,
      };
    case actions.EDIT_PASSWORD_FAILURE:
      return { ...state, isEditPassword: false };

    default:
      return state;
  }
}

export default Reducer;
