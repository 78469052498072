import React, { Suspense, lazy, useEffect } from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "components/ui/loader/Loader";
import { useCookies } from "react-cookie";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

const CustomerRoutes = lazy(() => import("routes/Customer"));
const AdminRoutes = lazy(() => import("routes/Admin"));
const AuthRoutes = lazy(() => import("routes/Auth"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/autentisering/*" element={<AuthRoutes />} />
      <Route path="/admin/*" element={<AdminRoutes />} />
      <Route path="/*" element={<CustomerRoutes />} />
    </Route>
  )
);

function App() {
  const [cookies] = useCookies("token");

  useEffect(() => {
    if (cookies.token === undefined) {
      localStorage.removeItem("user");
    }
  }, [cookies]);

  return (
    <Suspense fallback={<Loader />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
