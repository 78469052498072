import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchFireDrawingCustomers(action) {
  try {
    const response = yield callApi(() => getRequest("crm/customer"));

    yield put({
      type: actions.FETCH_FIRE_DRAWING_CUSTOMER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_FIRE_DRAWING_CUSTOMER_FAILURE });
  }
}

function* fetchFireDrawing(action) {
  try {
    const responseCustomer = yield callApi(() => getRequest("crm/customer"));
    const response = yield callApi(() =>
      getRequest(`building/fire-drawing?buildingId=${action.buildingId}`)
    );

    yield put({
      type: actions.FETCH_FIRE_DRAWING_SUCCESS,
      payload: response.data,
      payloadCustomer: responseCustomer.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_FIRE_DRAWING_FAILURE });
  }
}

function* addFireDrawing(action) {
  try {
    const response = yield callApi(() =>
      postRequest(
        `building/fire-drawing?buildingId=${action.buildingId}`,
        action.payload
      )
    );
    yield put({
      type: actions.CREATE_FIRE_DRAWING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_FIRE_DRAWING_FAILURE });
  }
}

function* updateFireDrawing(action) {
  try {
    const response = yield callApi(() =>
      putRequest(`building/fire-drawing?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_FIRE_DRAWING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_FIRE_DRAWING_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.FETCH_FIRE_DRAWING_CUSTOMER, fetchFireDrawingCustomers),
  ]);
  yield all([takeLatest(actions.FETCH_FIRE_DRAWING, fetchFireDrawing)]);
  yield all([takeLatest(actions.CREATE_FIRE_DRAWING, addFireDrawing)]);
  yield all([takeLatest(actions.UPDATE_FIRE_DRAWING, updateFireDrawing)]);
}
