import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchThermographyReport(action) {
  try {
    const response = yield callApi(() => getRequest(`building/thermography-report?buildingId=${action.payload}`))
    const responseCustomer = yield callApi(() => getRequest('crm/customer'))
    yield put({
      type: actions.FETCH_BUILDING_THERMOGRAPHY_REPORT_SUCCESS,
      payload: response.data,
      payloadCustomer: responseCustomer.data
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_THERMOGRAPHY_REPORT_FAILURE });
  }
}

function* addThermographyReport(action) {
  try {
    const response = yield callApi(() => postRequest(`building/thermography-report?buildingId=${action.buildingId}`, action.payload))
    yield put({
      type: actions.CREATE_BUILDING_THERMOGRAPHY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_BUILDING_THERMOGRAPHY_REPORT_FAILURE });
  }
}

function* updateThermographyReport(action) {
  try {
    const response = yield callApi(() => putRequest(`building/thermography-report?id=${action.id}`, action.payload))
    yield put({
      type: actions.UPDATE_BUILDING_THERMOGRAPHY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_THERMOGRAPHY_REPORT_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_BUILDING_THERMOGRAPHY_REPORT, fetchThermographyReport)]);
  yield all([takeLatest(actions.CREATE_BUILDING_THERMOGRAPHY_REPORT, addThermographyReport)]);
  yield all([takeLatest(actions.UPDATE_BUILDING_THERMOGRAPHY_REPORT, updateThermographyReport)]);
}
