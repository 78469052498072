import actions from "./actions";

const initialState = {
  exerciseCategory: [],
  isFetchingCmsExerciseCategory: false,

  isChangedCmsExerciseCategory: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_CMS_EXERCISE_CATEGORY:
      return {
        ...state,
        isFetchingCmsExerciseCategory: true,
        isChangedCmsExerciseCategory: null,
      };
    case actions.FETCH_CMS_EXERCISE_CATEGORY_SUCCESS:
      return {
        ...state,
        isFetchingCmsExerciseCategory: false,
        exerciseCategory: action.payload,
      };
    case actions.FETCH_CMS_EXERCISE_CATEGORY_FAILURE:
      return { ...state, isFetchingCmsExerciseCategory: false };

    case actions.ADD_CMS_EXERCISE_CATEGORY:
      return { ...state, isChangedCmsExerciseCategory: null };
    case actions.ADD_CMS_EXERCISE_CATEGORY_SUCCESS:
      return { ...state, isChangedCmsExerciseCategory: true };
    case actions.ADD_CMS_EXERCISE_CATEGORY_FAILURE:
      return { ...state, isChangedCmsExerciseCategory: false };

    case actions.UPDATE_CMS_EXERCISE_CATEGORY:
      return { ...state, isChangedCmsExerciseCategory: null };
    case actions.UPDATE_CMS_EXERCISE_CATEGORY_SUCCESS:
      return { ...state, isChangedCmsExerciseCategory: true };
    case actions.UPDATE_CMS_EXERCISE_CATEGORY_FAILURE:
      return { ...state, isChangedCmsExerciseCategory: false };

    case actions.DELETE_CMS_EXERCISE_CATEGORY:
      return { ...state, isChangedCmsExerciseCategory: null };
    case actions.DELETE_CMS_EXERCISE_CATEGORY_SUCCESS:
      return { ...state, isChangedCmsExerciseCategory: true };
    case actions.DELETE_CMS_EXERCISE_CATEGORY_FAILURE:
      return { ...state, isChangedCmsExerciseCategory: false };

    case actions.INIT_STATE:
      return {
        ...state,
        isChangedCmsExerciseCategory: null,
      };
    default:
      return state;
  }
}

export default Reducer;
