const actions = {
  FETCH_BUILDING_GROUP: "FETCH_BUILDING_GROUP",
  FETCH_BUILDING_GROUP_SUCCESS: "FETCH_BUILDING_GROUP_SUCCESS",
  FETCH_BUILDING_GROUP_FAILURE: "FETCH_BUILDING_GROUP_FAILURE",

  CREATE_BUILDING_GROUP: "CREATE_BUILDING_GROUP",
  CREATE_BUILDING_GROUP_SUCCESS: "CREATE_BUILDING_GROUP_SUCCESS",
  CREATE_BUILDING_GROUP_FAILURE: "CREATE_BUILDING_GROUP_FAILURE",

  UPDATE_BUILDING_GROUP: "UPDATE_BUILDING_GROUP",
  UPDATE_BUILDING_GROUP_SUCCESS: "UPDATE_BUILDING_GROUP_SUCCESS",
  UPDATE_BUILDING_GROUP_FAILURE: "UPDATE_BUILDING_GROUP_FAILURE",

  DELETE_BUILDING_GROUP: "DELETE_BUILDING_GROUP",
  DELETE_BUILDING_GROUP_SUCCESS: "DELETE_BUILDING_GROUP_SUCCESS",
  DELETE_BUILDING_GROUP_FAILURE: "DELETE_BUILDING_GROUP_FAILURE",
};

export default actions;
