import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* getBuildingContact() {
  try {
    const response = yield callApi(() => getRequest("building/contact"));
    yield put({
      type: actions.FETCH_BUILDING_CONTACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_CONTACT_FAILURE });
  }
}

function* getBuildingConnections(action) {
  try {
    const response = yield callApi(() =>
      getRequest(`building/connections?buildingId=${action.buildingId}`)
    );
    yield put({
      type: actions.FETCH_BUILDING_CONNECTIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_CONNECTIONS_SUCCESS });
  }
}

function* addBuildingContact(action) {
  try {
    const response = yield callApi(() =>
      postRequest("building/contact", action.payload)
    );
    yield put({
      type: actions.ADD_BUILDING_CONTACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.ADD_BUILDING_CONTACT_FAILURE });
  }
}

function* editBuildingContact(action) {
  try {
    yield callApi(() =>
      putRequest(`building/contact?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_BUILDING_CONTACT_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_CONTACT_FAILURE });
  }
}

function* deleteBuildingContact(action) {
  try {
    yield callApi(() => deleteRequest(`building/contact?id=${action.id}`));
    yield put({
      type: actions.DELETE_BUILDING_CONTACT_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_BUILDING_CONTACT_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_BUILDING_CONTACT, getBuildingContact)]);
  yield all([
    takeLatest(actions.FETCH_BUILDING_CONNECTIONS, getBuildingConnections),
  ]);
  yield all([takeLatest(actions.ADD_BUILDING_CONTACT, addBuildingContact)]);
  yield all([takeLatest(actions.UPDATE_BUILDING_CONTACT, editBuildingContact)]);
  yield all([
    takeLatest(actions.DELETE_BUILDING_CONTACT, deleteBuildingContact),
  ]);
}
