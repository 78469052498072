import actions from "./actions";

const initialState = {
  buildingThemeDeviation: [],
  isFetchingBuildingThemeDeviation: false,

  mustFetchThemeDeviation: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_THEME_DEVIATION:
      return {
        ...state,
        isFetchingBuildingThemeDeviation: true,
      };
    case actions.FETCH_BUILDING_THEME_DEVIATION_SUCCESS:
      return {
        ...state,
        isFetchingBuildingThemeDeviation: false,
        buildingThemeDeviation: action.payload,
      };
    case actions.FETCH_BUILDING_THEME_DEVIATION_FAILURE:
      return {
        ...state,
        isFetchingBuildingThemeDeviation: false,
        buildingThemeDeviation: [],
      };

    case actions.ADD_BUILDING_THEME_DEVIATION:
      return { ...state, mustFetchThemeDeviation: null };
    case actions.ADD_BUILDING_THEME_DEVIATION_SUCCESS:
      return { ...state, mustFetchThemeDeviation: true };
    case actions.ADD_BUILDING_THEME_DEVIATION_FAILURE:
      return { ...state, mustFetchThemeDeviation: false };

    case actions.UPDATE_BUILDING_THEME_DEVIATION:
      return { ...state, mustFetchThemeDeviation: null };
    case actions.UPDATE_BUILDING_THEME_DEVIATION_SUCCESS:
      return { ...state, mustFetchThemeDeviation: true };
    case actions.UPDATE_BUILDING_THEME_DEVIATION_FAILURE:
      return { ...state, mustFetchThemeDeviation: false };

    case actions.DELETE_BUILDING_THEME_DEVIATION:
      return { ...state, mustFetchThemeDeviation: null };
    case actions.DELETE_BUILDING_THEME_DEVIATION_SUCCESS:
      return { ...state, mustFetchThemeDeviation: true };
    case actions.DELETE_BUILDING_THEME_DEVIATION_FAILURE:
      return { ...state, mustFetchThemeDeviation: false };

    case actions.RESET_BUILDING_THEME_DEVIATION:
      return { ...state, mustFetchThemeDeviation: null };

    default:
      return state;
  }
}

export default Reducer;
