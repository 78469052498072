const actions = {
  FETCH_CMS_SERVICE_PROVIDER: "FETCH_CMS_SERVICE_PROVIDER",
  FETCH_CMS_SERVICE_PROVIDER_SUCCESS: "FETCH_CMS_SERVICE_PROVIDER_SUCCESS",
  FETCH_CMS_SERVICE_PROVIDER_FAILURE: "FETCH_CMS_SERVICE_PROVIDER_FAILURE",

  ADD_CMS_SERVICE_PROVIDER: "ADD_CMS_SERVICE_PROVIDER",
  ADD_CMS_SERVICE_PROVIDER_SUCCESS: "ADD_CMS_SERVICE_PROVIDER_SUCCESS",
  ADD_CMS_SERVICE_PROVIDER_FAILURE: "ADD_CMS_SERVICE_PROVIDER_FAILURE",

  UPDATE_CMS_SERVICE_PROVIDER: "UPDATE_CMS_SERVICE_PROVIDER",
  UPDATE_CMS_SERVICE_PROVIDER_SUCCESS: "UPDATE_CMS_SERVICE_PROVIDER_SUCCESS",
  UPDATE_CMS_SERVICE_PROVIDER_FAILURE: "UPDATE_CMS_SERVICE_PROVIDER_FAILURE",
};

export default actions;
