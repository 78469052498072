const actions = {
  FETCH_BUILDING_VENTILATION: "FETCH_BUILDING_VENTILATION",
  FETCH_BUILDING_VENTILATION_SUCCESS: "FETCH_BUILDING_VENTILATION_SUCCESS",
  FETCH_BUILDING_VENTILATION_FAILURE: "FETCH_BUILDING_VENTILATION_FAILURE",

  CREATE_BUILDING_VENTILATION: "CREATE_BUILDING_VENTILATION",
  CREATE_BUILDING_VENTILATION_SUCCESS: "CREATE_BUILDING_VENTILATION_SUCCESS",
  CREATE_BUILDING_VENTILATION_FAILURE: "CREATE_BUILDING_VENTILATION_FAILURE",

  UPDATE_BUILDING_VENTILATION: "UPDATE_BUILDING_VENTILATION",
  UPDATE_BUILDING_VENTILATION_SUCCESS: "UPDATE_BUILDING_VENTILATION_SUCCESS",
  UPDATE_BUILDING_VENTILATION_FAILURE: "UPDATE_BUILDING_VENTILATION_FAILURE",

};

export default actions;
