import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, putRequest } from "utils/axios-client";

function* fetchCustomers() {
  try {
    const response = yield callApi(() => getRequest('crm/customer'));
    const responsePlan = yield callApi(() => getRequest('cms/plan'));
    const responseRole = yield callApi(() => getRequest('cms/role'));

    yield put({
      type: actions.FETCH_CUSTOMERS_SUCCESS,
      payload: response.data,
      payload_plan: responsePlan.data,
      payload_role: responseRole.data,
    });

  } catch (error) {
    yield put({ type: actions.FETCH_CUSTOMERS_FAILURE });
  }
}

function* fetchOnlyCustomers() {
  try {
    const response = yield callApi(() => getRequest('crm/customer'));

    yield put({
      type: actions.FETCH_CUSTOMERS_ONLY_SUCCESS,
      payload: response.data,
    });

  } catch (error) {
    yield put({ type: actions.FETCH_CUSTOMERS_ONLY_FAILURE });
  }
}

function* updateCustomers(action) {
  try {
    yield callApi(() => putRequest('edit_customer', action.payload))
    yield put({
      type: actions.UPDATE_CUSTOMERS_FAILURE,
    });

  } catch (error) {
    yield put({ type: actions.UPDATE_CUSTOMERS_FAILURE });
  }

}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_CUSTOMERS, fetchCustomers)]);
  yield all([takeLatest(actions.FETCH_CUSTOMERS_ONLY, fetchOnlyCustomers)]);
  yield all([takeLatest(actions.UPDATE_CUSTOMERS, updateCustomers)]);
}
