import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* getExercise(action) {
  try {
    const response = yield callApi(() => getRequest("crm/exercise"));
    yield put({
      type: actions.FETCH_EXERCISE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_EXERCISE_FAILURE });
  }
}

function* addExercise(action) {
  try {
    yield callApi(() => postRequest("crm/exercise", action.payload));
    yield put({
      type: actions.CREATE_EXERCISE_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_EXERCISE_FAILURE });
  }
}

function* editExercise(action) {
  try {
    yield callApi(() => putRequest("crm/exercise", action.payload));
    yield put({
      type: actions.UPDATE_EXERCISE_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_EXERCISE_FAILURE });
  }
}

function* deleteExercise(action) {
  try {
    yield callApi(() => deleteRequest(`crm/exercise?id=${action.id}`));
    yield put({
      type: actions.DELETE_EXERCISE_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_EXERCISE_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_EXERCISE, getExercise)]);
  yield all([takeLatest(actions.CREATE_EXERCISE, addExercise)]);
  yield all([takeLatest(actions.UPDATE_EXERCISE, editExercise)]);
  yield all([takeLatest(actions.DELETE_EXERCISE, deleteExercise)]);
}
