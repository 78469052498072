export const actions = {
  SIGNUP: "SIGNUP",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",

  SIGNUPFROMSUBSCRIPTION: "SIGNUPFROMSUBSCRIPTION",
  SIGNUPFROMSUBSCRIPTION_SUCCESS: "SIGNUPFROMSUBSCRIPTION_SUCCESS",
  SIGNUPFROMSUBSCRIPTION_FAILURE: "SIGNUPFROMSUBSCRIPTION_FAILURE",
};

export default actions;
