import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchBasicDataEmergency() {
  try {
    const response = yield callApi(() => getRequest('cms/coverage-area'));
    yield put({
      type: actions.FETCH_BASIC_DATA_EMERGENCY_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BASIC_DATA_EMERGENCY_FAILURE });
  }
}

function* fetchBuildingEmergency(action) {
  try {
    const response = yield callApi(() => getRequest(`building/emergency-light?buildingId=${action.payload}`))
    yield put({
      type: actions.FETCH_BUILDING_INSTALLATION_EMERGENCY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_INSTALLATION_EMERGENCY_FAILURE });
  }
}

function* createBuildingEmergency(action) {
  try {
    const response = yield callApi(() => postRequest(`building/emergency-light?buildingId=${action.buildingId}`, action.payload))
    yield put({
      type: actions.CREATE_BUILDING_INSTALLATION_EMERGENCY_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.CREATE_BUILDING_INSTALLATION_EMERGENCY_FAILURE });
  }
}

function* updateBuildingEmergency(action) {
  try {
    const response = yield callApi(() => putRequest(`building/emergency-light?id=${action.id}`, action.payload))
    yield put({
      type: actions.UPDATE_BUILDING_INSTALLATION_EMERGENCY_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_INSTALLATION_EMERGENCY_FAILURE });
  }
}


export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_BASIC_DATA_EMERGENCY, fetchBasicDataEmergency)]);
  yield all([takeLatest(actions.FETCH_BUILDING_INSTALLATION_EMERGENCY, fetchBuildingEmergency)]);
  yield all([takeLatest(actions.CREATE_BUILDING_INSTALLATION_EMERGENCY, createBuildingEmergency)]);
  yield all([takeLatest(actions.UPDATE_BUILDING_INSTALLATION_EMERGENCY, updateBuildingEmergency)]);
}
