const actions = {
  FETCH_CMS_LAWS: "FETCH_CMS_LAWS",
  FETCH_CMS_LAWS_SUCCESS: "FETCH_CMS_LAWS_SUCCESS",
  FETCH_CMS_LAWS_FAILURE: "FETCH_CMS_LAWS_FAILURE",

  ADD_CMS_LAWS: "ADD_CMS_LAWS",
  ADD_CMS_LAWS_SUCCESS: "ADD_CMS_LAWS_SUCCESS",
  ADD_CMS_LAWS_FAILURE: "ADD_CMS_LAWS_FAILURE",

  UPDATE_CMS_LAWS: "UPDATE_CMS_LAWS",
  UPDATE_CMS_LAWS_SUCCESS: "UPDATE_CMS_LAWS_SUCCESS",
  UPDATE_CMS_LAWS_FAILURE: "UPDATE_CMS_LAWS_FAILURE",

  DELETE_CMS_LAWS: "DELETE_CMS_LAWS",
  DELETE_CMS_LAWS_SUCCESS: "DELETE_CMS_LAWS_SUCCESS",
  DELETE_CMS_LAWS_FAILURE: "DELETE_CMS_LAWS_FAILURE",

  INIT_STATE: "INIT_STATE",
};

export default actions;
