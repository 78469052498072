import actions from "./actions";

const initialState = {
  buildingFireConcepts: [],
  isFecthingBuildingFireConcepts: false,
  buildingCreatedId: null,
  buildingFireConceptsFile: null,
  buildingFireConceptCustomers: [],
  buildingFireHistories: [],
  isFecthingBuildingFireHistories: false,
  isFinishedBuildingFireConceipts: null,
  isFinishedBuildingFireConceiptsFile: null,
  isFinishedBuildingFireConceiptsFileConfirm: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_FIRE_CONCEPTS:
      return {
        ...state,
        buildingFireConcepts: [],
        buildingFireConceptCustomers: [],
        isFecthingBuildingFireConcepts: true,
      };
    case actions.FETCH_BUILDING_FIRE_CONCEPTS_SUCCESS:
      return {
        ...state,
        isFecthingBuildingFireConcepts: false,
        buildingFireConcepts: action.payload,
        buildingFireConceptCustomers: action.payloadCustomer,
      };
    case actions.FETCH_BUILDING_FIRE_CONCEPTS_FAILURE:
      return { ...state, isFecthingBuildingFireConcepts: false };

    case actions.FETCH_BUILDING_FIRE_HISTORIES:
      return {
        ...state,
        isFecthingBuildingFireHistories: true,
      };
    case actions.FETCH_BUILDING_FIRE_HISTORIES_SUCCESS:
      return {
        ...state,
        isFecthingBuildingFireHistories: false,
        buildingFireHistories: action.payload,
      };
    case actions.FETCH_BUILDING_FIRE_HISTORIES_FAILURE:
      return { ...state, isFecthingBuildingFireHistories: false };

    case actions.CREATE_BUILDING_FIRE_CONCEPTS:
      return { ...state, isFinishedBuildingFireConceipts: null };
    case actions.CREATE_BUILDING_FIRE_CONCEPTS_SUCCESS:
      return {
        ...state,
        isFinishedBuildingFireConceipts: true,
        buildingCreatedId: action.payload,
      };
    case actions.CREATE_BUILDING_FIRE_CONCEPTS_FAILURE:
      return { ...state, isFinishedBuildingFireConceipts: false };

    case actions.UPDATE_BUILDING_FIRE_CONCEPTS:
      return { ...state, isFinishedBuildingFireConceipts: null };
    case actions.UPDATE_BUILDING_FIRE_CONCEPTS_SUCCESS:
      return { ...state, isFinishedBuildingFireConceipts: true };
    case actions.UPDATE_BUILDING_FIRE_CONCEPTS_FAILURE:
      return { ...state, isFinishedBuildingFireConceipts: false };

    case actions.UPDATE_BUILDING_FIRE_CONCEPTS_FILES:
      return { ...state, isFinishedBuildingFireConceiptsFile: null };
    case actions.UPDATE_BUILDING_FIRE_CONCEPTS_FILES_SUCCESS:
      return {
        ...state,
        isFinishedBuildingFireConceiptsFile: true,
        buildingFireConceptsFile: action.payload,
      };
    case actions.UPDATE_BUILDING_FIRE_CONCEPTS_FILES_FAILURE:
      return { ...state, isFinishedBuildingFireConceiptsFile: false };

    case actions.UPDATE_BUILDING_FIRE_CONCEPTS_CONFIRM:
      return { ...state, isFinishedBuildingFireConceiptsFileConfirm: null };
    case actions.UPDATE_BUILDING_FIRE_CONCEPTS_CONFIRM_SUCCESS:
      return { ...state, isFinishedBuildingFireConceiptsFileConfirm: true };
    case actions.UPDATE_BUILDING_FIRE_CONCEPTS_CONFIRM_FAILURE:
      return { ...state, isFinishedBuildingFireConceiptsFileConfirm: false };

    case actions.DELETE_BUILDING_FIRE_CONCEPTS:
      return { ...state, isFinishedBuildingFireConceiptsFileConfirm: null };
    case actions.DELETE_BUILDING_FIRE_CONCEPTS_SUCCESS:
      return { ...state, isFinishedBuildingFireConceiptsFileConfirm: true };
    case actions.DELETE_BUILDING_FIRE_CONCEPTS_FAILURE:
      return { ...state, isFinishedBuildingFireConceiptsFileConfirm: false };

    default:
      return state;
  }
}

export default Reducer;
