import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, putRequest } from "utils/axios-client";

function* fetchTaskComment(action) {
  try {
    const responseTaskComment = yield callApi(() => getRequest(`workflow/task/comment?task_id=${action.payload}`))

    yield put({
      type: actions.FETCH_TASK_COMMENTS_SUCCESS,
      payload: responseTaskComment.data.comments
    });
  } catch (error) {
    yield put({ type: actions.FETCH_TASK_COMMENTS_FAILURE });
  }
}

function* editTaskComment(action) {
  try {
    const response = yield callApi(() => putRequest('workflow/task/comment', action.payload))
    yield put({
      type: actions.EDIT_TASK_COMMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.EDIT_TASK_COMMENT_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_TASK_COMMENTS, fetchTaskComment)]);
  yield all([takeLatest(actions.EDIT_TASK_COMMENT, editTaskComment)]);
}
