import actions from "./actions";

const initialState = {
  userNotification: [],
  isFetchingUserNotification: false,

  isChangedUserNotification: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_USER_NOTIFICATION:
      return {
        ...state,
        isChangedUserNotification: null,
        isFetchingUserNotification: true,
      };
    case actions.FETCH_USER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isFetchingUserNotification: false,
        userNotification: action.payload,
      };
    case actions.FETCH_USER_NOTIFICATION_FAILURE:
      return { ...state, isFetchingUserNotification: false };

    case actions.CREATE_USER_NOTIFICATION:
      return {
        ...state,
        isChangedUserNotification: null,
      };
    case actions.CREATE_USER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isChangedUserNotification: true,
      };
    case actions.CREATE_USER_NOTIFICATION_FAILURE:
      return { ...state, isChangedUserNotification: false };

    case actions.UPDATE_USER_NOTIFICATION:
      return {
        ...state,
        isChangedUserNotification: null,
      };
    case actions.UPDATE_USER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isChangedUserNotification: true,
      };
    case actions.UPDATE_USER_NOTIFICATION_FAILURE:
      return { ...state, isChangedUserNotification: false };

    case actions.DELETE_USER_NOTIFICATION:
      return {
        ...state,
        isChangedUserNotification: null,
      };
    case actions.DELETE_USER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isChangedUserNotification: true,
      };
    case actions.DELETE_USER_NOTIFICATION_FAILURE:
      return { ...state, isChangedUserNotification: false };

    default:
      return state;
  }
}

export default Reducer;
