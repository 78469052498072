import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequestNoToken } from "utils/axios-client";

function* fetchSubscriptions() {
  try {
    const response = yield callApi(() => getRequest('crm/subscription'));
    const responsePlan = yield callApi(() => getRequest('cms/plan'));
    const responseRole = yield callApi(() => getRequest('cms/role'));
    yield put({
      type: actions.FETCH_SUBSCRIPTIONS_SUCCESS,
      payload: response.data,
      payload_plan: responsePlan.data,
      payload_role: responseRole.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_SUBSCRIPTIONS_FAILURE });
  }
}

function* createSubscription(action) {
  try {
    const response = yield callApi(() => postRequestNoToken('crm/subscription', action.payload))
    yield put({
      type: actions.CREATE_SUBSCRIPTIONS_SUCCESS,
      payload: response
    })
  } catch (error) {
    yield put({ type: actions.CREATE_SUBSCRIPTIONS_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_SUBSCRIPTIONS, fetchSubscriptions)]);
  yield all([takeLatest(actions.CREATE_SUBSCRIPTIONS, createSubscription)]);
}
