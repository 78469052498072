import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { deleteRequest, getRequest, postRequest, putRequest } from "utils/axios-client";

function* getCoverageArea() {
  try {
    const response = yield callApi(() => getRequest('cms/coverage-area'))
    yield put({
      type: actions.FETCH_COVERAGE_AREA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_COVERAGE_AREA_FAILURE });
  }
}

function* addCoverageArea(action) {
  try {
    yield callApi(() => postRequest('cms/coverage-area', action.payload))
    yield put({
      type: actions.ADD_COVERAGE_AREA_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_COVERAGE_AREA_FAILURE });
  }
}

function* editCoverageArea(action) {
  try {
    yield callApi(() => putRequest(`cms/coverage-area?id=${action.id}`, action.payload))
    yield put({
      type: actions.UPDATE_COVERAGE_AREA_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_COVERAGE_AREA_FAILURE });
  }
}

function* deleteCoverageArea(action) {
  try {
    yield callApi(() => deleteRequest(`cms/coverage-area?id=${action.id}`))
    yield put({
      type: actions.DELETE_COVERAGE_AREA_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_COVERAGE_AREA_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_COVERAGE_AREA, getCoverageArea)]);
  yield all([takeLatest(actions.ADD_COVERAGE_AREA, addCoverageArea)]);
  yield all([takeLatest(actions.UPDATE_COVERAGE_AREA, editCoverageArea)]);
  yield all([takeLatest(actions.DELETE_COVERAGE_AREA, deleteCoverageArea)]);

}
