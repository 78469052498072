import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* getBuildingSprinkler(action) {
  try {
    const response = yield callApi(() =>
      getRequest(`building/sprinkler?buildingId=${action.buildingId}`)
    );
    yield put({
      type: actions.FETCH_BUILDING_SPRINKLER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_SPRINKLER_FAILURE });
  }
}

function* addBuildingSprinkler(action) {
  try {
    yield callApi(() =>
      postRequest(
        `building/sprinkler?buildingId=${action.buildingId}`,
        action.payload
      )
    );
    yield put({
      type: actions.ADD_BUILDING_SPRINKLER_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_BUILDING_SPRINKLER_FAILURE });
  }
}

function* editBuildingSprinkler(action) {
  try {
    yield callApi(() =>
      putRequest(`building/sprinkler?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_BUILDING_SPRINKLER_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_SPRINKLER_FAILURE });
  }
}

function* deleteBuildingSprinkler(action) {
  try {
    yield callApi(() => deleteRequest(`building/sprinkler?id=${action.id}`));
    yield put({
      type: actions.DELETE_BUILDING_SPRINKLER_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_BUILDING_SPRINKLER_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.FETCH_BUILDING_SPRINKLER, getBuildingSprinkler),
  ]);
  yield all([takeLatest(actions.ADD_BUILDING_SPRINKLER, addBuildingSprinkler)]);
  yield all([
    takeLatest(actions.UPDATE_BUILDING_SPRINKLER, editBuildingSprinkler),
  ]);
  yield all([
    takeLatest(actions.DELETE_BUILDING_SPRINKLER, deleteBuildingSprinkler),
  ]);
}
