import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* fetchServiceCall(action) {
  try {
    const uri = action.id
      ? `workflow/service-call?id=${action.id}`
      : "workflow/service-call";
    const response = yield callApi(() => getRequest(uri));
    yield put({
      type: actions.FETCH_SERVICE_CALL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_SERVICE_CALL_FAILURE });
  }
}

function* createServiceCall(action) {
  try {
    const response = yield callApi(() =>
      postRequest("workflow/service-call", action.payload)
    );
    yield put({
      type: actions.CREATE_SERVICE_CALL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_SERVICE_CALL_FAILURE });
  }
}

function* updateServiceCall(action) {
  try {
    const response = yield callApi(() =>
      putRequest(`workflow/service-call?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_SERVICE_CALL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_SERVICE_CALL_FAILURE });
  }
}

function* deleteServiceCall(action) {
  try {
    const response = yield callApi(() =>
      deleteRequest(`workflow/service-call?id=${action.id}`)
    );
    yield put({
      type: actions.DELETE_SERVICE_CALL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_SERVICE_CALL_FAILURE });
  }
}

function* createServiceCallComment(action) {
  try {
    const response = yield callApi(() =>
      postRequest(
        `workflow/service-call/comment?id=${action.id}`,
        action.payload
      )
    );
    yield put({
      type: actions.CREATE_SERVICE_CALL_COMMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_SERVICE_CALL_COMMENT_FAILURE });
  }
}

function* updateServiceCallComment(action) {
  try {
    const response = yield callApi(() =>
      putRequest(
        `workflow/service-call/comment?id=${action.id}`,
        action.payload
      )
    );
    yield put({
      type: actions.UPDATE_SERVICE_CALL_COMMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_SERVICE_CALL_COMMENT_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_SERVICE_CALL, fetchServiceCall)]);
  yield all([takeLatest(actions.UPDATE_SERVICE_CALL, updateServiceCall)]);
  yield all([takeLatest(actions.DELETE_SERVICE_CALL, deleteServiceCall)]);
  yield all([takeLatest(actions.CREATE_SERVICE_CALL, createServiceCall)]);
  yield all([
    takeLatest(actions.CREATE_SERVICE_CALL_COMMENT, createServiceCallComment),
  ]);
  yield all([
    takeLatest(actions.UPDATE_SERVICE_CALL_COMMENT, updateServiceCallComment),
  ]);
}
