import actions from "./actions";

const initialState = {
  buildingInstallationOther: null,
  fetchedCoverageArea: [],
  fetchedOtherInstallation: [],
  fetchedDeviations: [],
  isFecthingOther: false,
  isFetchedBasicDataOther: null,
  isChangedOther: null,
  isOccuredErrorOther: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BASIC_DATA_OTHER:
      return {
        ...state,
        isFetchedBasicDataOther: null,
      };

    case actions.FETCH_BASIC_DATA_OTHER_SUCCESS:
      return {
        ...state,
        isFetchedBasicDataOther: true,
        fetchedCoverageArea: action.payload_coverage_area,
        fetchedOtherInstallation: action.payload_other_installation,
        fetchedDeviations: action.payload_deviations,
      };

    case actions.FETCH_BASIC_DATA_OTHER_FAILURE:
      return {
        ...state,
        isFetchedBasicDataOther: false,
      };

    case actions.FETCH_BUILDING_INSTALLATION_OTHER:
      return {
        ...state,
        buildingInstallationOther: null,
        isFecthingOther: true,
        isOccuredErrorOther: null,
      };
    case actions.FETCH_BUILDING_INSTALLATION_OTHER_SUCCESS:
      return {
        ...state,
        isFecthingOther: false,
        isOccuredErrorOther: false,
        isFetchedBasicDataOther: null,
        isChangedOther: null,
        buildingInstallationOther: action.payload,
      };
    case actions.FETCH_BUILDING_INSTALLATION_OTHER_FAILURE:
      return {
        ...state,
        isFecthingOther: false,
        isOccuredErrorOther: true,
        isFetchedBasicDataOther: null,
      };

    case actions.CREATE_BUILDING_INSTALLATION_OTHER:
      return { ...state, isChangedOther: null };

    case actions.CREATE_BUILDING_INSTALLATION_OTHER_SUCCESS:
      return { ...state, isChangedOther: true };

    case actions.CREATE_BUILDING_INSTALLATION_OTHER_FAILURE:
      return { ...state, isChangedOther: false };

    case actions.UPDATE_BUILDING_INSTALLATION_OTHER:
      return { ...state, isChangedOther: null };

    case actions.UPDATE_BUILDING_INSTALLATION_OTHER_SUCCESS:
      return { ...state, isChangedOther: true };

    case actions.UPDATE_BUILDING_INSTALLATION_OTHER_FAILURE:
      return { ...state, isChangedOther: false };

    case actions.RESET_BUILDING_INSTALLATION_OTHER_STATE:
      return { ...state, isChangedOther: null };

    default:
      return state;
  }
}

export default Reducer;
