const actions = {
  FETCH_CMS_POSITION: "FETCH_CMS_POSITION",
  FETCH_CMS_POSITION_SUCCESS: "FETCH_CMS_POSITION_SUCCESS",
  FETCH_CMS_POSITION_FAILURE: "FETCH_CMS_POSITION_FAILURE",

  ADD_CMS_POSITION: "ADD_CMS_POSITION",
  ADD_CMS_POSITION_SUCCESS: "ADD_CMS_POSITION_SUCCESS",
  ADD_CMS_POSITION_FAILURE: "ADD_CMS_POSITION_FAILURE",

  UPDATE_CMS_POSITION: "UPDATE_CMS_POSITION",
  UPDATE_CMS_POSITION_SUCCESS: "UPDATE_CMS_POSITION_SUCCESS",
  UPDATE_CMS_POSITION_FAILURE: "UPDATE_CMS_POSITION_FAILURE",

  DELETE_CMS_POSITION: "DELETE_CMS_POSITION",
  DELETE_CMS_POSITION_SUCCESS: "DELETE_CMS_POSITION_SUCCESS",
  DELETE_CMS_POSITION_FAILURE: "DELETE_CMS_POSITION_FAILURE",

  INIT_STATE: "INIT_STATE",
};

export default actions;
