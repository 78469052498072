import actions from "./actions";

const initialState = {
  serviceProvider: [],
  isUpdatingServiceProvider: false,
  isFetchingServiceProvider: false,
  isChangedServiceProvider: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_CMS_SERVICE_PROVIDER:
      return {
        ...state,
        isChangedServiceProvider: null,
        isFetchingServiceProvider: true,
      };
    case actions.FETCH_CMS_SERVICE_PROVIDER_SUCCESS:
      return {
        ...state,
        isFetchingServiceProvider: false,
        serviceProvider: action.payload,
      };
    case actions.FETCH_CMS_SERVICE_PROVIDER_FAILURE:
      return { ...state, isFetchingServiceProvider: false };

    case actions.ADD_CMS_SERVICE_PROVIDER:
      return {
        ...state,
        isUpdatingServiceProvider: true,
      };
    case actions.ADD_CMS_SERVICE_PROVIDER_SUCCESS:
      return {
        ...state,
        isUpdatingServiceProvider: false,
        isChangedServiceProvider: true,
      };
    case actions.ADD_CMS_SERVICE_PROVIDER_FAILURE:
      return { ...state, isUpdatingServiceProvider: false };

    case actions.UPDATE_CMS_SERVICE_PROVIDER:
      return {
        ...state,
        isUpdatingServiceProvider: true,
      };
    case actions.UPDATE_CMS_SERVICE_PROVIDER_SUCCESS:
      return {
        ...state,
        isChangedServiceProvider: true,
        isUpdatingServiceProvider: false,
      };
    case actions.UPDATE_CMS_SERVICE_PROVIDER_FAILURE:
      return { ...state, isUpdatingServiceProvider: false };

    default:
      return state;
  }
}

export default Reducer;
