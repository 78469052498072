import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { notification } from "antd";
import axios from "axios";
import { getRequest } from "utils/axios-client";
import { callApi } from "states/saga";

function* sendInvoce() {
  const axiosClient = axios.create({

    baseURL: process.env.REACT_APP_TRIPLETEX_BASE_URL,
    timeout: 1000,
    headers: {
      Accept: "application/json",
      Authorization: "Basic MDpleUowYjJ0bGJrbGtJam95TnpFMk1EZzNOU3dpZEc5clpXNGlPaUowWlhOMExUSXdNbVJtTVRJM0xURmxabU10TkRGaU55MDROekkwTFRZME0ySmlZelJrTTJOaFpDSjk=",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    },
  });

  try {
    const response = yield axiosClient.get("/activity?from=0&count=1000");

    yield put({
      type: actions.FETCH_DOCUMENTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_DOCUMENTS_FAILURE });
    if (error.code === "ECONNABORTED")
      notification.error({
        duration: 3,
        message: "ECONNABORTED",
        description: error.message,
      });
    else
      notification.error({
        duration: 3,
        message: error ? `${error.response.status}: ${error.response.statusText}` : "Innlogging mislykket",
        description: error ? error.response.data.message : "Ugyldig e-post eller passord",
      });
  }
}

function* getInvoce(action) {
  try {
    const response = yield callApi(() => getRequest('payment_invoices', action.payload))
    yield put({
      type: actions.GET_INVOICE_SUCCESS,
      payload: response.data.invoices,
    });
  } catch (error) {
    yield put({ type: actions.GET_INVOICE_FAILURE });
  }
}


export default function* rootSaga() {
  yield all([takeLatest(actions.SEND_INVOICE, sendInvoce)]);
  yield all([takeLatest(actions.GET_INVOICE, getInvoce)]);

}
