const actions = {
  FETCH_BASIC_DATA_EMERGENCY: "FETCH_BASIC_DATA_EMERGENCY",
  FETCH_BASIC_DATA_EMERGENCY_SUCCESS: "FETCH_BASIC_DATA_EMERGENCY_SUCCESS",
  FETCH_BASIC_DATA_EMERGENCY_FAILURE: "FETCH_BASIC_DATA_EMERGENCY_FAILURE",

  FETCH_BUILDING_INSTALLATION_EMERGENCY: "FETCH_BUILDING_INSTALLATION_EMERGENCY",
  FETCH_BUILDING_INSTALLATION_EMERGENCY_SUCCESS: "FETCH_BUILDING_INSTALLATION_EMERGENCY_SUCCESS",
  FETCH_BUILDING_INSTALLATION_EMERGENCY_FAILURE: "FETCH_BUILDING_INSTALLATION_EMERGENCY_FAILURE",

  CREATE_BUILDING_INSTALLATION_EMERGENCY: "CREATE_BUILDING_INSTALLATION_EMERGENCY",
  CREATE_BUILDING_INSTALLATION_EMERGENCY_SUCCESS: "CREATE_BUILDING_INSTALLATION_EMERGENCY_SUCCESS",
  CREATE_BUILDING_INSTALLATION_EMERGENCY_FAILURE: "CREATE_BUILDING_INSTALLATION_EMERGENCY_FAILURE",

  UPDATE_BUILDING_INSTALLATION_EMERGENCY: "UPDATE_BUILDING_INSTALLATION_EMERGENCY",
  UPDATE_BUILDING_INSTALLATION_EMERGENCY_SUCCESS: "UPDATE_BUILDING_INSTALLATION_EMERGENCY_SUCCESS",
  UPDATE_BUILDING_INSTALLATION_EMERGENCY_FAILURE: "UPDATE_BUILDING_INSTALLATION_EMERGENCY_FAILURE",  
};

export default actions;
