import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { deleteRequest, getRequest, postRequest, putRequest } from "utils/axios-client";

function* getCmsOtherInstallation() {
  try {
    const response = yield callApi(() => getRequest('cms/other-installation'))
    yield put({
      type: actions.FETCH_CMS_OTHER_INSTALLATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_CMS_OTHER_INSTALLATION_FAILURE });
  }
}

function* addCmsOtherInstallation(action) {
  try {
    yield callApi(() => postRequest('cms/other-installation', action.payload))
    yield put({
      type: actions.ADD_CMS_OTHER_INSTALLATION_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_CMS_OTHER_INSTALLATION_FAILURE });
  }
}

function* editCmsOtherInstallation(action) {
  try {
    yield callApi(() => putRequest(`cms/other-installation?id=${action.id}`, action.payload))
    yield put({
      type: actions.UPDATE_CMS_OTHER_INSTALLATION_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_CMS_OTHER_INSTALLATION_FAILURE });
  }
}

function* deleteCmsOtherInstallation(action) {
  try {
    yield callApi(() => deleteRequest(`cms/other-installation?id=${action.id}`))
    yield put({
      type: actions.DELETE_CMS_OTHER_INSTALLATION_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_CMS_OTHER_INSTALLATION_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_CMS_OTHER_INSTALLATION, getCmsOtherInstallation)]);
  yield all([takeLatest(actions.ADD_CMS_OTHER_INSTALLATION, addCmsOtherInstallation)]);
  yield all([takeLatest(actions.UPDATE_CMS_OTHER_INSTALLATION, editCmsOtherInstallation)]);
  yield all([takeLatest(actions.DELETE_CMS_OTHER_INSTALLATION, deleteCmsOtherInstallation)]);

}
