import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { putRequest } from "utils/axios-client";

function* updateUserWithOnboardingData(action) {
  try {
    const response = yield callApi(() =>
      putRequest("user/onboard", action.payload)
    );
    yield put({
      type: actions.UPDATE_USER_WITH_ONBOARDING_DATA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_USER_WITH_ONBOARDING_DATA_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      actions.UPDATE_USER_WITH_ONBOARDING_DATA,
      updateUserWithOnboardingData
    ),
  ]);
}
