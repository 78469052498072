import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { postRequest } from "utils/axios-client";

function* editPersonInfo(action) {
  try {
    const response = yield callApi(() => postRequest('edit_customer', action.payload));
    yield put({
      type: actions.EDIT_PERSON_INFO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.EDIT_PERSON_INFO_FAILURE });
  }
}

function* editPassword(action) {
  try {
    const response = yield callApi(() => postRequest('changePassword', action.payload))
    yield put({
      type: actions.EDIT_PASSWORD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.EDIT_PASSWORD_FAILURE });
  }
}


export default function* rootSaga() {
  yield all([takeLatest(actions.EDIT_PERSON_INFO, editPersonInfo)]);
  yield all([takeLatest(actions.EDIT_PASSWORD, editPassword)]);
}
