import actions from "./actions";

const initialState = {
  cmsOtherInstallation: [],
  isFetchingCmsOtherInstallationt: false,

  isChangedCmsOtherInstallationt: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_CMS_OTHER_INSTALLATION:
      return {
        ...state,
        isFetchingCmsOtherInstallationt: true,
      };
    case actions.FETCH_CMS_OTHER_INSTALLATION_SUCCESS:
      return {
        ...state,
        isFetchingCmsOtherInstallationt: false,
        cmsOtherInstallation: action.payload,
      };
    case actions.FETCH_CMS_OTHER_INSTALLATION_FAILURE:
      return { ...state, isFetchingCmsOtherInstallationt: false };
    
    case actions.ADD_CMS_OTHER_INSTALLATION:
      return { ...state, isChangedCmsOtherInstallationt: null};
    case actions.ADD_CMS_OTHER_INSTALLATION_SUCCESS:
      return {...state, isChangedCmsOtherInstallationt: true};
    case actions.ADD_CMS_OTHER_INSTALLATION_FAILURE:
      return { ...state, isChangedCmsOtherInstallationt: false};

    case actions.UPDATE_CMS_OTHER_INSTALLATION:
      return { ...state, isChangedCmsOtherInstallationt: null};
    case actions.UPDATE_CMS_OTHER_INSTALLATION_SUCCESS:
      return {...state, isChangedCmsOtherInstallationt: true};
    case actions.UPDATE_CMS_OTHER_INSTALLATION_FAILURE:
      return { ...state, isChangedCmsOtherInstallationt: false};
      
    case actions.DELETE_CMS_OTHER_INSTALLATION:
      return { ...state, isChangedCmsOtherInstallationt: null};
    case actions.DELETE_CMS_OTHER_INSTALLATION_SUCCESS:
      return {...state, isChangedCmsOtherInstallationt: true};
    case actions.DELETE_CMS_OTHER_INSTALLATION_FAILURE:
      return { ...state, isChangedCmsOtherInstallationt: false};

    default:
      return state;
  }
}

export default Reducer;
