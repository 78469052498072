import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* getRequirementAccording() {
  try {
    const response = yield callApi(() =>
      getRequest("cms/fire-alarm/requirement-according")
    );
    yield put({
      type: actions.FETCH_REQUIREMENT_ACCORDING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_REQUIREMENT_ACCORDING_FAILURE });
  }
}

function* addRequirementAccording(action) {
  try {
    yield callApi(() =>
      postRequest("cms/fire-alarm/requirement-according", action.payload)
    );
    yield put({
      type: actions.ADD_REQUIREMENT_ACCORDING_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_REQUIREMENT_ACCORDING_FAILURE });
  }
}

function* editRequirementAccording(action) {
  try {
    yield callApi(() =>
      putRequest(
        `cms/fire-alarm/requirement-according?id=${action.id}`,
        action.payload
      )
    );
    yield put({
      type: actions.UPDATE_REQUIREMENT_ACCORDING_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_REQUIREMENT_ACCORDING_FAILURE });
  }
}

function* deleteRequirementAccording(action) {
  try {
    yield callApi(() =>
      deleteRequest(`cms/fire-alarm/requirement-according?id=${action.id}`)
    );
    yield put({
      type: actions.DELETE_REQUIREMENT_ACCORDING_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_REQUIREMENT_ACCORDING_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.FETCH_REQUIREMENT_ACCORDING, getRequirementAccording),
  ]);
  yield all([
    takeLatest(actions.ADD_REQUIREMENT_ACCORDING, addRequirementAccording),
  ]);
  yield all([
    takeLatest(actions.UPDATE_REQUIREMENT_ACCORDING, editRequirementAccording),
  ]);
  yield all([
    takeLatest(
      actions.DELETE_REQUIREMENT_ACCORDING,
      deleteRequirementAccording
    ),
  ]);
}
