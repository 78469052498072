const actions = {
  FETCH_DUE_DATE: "FETCH_DUE_DATE",
  FETCH_DUE_DATE_SUCCESS: "FETCH_DUE_DATE_SUCCESS",
  FETCH_DUE_DATE_FAILURE: "FETCH_DUE_DATE_FAILURE",

  EDIT_DUE_DATE: "EDIT_DUE_DATE",
  EDIT_DUE_DATE_SUCCESS: "EDIT_DUE_DATE_SUCCESS",
  EDIT_DUE_DATE_FAILURE: "EDIT_DUE_DATE_FAILURE",
};

export default actions;
