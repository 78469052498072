import actions from "./actions";

const initialState = {
  buildingSupervisoryReport: [],
  isFecthingBuildingSupervisoryReport: false,
  buildingCreatedId: null,
  buildingSupervisoryReportFile: null,
  buildingSupervisoryReportCustomers: [],
  buildingSupervisoryReportHistories: [],
  isFecthingBuildingSupervisoryReportHistories: false,
  isFinishedBuildingSupervisoryReports: null,
  isFinishedBuildingSupervisoryReportsFile: null,
  isFinishedBuildingSupervisoryReportsFileConfirm: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_SUPERVISORY_REPORT:
      return {
        ...state,
        buildingSupervisoryReport: [],
        buildingSupervisoryReportCustomers: [],
        isFecthingBuildingSupervisoryReport: true,
      };
    case actions.FETCH_BUILDING_SUPERVISORY_REPORT_SUCCESS:
      return {
        ...state,
        isFecthingBuildingSupervisoryReport: false,
        buildingSupervisoryReport: action.payload,
        buildingSupervisoryReportCustomers: action.payloadCustomer,
      };
    case actions.FETCH_BUILDING_SUPERVISORY_REPORT_FAILURE:
      return { ...state, isFecthingBuildingSupervisoryReport: false };

    case actions.FETCH_BUILDING_FIRE_HISTORIES:
      return {
        ...state,
        isFecthingBuildingSupervisoryReportHistories: true,
      };
    case actions.FETCH_BUILDING_FIRE_HISTORIES_SUCCESS:
      return {
        ...state,
        isFecthingBuildingSupervisoryReportHistories: false,
        buildingSupervisoryReportHistories: action.payload,
      };
    case actions.FETCH_BUILDING_FIRE_HISTORIES_FAILURE:
      return { ...state, isFecthingBuildingSupervisoryReportHistories: false };

    case actions.CREATE_BUILDING_SUPERVISORY_REPORT:
      return { ...state, isFinishedBuildingSupervisoryReports: null };
    case actions.CREATE_BUILDING_SUPERVISORY_REPORT_SUCCESS:
      return {
        ...state,
        isFinishedBuildingSupervisoryReports: true,
        buildingCreatedId: action.payload,
      };
    case actions.CREATE_BUILDING_SUPERVISORY_REPORT_FAILURE:
      return { ...state, isFinishedBuildingSupervisoryReports: false };

    case actions.UPDATE_BUILDING_SUPERVISORY_REPORT:
      return { ...state, isFinishedBuildingSupervisoryReports: null };
    case actions.UPDATE_BUILDING_SUPERVISORY_REPORT_SUCCESS:
      return { ...state, isFinishedBuildingSupervisoryReports: true };
    case actions.UPDATE_BUILDING_SUPERVISORY_REPORT_FAILURE:
      return { ...state, isFinishedBuildingSupervisoryReports: false };

    case actions.UPDATE_BUILDING_SUPERVISORY_REPORT_FILES:
      return { ...state, isFinishedBuildingSupervisoryReportsFile: null };
    case actions.UPDATE_BUILDING_SUPERVISORY_REPORT_FILES_SUCCESS:
      return {
        ...state,
        isFinishedBuildingSupervisoryReportsFile: true,
        buildingSupervisoryReportFile: action.payload,
      };
    case actions.UPDATE_BUILDING_SUPERVISORY_REPORT_FILES_FAILURE:
      return { ...state, isFinishedBuildingSupervisoryReportsFile: false };

    case actions.UPDATE_BUILDING_SUPERVISORY_REPORT_CONFIRM:
      return {
        ...state,
        isFinishedBuildingSupervisoryReportsFileConfirm: null,
      };
    case actions.UPDATE_BUILDING_SUPERVISORY_REPORT_CONFIRM_SUCCESS:
      return {
        ...state,
        isFinishedBuildingSupervisoryReportsFileConfirm: true,
      };
    case actions.UPDATE_BUILDING_SUPERVISORY_REPORT_CONFIRM_FAILURE:
      return {
        ...state,
        isFinishedBuildingSupervisoryReportsFileConfirm: false,
      };

    default:
      return state;
  }
}

export default Reducer;
