import actions from "./actions";

const initialState = {
  buildingCheckpoint: [],
  isFetchingBuildingCheckpoint: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_CHECKPOINTS:
      return {
        ...state,
        isFetchingBuildingCheckpoint: true,
      };
    case actions.FETCH_BUILDING_CHECKPOINTS_SUCCESS:
      return {
        ...state,
        isFetchingBuildingCheckpoint: false,
        buildingCheckpoint: action.payload,
      };
    case actions.FETCH_BUILDING_CHECKPOINTS_FAILURE:
      return { ...state, isFetchingBuildingCheckpoint: false };

    default:
      return state;
  }
}

export default Reducer;
