const actions = {
  FETCH_ALL_DATA_FOR_CREATE_VENDOR: "FETCH_ALL_DATA_FOR_CREATE_VENDOR",
  FETCH_ALL_DATA_FOR_CREATE_VENDOR_SUCCESS: "FETCH_ALL_DATA_FOR_CREATE_VENDOR_SUCCESS",
  FETCH_ALL_DATA_FOR_CREATE_VENDOR_FAILURE: "FETCH_ALL_DATA_FOR_CREATE_VENDOR_FAILURE",

  FETCH_VENDORS: "FETCH_VENDORS",
  FETCH_VENDORS_SUCCESS: "FETCH_VENDORS_SUCCESS",
  FETCH_VENDORS_FAILURE: "FETCH_VENDORS_FAILURE",

  FETCH_ADDRESS: "FETCH_ADDRESS",
  FETCH_ADDRESS_SUCCESS: "FETCH_ADDRESS_SUCCESS",
  FETCH_ADDRESS_FAILURE: "FETCH_ADDRESS_FAILURE",

  CREATE_CONTACT_FOR_VENDOR: "CREATE_CONTACT_FOR_VENDOR",
  CREATE_CONTACT_FOR_VENDOR_SUCCESS: "CREATE_CONTACT_FOR_VENDOR_SUCCESS",
  CREATE_CONTACT_FOR_VENDOR_FAILURE: "CREATE_CONTACT_FOR_VENDOR_FAILURE",

  UPDATE_CONTACT_FOR_VENDOR: "UPDATE_CONTACT_FOR_VENDOR",
  UPDATE_CONTACT_FOR_VENDOR_SUCCESS: "UPDATE_CONTACT_FOR_VENDOR_SUCCESS",
  UPDATE_CONTACT_FOR_VENDOR_FAILURE: "UPDATE_CONTACT_FOR_VENDOR_FAILURE",

  DELETE_CONTACT_FOR_VENDOR: "DELETE_CONTACT_FOR_VENDOR",
  DELETE_CONTACT_FOR_VENDOR_SUCCESS: "DELETE_CONTACT_FOR_VENDOR_SUCCESS",
  DELETE_CONTACT_FOR_VENDOR_FAILURE: "DELETE_CONTACT_FOR_VENDOR_FAILURE",

  CREATE_VENDOR_GENERAL: "CREATE_VENDOR_GENERAL",
  CREATE_VENDOR_GENERAL_SUCCESS: "CREATE_VENDOR_GENERAL_SUCCESS",
  CREATE_VENDOR_GENERAL_FAILURE: "CREATE_VENDOR_GENERAL_FAILURE",

  UPDATE_VENDOR_GENERAL: "UPDATE_VENDOR_GENERAL",
  UPDATE_VENDOR_GENERAL_SUCCESS: "UPDATE_VENDOR_GENERAL_SUCCESS",
  UPDATE_VENDOR_GENERAL_FAILURE: "UPDATE_VENDOR_GENERAL_FAILURE",

  CREATE_VENDOR_ADDRESS: "CREATE_VENDOR_ADDRESS",
  CREATE_VENDOR_ADDRESS_SUCCESS: "CREATE_VENDOR_ADDRESS_SUCCESS",
  CREATE_VENDOR_ADDRESS_FAILURE: "CREATE_VENDOR_ADDRESS_FAILURE",

  UPDATE_VENDOR_ADDRESS: "UPDATE_VENDOR_ADDRESS",
  UPDATE_VENDOR_ADDRESS_SUCCESS: "UPDATE_VENDOR_ADDRESS_SUCCESS",
  UPDATE_VENDOR_ADDRESS_FAILURE: "UPDATE_VENDOR_ADDRESS_FAILURE",

  CREATE_BUILDING_GROUP_FOR_VENDOR: "CREATE_BUILDING_GROUP_FOR_VENDOR",
  CREATE_BUILDING_GROUP_FOR_VENDOR_SUCCESS: "CREATE_BUILDING_GROUP_FOR_VENDOR_SUCCESS",
  CREATE_BUILDING_GROUP_FOR_VENDOR_FAILURE: "CREATE_BUILDING_GROUP_FOR_VENDOR_FAILURE",

  INITIALIZE_STATES: "INITIALIZE_STATES",
};

export default actions;
