export const actions = {
  GETROLE: "GETROLE",
  GETROLE_SUCCESS: "GETROLE_SUCCESS",
  GETROLE_FAILURE: "GETROLE_FAILURE",

  ADDROLE: "ADDROLE",
  ADDROLE_SUCCESS: "ADDROLE_SUCCESS",
  ADDROLE_FAILURE: "ADDROLE_FAILURE",
  
  EDITROLE: "EDITROLE",
  EDITROLE_SUCCESS: "EDITROLE_SUCCESS",
  EDITROLE_FAILURE: "EDITROLE_FAILURE",

  DELETEROLE: "DELETEROLE",
  DELETEROLE_SUCCESS: "DELETEROLE_SUCCESS",
  DELETEROLE_FAILURE: "DELETEROLE_FAILURE",

  // GETPERMISSION: "GETPERMISSION",
  // GETPERMISSION_SUCCESS: "GETPERMISSION_SUCCESS",
  // GETPERMISSION_FAILURE: "GETPERMISSION_FAILURE",

  ADDPERMISSION: "ADDPERMISSION",
  ADDPERMISSION_SUCCESS: "ADDPERMISSION_SUCCESS",
  ADDPERMISSION_FAILURE: "ADDPERMISSION_FAILURE",
  
  EDITPERMISSION: "EDITPERMISSION",
  EDITPERMISSION_SUCCESS: "EDITPERMISSION_SUCCESS",
  EDITPERMISSION_FAILURE: "EDITPERMISSION_FAILURE",

  // DELETEPERMISSION: "DELETEPERMISSION",
  // DELETEPERMISSION_SUCCESS: "DELETEPERMISSION_SUCCESS",
  // DELETEPERMISSION_FAILURE: "DELETEPERMISSION_FAILURE",

  // GETROLEPERMISSION: "GETROLEPERMISSION",
  // GETROLEPERMISSION_SUCCESS: "GETROLEPERMISSION_SUCCESS",
  // GETROLEPERMISSION_FAILURE: "GETROLEPERMISSION_FAILURE",

  ADDROLEPERMISSION: "ADDROLEPERMISSION",
  ADDROLEPERMISSION_SUCCESS: "ADDROLEPERMISSION_SUCCESS",
  ADDROLEPERMISSION_FAILURE: "ADDROLEPERMISSION_FAILURE",
};

export default actions;
