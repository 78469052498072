import actions from "./actions";

const initialState = {
  centralType: [],
  isFetchingCentralType: false,

  isChangedAddCentralType: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_CENTRAL:
      return {
        ...state,
        isFetchingCentralType: true,
      };
    case actions.FETCH_CENTRAL_SUCCESS:
      return {
        ...state,
        isFetchingCentralType: false,
        centralType: action.payload,
      };
    case actions.FETCH_CENTRAL_FAILURE:
      return { ...state, isFetchingCentralType: false };
    
    case actions.ADD_CENTRAL:
      return { ...state, isChangedAddCentralType: null};
    case actions.ADD_CENTRAL_SUCCESS:
      return {...state, isChangedAddCentralType: true};
    case actions.ADD_CENTRAL_FAILURE:
      return { ...state, isChangedAddCentralType: false};

    case actions.UPDATE_CENTRAL:
      return { ...state, isChangedAddCentralType: null};
    case actions.UPDATE_CENTRAL_SUCCESS:
      return {...state, isChangedAddCentralType: true};
    case actions.UPDATE_CENTRAL_FAILURE:
      return { ...state, isChangedAddCentralType: false};
      
    case actions.DELETE_CENTRAL:
      return { ...state, isChangedAddCentralType: null};
    case actions.DELETE_CENTRAL_SUCCESS:
      return {...state, isChangedAddCentralType: true};
    case actions.DELETE_CENTRAL_FAILURE:
      return { ...state, isChangedAddCentralType: false};

    default:
      return state;
  }
}

export default Reducer;
