const actions = {
  FETCH_PROPERTIES: "FETCH_PROPERTIES",
  FETCH_PROPERTIES_SUCCESS: "FETCH_PROPERTIES_SUCCESS",
  FETCH_PROPERTIES_FAILURE: "FETCH_PROPERTIES_FAILURE",

  FETCH_PROPERTIES_WITH_USER: "FETCH_PROPERTIES_WITH_USER",
  FETCH_PROPERTIES_WITH_USER_SUCCESS: "FETCH_PROPERTIES_WITH_USER_SUCCESS",
  FETCH_PROPERTIES_WITH_USER_FAILURE: "FETCH_PROPERTIES_WITH_USER_FAILURE",

  FETCH_PROPERTY: "FETCH_PROPERTY",
  FETCH_PROPERTY_SUCCESS: "FETCH_PROPERTY_SUCCESS",
  FETCH_PROPERTY_FAILURE: "FETCH_PROPERTY_FAILURE",

  CREATE_PROPERTY: "CREATE_PROPERTY",
  CREATE_PROPERTY_SUCCESS: "CREATE_PROPERTY_SUCCESS",
  CREATE_PROPERTY_FAILURE: "CREATE_PROPERTY_FAILURE",

  FETCH_USER_FOR_PROPERTY: "FETCH_USER_FOR_PROPERTY",
  FETCH_USER_FOR_PROPERTY_SUCCESS: "FETCH_USER_FOR_PROPERTY_SUCCESS",
  FETCH_USER_FOR_PROPERTY_FAILURE: "FETCH_USER_FOR_PROPERTY_FAILURE",
};

export default actions;
