import actions from "./actions";

const initialState = {
  dueDate: [],
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_DUE_DATE_SUCCESS:
      return {
        ...state,
        dueDate: action.payload,
      };

    default:
      return state;
  }
}

export default Reducer;
