import actions from "./actions";

const initialState = {
  buildingOwner: [],
  isFecthingBuildingOwner: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_OWNER:
      return {
        ...state,
        isFecthingBuildingOwner: true,
        buildingOwner: [],
      };
    case actions.FETCH_BUILDING_OWNER_SUCCESS:
      return {
        ...state,
        isFecthingBuildingOwner: false,
        buildingOwner: action.payload,
      };
    case actions.FETCH_BUILDING_OWNER_FAILURE:
      return { ...state, isFecthingBuildingOwner: false };

    case actions.CREATE_BUILDING_OWNER:
      return {
        ...state,
        isFecthingBuildingOwner: true,
      };
    case actions.CREATE_BUILDING_OWNER_SUCCESS:
      return {
        ...state,
        isFecthingBuildingOwner: false,
        buildingOwner: action.payload,
      };
    case actions.CREATE_BUILDING_OWNER_FAILURE:
      return { ...state, isFecthingBuildingOwner: false };

    default:
      return state;
  }
}

export default Reducer;
