import actions from "./actions";

const initialState = {
  yearlyControl: [],
  yearlyControlComment: [],
  isFetchingYearlyControl: false,

  isChangedYearlyControl: null,
  isChangedYearlyControlComment: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_YEARLY_CONTROL:
      return {
        ...state,
        isChangedYearlyControl: null,
        isFetchingYearlyControl: true,
      };
    case actions.FETCH_YEARLY_CONTROL_SUCCESS:
      return {
        ...state,
        isFetchingYearlyControl: false,
        yearlyControl: action.payload,
      };
    case actions.FETCH_YEARLY_CONTROL_FAILURE:
      return { ...state, isFetchingYearlyControl: false };

    case actions.CREATE_YEARLY_CONTROL:
      return {
        ...state,
        isChangedYearlyControl: null,
      };
    case actions.CREATE_YEARLY_CONTROL_SUCCESS:
      return {
        ...state,
        isChangedYearlyControl: true,
      };
    case actions.CREATE_YEARLY_CONTROL_FAILURE:
      return { ...state, isChangedYearlyControl: false };

    case actions.UPDATE_YEARLY_CONTROL:
      return {
        ...state,
        isChangedYearlyControl: null,
      };
    case actions.UPDATE_YEARLY_CONTROL_SUCCESS:
      return {
        ...state,
        isChangedYearlyControl: true,
        yearlyControl: action.payload,
      };
    case actions.UPDATE_YEARLY_CONTROL_FAILURE:
      return { ...state, isChangedYearlyControl: false };

    case actions.DELETE_YEARLY_CONTROL:
      return {
        ...state,
        isChangedYearlyControl: null,
      };
    case actions.DELETE_YEARLY_CONTROL_SUCCESS:
      return {
        ...state,
        isChangedYearlyControl: true,
      };
    case actions.DELETE_YEARLY_CONTROL_FAILURE:
      return { ...state, isChangedYearlyControl: false };

    case actions.CREATE_YEARLY_CONTROL_COMMENT:
      return {
        ...state,
        isChangedYearlyControlComment: null,
      };
    case actions.CREATE_YEARLY_CONTROL_COMMENT_SUCCESS:
      return {
        ...state,
        isChangedYearlyControlComment: true,
      };
    case actions.CREATE_YEARLY_CONTROL_COMMENT_FAILURE:
      return { ...state, isChangedYearlyControlComment: false };

    case actions.UPDATE_YEARLY_CONTROL_COMMENT:
      return {
        ...state,
        isChangedYearlyControlComment: null,
      };
    case actions.UPDATE_YEARLY_CONTROL_COMMENT_SUCCESS:
      return {
        ...state,
        isChangedYearlyControlComment: true,
      };
    case actions.UPDATE_YEARLY_CONTROL_COMMENT_FAILURE:
      return { ...state, isChangedYearlyControlComment: false };

    default:
      return state;
  }
}

export default Reducer;
