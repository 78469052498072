import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest } from "utils/axios-client";

function* fetchOrganizations(action) {
  // mockAdapter.onGet("/organizations").reply(200, organizations);

  try {
    let response;
    if (action.payload)
      response = yield callApi(() =>
        getRequest(`building/fire-organization?buildingId=${action.payload}`)
      );
    else
      response = yield callApi(() => getRequest(`building/fire-organization`));
    yield put({
      type: actions.FETCH_ORGANIZATIONS_SUCCESS,
      payload: response.data.building_protections,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_ORGANIZATIONS_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_ORGANIZATIONS, fetchOrganizations)]);
}
