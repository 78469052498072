import actions from "./actions";

const initialState = {
  exercise: [],
  isFetchingExercise: false,

  isChangedExercise: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_EXERCISE:
      return {
        ...state,
        isFetchingExercise: true,
        isChangedExercise: null,
      };
    case actions.FETCH_EXERCISE_SUCCESS:
      return {
        ...state,
        isFetchingExercise: false,
        exercise: action.payload,
      };
    case actions.FETCH_EXERCISE_FAILURE:
      return { ...state, isFetchingExercise: false };

    case actions.CREATE_EXERCISE:
      return { ...state, isChangedExercise: null };
    case actions.CREATE_EXERCISE_SUCCESS:
      return { ...state, isChangedExercise: true };
    case actions.CREATE_EXERCISE_FAILURE:
      return { ...state, isChangedExercise: false };

    case actions.UPDATE_EXERCISE:
      return { ...state, isChangedExercise: null };
    case actions.UPDATE_EXERCISE_SUCCESS:
      return { ...state, isChangedExercise: true };
    case actions.UPDATE_EXERCISE_FAILURE:
      return { ...state, isChangedExercise: false };

    case actions.DELETE_EXERCISE:
      return { ...state, isChangedExercise: null };
    case actions.DELETE_EXERCISE_SUCCESS:
      return { ...state, isChangedExercise: true };
    case actions.DELETE_EXERCISE_FAILURE:
      return { ...state, isChangedExercise: false };

    default:
      return state;
  }
}

export default Reducer;
