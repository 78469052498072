import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* getCmsInformations() {
  try {
    const response = yield callApi(() => getRequest("cms/information"));
    yield put({
      type: actions.FETCH_CMS_INFORMATIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_CMS_INFORMATIONS_FAILURE });
  }
}

function* addCmsInformations(action) {
  try {
    yield callApi(() => postRequest("cms/information", action.payload));
    yield put({
      type: actions.ADD_CMS_INFORMATIONS_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_CMS_INFORMATIONS_FAILURE });
  }
}

function* editCmsInformations(action) {
  try {
    yield callApi(() =>
      putRequest(`cms/information?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_CMS_INFORMATIONS_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_CMS_INFORMATIONS_FAILURE });
  }
}

function* deleteCmsInformations(action) {
  try {
    yield callApi(() => deleteRequest(`cms/information?id=${action.id}`));
    yield put({
      type: actions.DELETE_CMS_INFORMATIONS_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_CMS_INFORMATIONS_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_CMS_INFORMATIONS, getCmsInformations)]);
  yield all([takeLatest(actions.ADD_CMS_INFORMATIONS, addCmsInformations)]);
  yield all([takeLatest(actions.UPDATE_CMS_INFORMATIONS, editCmsInformations)]);
  yield all([
    takeLatest(actions.DELETE_CMS_INFORMATIONS, deleteCmsInformations),
  ]);
}
