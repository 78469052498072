import actions from "./actions";

const initialState = {
  fireDrawing: [],
  isFecthingFireDrawing: false,
  createdFireDrawing: null,
  fireDrawingCustomers: [],
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_FIRE_DRAWING:
      return {
        ...state,
        isFecthingFireDrawing: true,
        fireDrawing: [],
      };
    case actions.FETCH_FIRE_DRAWING_SUCCESS:
      return {
        ...state,
        isFecthingFireDrawing: false,
        fireDrawing: action.payload,
      };
    case actions.FETCH_FIRE_DRAWING_FAILURE:
      return { ...state, isFecthingFireDrawing: false };

    case actions.FETCH_FIRE_DRAWING_CUSTOMER_SUCCESS:
      return {
        ...state,
        fireDrawingCustomers: action.payload,
      };

    case actions.CREATE_FIRE_DRAWING:
      return {
        ...state,
        createdFireDrawing: null,
      };
    case actions.CREATE_FIRE_DRAWING_SUCCESS:
      return {
        ...state,
        createdFireDrawing: action.payload,
        fireDrawing: action.payload,
      };
    case actions.CREATE_FIRE_DRAWING_FAILURE:
      return {
        ...state,
        createdFireDrawing: null,
      };

    case actions.UPDATE_FIRE_DRAWING_SUCCESS:
      return { ...state, fireDrawing: action.payload };
    default:
      return state;
  }
}

export default Reducer;
