const actions = {
  FETCH_YEARLY_CONTROL: "FETCH_YEARLY_CONTROL",
  FETCH_YEARLY_CONTROL_SUCCESS: "FETCH_YEARLY_CONTROL_SUCCESS",
  FETCH_YEARLY_CONTROL_FAILURE: "FETCH_YEARLY_CONTROL_FAILURE",

  CREATE_YEARLY_CONTROL: "CREATE_YEARLY_CONTROL",
  CREATE_YEARLY_CONTROL_SUCCESS: "CREATE_YEARLY_CONTROL_SUCCESS",
  CREATE_YEARLY_CONTROL_FAILURE: "CREATE_YEARLY_CONTROL_FAILURE",

  UPDATE_YEARLY_CONTROL: "UPDATE_YEARLY_CONTROL",
  UPDATE_YEARLY_CONTROL_SUCCESS: "UPDATE_YEARLY_CONTROL_SUCCESS",
  UPDATE_YEARLY_CONTROL_FAILURE: "UPDATE_YEARLY_CONTROL_FAILURE",

  DELETE_YEARLY_CONTROL: "DELETE_YEARLY_CONTROL",
  DELETE_YEARLY_CONTROL_SUCCESS: "DELETE_YEARLY_CONTROL_SUCCESS",
  DELETE_YEARLY_CONTROL_FAILURE: "DELETE_YEARLY_CONTROL_FAILURE",

  CREATE_YEARLY_CONTROL_COMMENT: "CREATE_YEARLY_CONTROL_COMMENT",
  CREATE_YEARLY_CONTROL_COMMENT_SUCCESS:
    "CREATE_YEARLY_CONTROL_COMMENT_SUCCESS",
  CREATE_YEARLY_CONTROL_COMMENT_FAILURE:
    "CREATE_YEARLY_CONTROL_COMMENT_FAILURE",

  UPDATE_YEARLY_CONTROL_COMMENT: "UPDATE_YEARLY_CONTROL_COMMENT",
  UPDATE_YEARLY_CONTROL_COMMENT_SUCCESS:
    "UPDATE_YEARLY_CONTROL_COMMENT_SUCCESS",
  UPDATE_YEARLY_CONTROL_COMMENT_FAILURE:
    "UPDATE_YEARLY_CONTROL_COMMENT_FAILURE",
};

export default actions;
