import actions from "./actions";

const initialState = {
  buildingFireFiles: [],
  isFetchingBuildingFireFiles: false,
  isChangedAddBuildingFireFiles: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_FIRE_FILES:
      return {
        ...state,
        buildingFireFiles: [],
        isFetchingBuildingFireFiles: true,
      };
    case actions.FETCH_BUILDING_FIRE_FILES_SUCCESS:
      return {
        ...state,
        isFetchingBuildingFireFiles: false,
        buildingFireFiles: action.payload,
      };
    case actions.FETCH_BUILDING_FIRE_FILES_FAILURE:
      return { ...state, isFetchingBuildingFireFiles: false };

    case actions.ADD_BUILDING_FIRE_FILES:
      return { ...state, isChangedAddBuildingFireFiles: null };
    case actions.ADD_BUILDING_FIRE_FILES_SUCCESS:
      return { ...state, isChangedAddBuildingFireFiles: true };
    case actions.ADD_BUILDING_FIRE_FILES_FAILURE:
      return { ...state, isChangedAddBuildingFireFiles: false };

    case actions.UPDATE_BUILDING_FIRE_FILES:
      return { ...state, isChangedAddBuildingFireFiles: null };
    case actions.UPDATE_BUILDING_FIRE_FILES_SUCCESS:
      return { ...state, isChangedAddBuildingFireFiles: true };
    case actions.UPDATE_BUILDING_FIRE_FILES_FAILURE:
      return { ...state, isChangedAddBuildingFireFiles: false };

    case actions.DELETE_BUILDING_FIRE_FILES:
      return { ...state, isChangedAddBuildingFireFiles: null };
    case actions.DELETE_BUILDING_FIRE_FILES_SUCCESS:
      return { ...state, isChangedAddBuildingFireFiles: true };
    case actions.DELETE_BUILDING_FIRE_FILES_FAILURE:
      return { ...state, isChangedAddBuildingFireFiles: false };

    case actions.RESET_BUILDING_FIRE_FILES:
      return { ...state, isChangedAddBuildingFireFiles: null };

    default:
      return state;
  }
}

export default Reducer;
