import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchBasicData() {
  try {
    const response = yield callApi(() => getRequest('cms/extinquishing-equipment'));
    yield put({
      type: actions.FETCH_BASIC_DATA_EQUIPMENT_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BASIC_DATA_EQUIPMENT_FAILURE });
  }
}

function* fetchBuildingEquipment(action) {
  try {
    const response = yield callApi(() => getRequest(`building/extinquishing-equipment?buildingId=${action.payload}`))
    yield put({
      type: actions.FETCH_BUILDING_INSTALLATION_EQUIPMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_INSTALLATION_EQUIPMENT_FAILURE });
  }
}

function* createBuildingEquipment(action) {
  try {
    const response = yield callApi(() => postRequest(`building/extinquishing-equipment?buildingId=${action.buildingId}`, action.payload))
    yield put({
      type: actions.CREATE_BUILDING_INSTALLATION_EQUIPMENT_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.CREATE_BUILDING_INSTALLATION_EQUIPMENT_FAILURE });
  }
}

function* updateBuildingEquipment(action) {
  try {
    const response = yield callApi(() => putRequest(`building/extinquishing-equipment?id=${action.id}`, action.payload))
    yield put({
      type: actions.UPDATE_BUILDING_INSTALLATION_EQUIPMENT_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_INSTALLATION_EQUIPMENT_FAILURE });
  }
}


export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_BASIC_DATA_EQUIPMENT, fetchBasicData)]);
  yield all([takeLatest(actions.FETCH_BUILDING_INSTALLATION_EQUIPMENT, fetchBuildingEquipment)]);
  yield all([takeLatest(actions.CREATE_BUILDING_INSTALLATION_EQUIPMENT, createBuildingEquipment)]);
  yield all([takeLatest(actions.UPDATE_BUILDING_INSTALLATION_EQUIPMENT, updateBuildingEquipment)]);
}
