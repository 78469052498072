import actions from "./actions";

const initialState = {
  buildingThemeCheck: [],
  isFetchingBuildingThemeCheck: false,

  mustFetchThemeCheck: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_THEME_CHECK:
      return {
        ...state,
        mustFetchThemeCheck: null,
        isFetchingBuildingThemeCheck: true,
      };
    case actions.FETCH_BUILDING_THEME_CHECK_SUCCESS:
      return {
        ...state,
        isFetchingBuildingThemeCheck: false,
        buildingThemeCheck: action.payload,
      };
    case actions.FETCH_BUILDING_THEME_CHECK_FAILURE:
      return {
        ...state,
        isFetchingBuildingThemeCheck: false,
        buildingThemeCheck: [],
      };

    case actions.ADD_BUILDING_THEME_CHECK:
      return { ...state, mustFetchThemeCheck: null };
    case actions.ADD_BUILDING_THEME_CHECK_SUCCESS:
      return { ...state, mustFetchThemeCheck: true };
    case actions.ADD_BUILDING_THEME_CHECK_FAILURE:
      return { ...state, mustFetchThemeCheck: false };

    case actions.UPDATE_BUILDING_THEME_CHECK:
      return { ...state, mustFetchThemeCheck: null };
    case actions.UPDATE_BUILDING_THEME_CHECK_SUCCESS:
      return { ...state, mustFetchThemeCheck: true };
    case actions.UPDATE_BUILDING_THEME_CHECK_FAILURE:
      return { ...state, mustFetchThemeCheck: false };

    case actions.DELETE_BUILDING_THEME_CHECK:
      return { ...state, mustFetchThemeCheck: null };
    case actions.DELETE_BUILDING_THEME_CHECK_SUCCESS:
      return { ...state, mustFetchThemeCheck: true };
    case actions.DELETE_BUILDING_THEME_CHECK_FAILURE:
      return { ...state, mustFetchThemeCheck: false };

    case actions.RESET_BUILDING_THEME_CHECK:
      return { ...state, mustFetchThemeCheck: null };

    default:
      return state;
  }
}

export default Reducer;
