import actions from "./actions";

const initialState = {
  serviceCall: [],
  serviceCallComment: [],
  isFetchingServiceCall: false,

  isChangedServiceCall: null,
  isChangedServiceCallComment: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_SERVICE_CALL:
      return {
        ...state,
        isChangedServiceCall: null,
        isFetchingServiceCall: true,
      };
    case actions.FETCH_SERVICE_CALL_SUCCESS:
      return {
        ...state,
        isFetchingServiceCall: false,
        serviceCall: action.payload,
      };
    case actions.FETCH_SERVICE_CALL_FAILURE:
      return { ...state, isFetchingServiceCall: false };

    case actions.CREATE_SERVICE_CALL:
      return {
        ...state,
        isChangedServiceCall: null,
      };
    case actions.CREATE_SERVICE_CALL_SUCCESS:
      return {
        ...state,
        isChangedServiceCall: true,
      };
    case actions.CREATE_SERVICE_CALL_FAILURE:
      return { ...state, isChangedServiceCall: false };

    case actions.UPDATE_SERVICE_CALL:
      return {
        ...state,
        isChangedServiceCall: null,
      };
    case actions.UPDATE_SERVICE_CALL_SUCCESS:
      return {
        ...state,
        serviceCall: action.payload,
        isChangedServiceCall: true,
      };
    case actions.UPDATE_SERVICE_CALL_FAILURE:
      return { ...state, isChangedServiceCall: false };

    case actions.DELETE_SERVICE_CALL:
      return {
        ...state,
        isChangedServiceCall: null,
      };
    case actions.DELETE_SERVICE_CALL_SUCCESS:
      return {
        ...state,
        isChangedServiceCall: true,
      };
    case actions.DELETE_SERVICE_CALL_FAILURE:
      return { ...state, isChangedServiceCall: false };

    case actions.CREATE_SERVICE_CALL_COMMENT:
      return {
        ...state,
        isChangedServiceCallComment: null,
      };
    case actions.CREATE_SERVICE_CALL_COMMENT_SUCCESS:
      return {
        ...state,
        isChangedServiceCallComment: true,
      };
    case actions.CREATE_SERVICE_CALL_COMMENT_FAILURE:
      return { ...state, isChangedServiceCallComment: false };

    case actions.UPDATE_SERVICE_CALL_COMMENT:
      return {
        ...state,
        isChangedServiceCallComment: null,
      };
    case actions.UPDATE_SERVICE_CALL_COMMENT_SUCCESS:
      return {
        ...state,
        isChangedServiceCallComment: true,
      };
    case actions.UPDATE_SERVICE_CALL_COMMENT_FAILURE:
      return { ...state, isChangedServiceCallComment: false };

    default:
      return state;
  }
}

export default Reducer;
