const actions = {
  FETCH_CUSTOMERS: "FETCH_CUSTOMERS",
  FETCH_CUSTOMERS_SUCCESS: "FETCH_CUSTOMERS_SUCCESS",
  FETCH_CUSTOMERS_FAILURE: "FETCH_CUSTOMERS_FAILURE",

  FETCH_CUSTOMERS_ONLY: "FETCH_CUSTOMERS_ONLY",
  FETCH_CUSTOMERS_ONLY_SUCCESS: "FETCH_CUSTOMERS_ONLY_SUCCESS",
  FETCH_CUSTOMERS_ONLY_FAILURE: "FETCH_CUSTOMERS_ONLY_FAILURE",


  UPDATE_CUSTOMERS: "UPDATE_CUSTOMERS",
  UPDATE_CUSTOMERS_SUCCESS: "UPDATE_CUSTOMERS_SUCCESS",
  UPDATE_CUSTOMERS_FAILURE: "UPDATE_CUSTOMERS_FAILURE",
};

export default actions;
