export const actions = {
  EDIT_PERSON_INFO: "EDIT_PERSON_INFO",
  EDIT_PERSON_INFO_SUCCESS: "EDIT_PERSON_INFO_SUCCESS",
  EDIT_PERSON_INFO_FAILURE: "EDIT_PERSON_INFO_FAILURE",

  EDIT_PASSWORD: "EDIT_PASSWORD",
  EDIT_PASSWORD_SUCCESS: "EDIT_PASSWORD_SUCCESS",
  EDIT_PASSWORD_FAILURE: "EDIT_PASSWORD_FAILURE",
};

export default actions;
