import { actions } from "./actions";

const initialState = {
  path: null,
  isUploadingFile: false,

  isDownloadingFile: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FILE_UPLAOD:
      return {
        ...state,
        path: null,
        isUploadingFile: true,
      };

    case actions.FILE_UPLAOD_SUCCESS:
      return {
        ...state,
        isUploadingFile: false,
        path: action.payload,
      };

    case actions.FETCH_BASIC_DATA_FAILURE:
      return {
        ...state,
        path: null,
        isUploadingFile: false,
      }

    case actions.FILE_DOWNLOAD:
      return { ...state, isDownloadingFile: true };

    case actions.FILE_DOWNLOAD_SUCCESS:
      return { ...state, isDownloadingFile: false };

    case actions.FILE_DOWNLOAD_FAILURE:
      return { ...state, isDownloadingFile: false };

    default:
      return state;
  }
}

export default Reducer;
