import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* getBuildingThemeCheck(action) {
  try {
    const response = yield callApi(() =>
      getRequest(
        `building/theme-checks?theme=${action.theme}&themeRecordId=${action.themeRecordId}`
      )
    );
    yield put({
      type: actions.FETCH_BUILDING_THEME_CHECK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_THEME_CHECK_FAILURE });
  }
}

function* addBuildingThemeCheck(action) {
  try {
    yield callApi(() => postRequest("building/theme-checks", action.payload));
    yield put({
      type: actions.ADD_BUILDING_THEME_CHECK_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_BUILDING_THEME_CHECK_FAILURE });
  }
}

function* editBuildingThemeCheck(action) {
  try {
    yield callApi(() =>
      putRequest(`building/theme-checks?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_BUILDING_THEME_CHECK_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_THEME_CHECK_FAILURE });
  }
}

function* deleteBuildingThemeCheck(action) {
  try {
    yield callApi(() => deleteRequest(`building/theme-checks?id=${action.id}`));
    yield put({
      type: actions.DELETE_BUILDING_THEME_CHECK_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_BUILDING_THEME_CHECK_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.FETCH_BUILDING_THEME_CHECK, getBuildingThemeCheck),
  ]);
  yield all([
    takeLatest(actions.ADD_BUILDING_THEME_CHECK, addBuildingThemeCheck),
  ]);
  yield all([
    takeLatest(actions.UPDATE_BUILDING_THEME_CHECK, editBuildingThemeCheck),
  ]);
  yield all([
    takeLatest(actions.DELETE_BUILDING_THEME_CHECK, deleteBuildingThemeCheck),
  ]);
}
