export const actions = {
  CHANGE_PLAN: "CHANGE_PLAN",
  CHANGE_PLAN_SUCCESS: "CHANGE_PLAN_SUCCESS",
  CHANGE_PLAN_FAILURE: "CHANGE_PLAN_FAILURE",

  CANCEL_PLAN: "CANCEL_PLAN",
  CANCEL_PLAN_SUCCESS: "CANCEL_PLAN_SUCCESS",
  CANCEL_PLAN_FAILURE: "CANCEL_PLAN_FAILURE",
};

export default actions;
