const actions = {
  FETCH_FIRE_DRAWING_CUSTOMER: "FETCH_FIRE_DRAWING_CUSTOMER",
  FETCH_FIRE_DRAWING_CUSTOMER_SUCCESS: "FETCH_FIRE_DRAWING_CUSTOMER_SUCCESS",
  FETCH_FIRE_DRAWING_CUSTOMER_FAILURE: "FETCH_FIRE_DRAWING_CUSTOMER_FAILURE",

  FETCH_FIRE_DRAWING: "FETCH_FIRE_DRAWING",
  FETCH_FIRE_DRAWING_SUCCESS: "FETCH_FIRE_DRAWING_SUCCESS",
  FETCH_FIRE_DRAWING_FAILURE: "FETCH_FIRE_DRAWING_FAILURE",

  CREATE_FIRE_DRAWING: "CREATE_FIRE_DRAWING",
  CREATE_FIRE_DRAWING_SUCCESS: "CREATE_FIRE_DRAWING_SUCCESS",
  CREATE_FIRE_DRAWING_FAILURE: "CREATE_FIRE_DRAWING_FAILURE",

  UPDATE_FIRE_DRAWING: "UPDATE_FIRE_DRAWING",
  UPDATE_FIRE_DRAWING_SUCCESS: "UPDATE_FIRE_DRAWING_SUCCESS",
  UPDATE_FIRE_DRAWING_FAILURE: "UPDATE_FIRE_DRAWING_FAILURE",

};

export default actions;
