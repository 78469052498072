const actions = {
  FETCH_BASIC_DATA: "FETCH_BASIC_DATA",
  FETCH_BASIC_DATA_SUCCESS: "FETCH_BASIC_DATA_SUCCESS",
  FETCH_BASIC_DATA_FAILURE: "FETCH_BASIC_DATA_FAILURE",

  FETCH_BUILDING_INSTALLATION_FIRE_ALARM: "FETCH_BUILDING_INSTALLATION_FIRE_ALARM",
  FETCH_BUILDING_INSTALLATION_FIRE_ALARM_SUCCESS: "FETCH_BUILDING_INSTALLATION_FIRE_ALARM_SUCCESS",
  FETCH_BUILDING_INSTALLATION_FIRE_ALARM_FAILURE: "FETCH_BUILDING_INSTALLATION_FIRE_ALARM_FAILURE",

  CREATE_BUILDING_INSTALLATION_FIRE_ALARM: "CREATE_BUILDING_INSTALLATION_FIRE_ALARM",
  CREATE_BUILDING_INSTALLATION_FIRE_ALARM_SUCCESS: "CREATE_BUILDING_INSTALLATION_FIRE_ALARM_SUCCESS",
  CREATE_BUILDING_INSTALLATION_FIRE_ALARM_FAILURE: "CREATE_BUILDING_INSTALLATION_FIRE_ALARM_FAILURE",

  UPDATE_BUILDING_INSTALLATION_FIRE_ALARM: "UPDATE_BUILDING_INSTALLATION_FIRE_ALARM",
  UPDATE_BUILDING_INSTALLATION_FIRE_ALARM_SUCCESS: "UPDATE_BUILDING_INSTALLATION_FIRE_ALARM_SUCCESS",
  UPDATE_BUILDING_INSTALLATION_FIRE_ALARM_FAILURE: "UPDATE_BUILDING_INSTALLATION_FIRE_ALARM_FAILURE",  
};

export default actions;
