const actions = {
  FETCH_BUILDING_CONDITION: "FETCH_BUILDING_CONDITION",
  FETCH_BUILDING_CONDITION_SUCCESS: "FETCH_BUILDING_CONDITION_SUCCESS",
  FETCH_BUILDING_CONDITION_FAILURE: "FETCH_BUILDING_CONDITION_FAILURE",

  ADD_BUILDING_CONDITION: "ADD_BUILDING_CONDITION",
  ADD_BUILDING_CONDITION_SUCCESS: "ADD_BUILDING_CONDITION_SUCCESS",
  ADD_BUILDING_CONDITION_FAILURE: "ADD_BUILDING_CONDITION_FAILURE",

  UPDATE_BUILDING_CONDITION: "UPDATE_BUILDING_CONDITION",
  UPDATE_BUILDING_CONDITION_SUCCESS: "UPDATE_BUILDING_CONDITION_SUCCESS",
  UPDATE_BUILDING_CONDITION_FAILURE: "UPDATE_BUILDING_CONDITION_FAILURE",

  DELETE_BUILDING_CONDITION: "DELETE_BUILDING_CONDITION",
  DELETE_BUILDING_CONDITION_SUCCESS: "DELETE_BUILDING_CONDITION_SUCCESS",
  DELETE_BUILDING_CONDITION_FAILURE: "DELETE_BUILDING_CONDITION_FAILURE",

  RESET_BUILDING_CONDITION: "RESET_BUILDING_CONDITION",
};

export default actions;
