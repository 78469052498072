import actions from "./actions";

const initialState = {
  buildingVentilation: [],
  isFecthingBuildingVentilation: false,

  buildingVentilationCustomers: [],
  buildingVentilationMainTheme: [],
  buildingVentilationServicePartner: [],
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_VENTILATION:
      return {
        ...state,
        buildingVentilation: [],
        buildingVentilationCustomers: [],
        buildingVentilationMainTheme: [],
        buildingVentilationServicePartner: [],
        isFecthingBuildingVentilation: true,
      };
    case actions.FETCH_BUILDING_VENTILATION_SUCCESS:
      return {
        ...state,
        isFecthingBuildingVentilation: false,
        buildingVentilation: action.payload,
        buildingVentilationCustomers: action.payloadCustomer,
        buildingVentilationMainTheme: action.payloadMainTheme,
        buildingVentilationServicePartner: action.payloadServicePartner,
      };
    case actions.FETCH_BUILDING_VENTILATION_FAILURE:
      return { ...state, isFecthingBuildingVentilation: false };

    default:
      return state;
  }
}

export default Reducer;
