import actions from "./actions";

const initialState = {
  contactsForCreateVendor: [],
  industriesForCreateVendor: [],
  categoriesForCreateVendor: [],
  buildingsForCreateVendor: [],
  buildingGroupsForCreateVendor: [],
  customersForCreateVendor: [],

  createdContactsForVendor: [],

  createdNewContactforVendor: null,
  deletedNewContactForVendor: null,
  editedNewContactForVendor: null,

  createdNewBuildingGroup: null,
  createdNewGeneralVendor: null,
  createdNewAddressVendor: null,

  isFetchingVendor: false,
  isCreatedContactForVendor: null,

  fetchedVendors: [],
  fetchedAddress: null,
  fetchedMainTheme: [],

  isFetchingVendors: false,
  isFetchingAddress: null,

  updatedGeneralVendor: null,
  updatedAdressVendor: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_ALL_DATA_FOR_CREATE_VENDOR:
      return {
        ...state,
        isFetchingVendor: true,
        isCreatedContactForVendor: null,
        createdNewGeneralVendor: null,
        createdNewBuildingGroup: null,
      };
    case actions.FETCH_ALL_DATA_FOR_CREATE_VENDOR_SUCCESS:
      return {
        ...state,
        isFetchingVendor: false,
        contactsForCreateVendor: action.payload_contacts,
        industriesForCreateVendor: action.payload_industries,
        categoriesForCreateVendor: action.payload_categories,
        buildingsForCreateVendor: action.payload_buildings,
        buildingGroupsForCreateVendor: action.payload_building_groups,
        customersForCreateVendor: action.payload_customers,
      };
    case actions.FETCH_ALL_DATA_FOR_CREATE_VENDOR_FAILURE:
      return { ...state, isFetchingVendor: false };

    case actions.FETCH_VENDORS:
      return {
        ...state,
        isFetchingVendors: true,
        updatedGeneralVendor: null,
      };
    case actions.FETCH_VENDORS_SUCCESS:
      return {
        ...state,
        isFetchingVendors: false,
        fetchedVendors: action.payload,
        fetchedMainTheme: action.mainTheme,
      };
    case actions.FETCH_VENDORS_FAILURE:
      return { ...state, isFetchingVendors: false };

    case actions.FETCH_ADDRESS:
      return {
        ...state,
        isFetchingAddress: true,
        updatedAdressVendor: null,
      };
    case actions.FETCH_ADDRESS_SUCCESS:
      return {
        ...state,
        isFetchingAddress: false,
        fetchedAddress: action.payload,
      };
    case actions.FETCH_ADDRESS_FAILURE:
      return { ...state, isFetchingAddress: false };

    case actions.CREATE_CONTACT_FOR_VENDOR:
      return {
        ...state, isCreatedContactForVendor: null,
        createdNewContactforVendor: null,
      }

    case actions.CREATE_CONTACT_FOR_VENDOR_SUCCESS:
      return {
        ...state,
        isCreatedContactForVendor: true,
        createdNewContactforVendor: action.payload
      }
    case actions.CREATE_CONTACT_FOR_VENDOR_FAILURE:
      return {
        ...state,
        isCreatedContactForVendor: false,
        createdNewContactforVendor: null,
      }

    case actions.DELETE_CONTACT_FOR_VENDOR:
      return {
        ...state,
        deletedNewContactForVendor: null,
      }
    case actions.DELETE_CONTACT_FOR_VENDOR_SUCCESS:
      return {
        ...state,
        deletedNewContactForVendor: action.payload
      }
    case actions.DELETE_CONTACT_FOR_VENDOR_FAILURE:
      return {
        ...state,
        deletedNewContactForVendor: null
      }

    case actions.UPDATE_CONTACT_FOR_VENDOR:
      return {
        ...state,
        editedNewContactForVendor: null,
      }
    case actions.UPDATE_CONTACT_FOR_VENDOR_SUCCESS:
      return {
        ...state,
        editedNewContactForVendor: action.payload
      }
    case actions.UPDATE_CONTACT_FOR_VENDOR_FAILURE:
      return {
        ...state,
        editedNewContactForVendor: null
      }

    case actions.CREATE_VENDOR_GENERAL:
      return {
        ...state,
        createdNewGeneralVendor: null,
      }
    case actions.CREATE_VENDOR_GENERAL_SUCCESS:
      return {
        ...state,
        createdNewGeneralVendor: action.payload,
      }
    case actions.CREATE_VENDOR_GENERAL_FAILURE:
      return {
        ...state,
        createdNewGeneralVendor: false
      }

    case actions.UPDATE_VENDOR_GENERAL:
      return {
        ...state,
        updatedGeneralVendor: null,
      }
    case actions.UPDATE_VENDOR_GENERAL_SUCCESS:
      return {
        ...state,
        updatedGeneralVendor: true,
      }
    case actions.UPDATE_VENDOR_GENERAL_FAILURE:
      return {
        ...state,
        updatedGeneralVendor: false
      }

    case actions.CREATE_VENDOR_ADDRESS:
      return {
        ...state,
        createdNewAddressVendor: null,
      }
    case actions.CREATE_VENDOR_ADDRESS_SUCCESS:
      return {
        ...state,
        createdNewAddressVendor: action.payload,
      }
    case actions.CREATE_VENDOR_ADDRESS_FAILURE:
      return {
        ...state,
        createdNewAddressVendor: null
      }

    case actions.UPDATE_VENDOR_ADDRESS:
      return {
        ...state,
        updatedAdressVendor: null,
      }
    case actions.UPDATE_VENDOR_ADDRESS_SUCCESS:
      return {
        ...state,
        updatedAdressVendor: true,
      }
    case actions.UPDATE_VENDOR_ADDRESS_FAILURE:
      return {
        ...state,
        updatedAdressVendor: null
      }


    case actions.CREATE_BUILDING_GROUP_FOR_VENDOR:
      return {
        ...state,
        createdNewBuildingGroup: null,
      }
    case actions.CREATE_BUILDING_GROUP_FOR_VENDOR_SUCCESS:
      return {
        ...state,
        createdNewBuildingGroup: true,
      }
    case actions.CREATE_BUILDING_GROUP_FOR_VENDOR_FAILURE:
      return {
        ...state,
        createdNewBuildingGroup: false
      }

    case actions.INITIALIZE_STATES:
      return {
        ...state,
        deletedNewContactForVendor: null,
        editedNewContactForVendor: null,
        createdNewAddressVendor: null,
        updatedAdressVendor: null,
      }
    default:
      return state;
  }
}

export default Reducer;
