import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, putRequest } from "utils/axios-client";

function* fetchDueDate(action) {

  try {
    const response = yield callApi(() => getRequest(`setting/duedate`))
    yield put({
      type: actions.FETCH_DUE_DATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_DUE_DATE_FAILURE });
  }
}

function* editDueDate(action) {
  try {
    const response = yield callApi(() => putRequest('setting/duedate', action.payload))
    yield put({
      type: actions.EDIT_DUE_DATE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.EDIT_DUE_DATE_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_DUE_DATE, fetchDueDate)]);
  yield all([takeLatest(actions.EDIT_DUE_DATE, editDueDate)]);
}
