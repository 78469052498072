import actions from "./actions";

const initialState = {
  token: null,
  iscreatedEvent: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_TOKENS_SUCCESS:
      return {
        ...state,
        token: action.payload,
      };

    case actions.CREATE_EVENT:
      return {
        ...state,
        iscreatedEvent: null
      };

    case actions.CREATE_EVENT_SUCCESS:
      return {
        ...state,
        iscreatedEvent: true
      };

    case actions.CREATE_EVENT_FAILURE:
      return {
        ...state,
        iscreatedEvent: false
      };
      
    default:
      return state;
  }
}

export default Reducer;
