import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { notifications } from "./mock";
import { axiosClient, mockAdapter } from "utils/mock";
import { callApi } from "states/saga";
import { getRequest } from "utils/axios-client";

function* fetchDocuments(action) {
  try {
    let response = [];
    if (action.payload === true)
      response = yield callApi(() => getRequest('building?limit=8'))
    else
      response = yield callApi(() => getRequest(`user/buildings`))

    yield put({
      type: actions.FETCH_DOCUMENTS_SUCCESS,
      payload: action.payload === true ? response.data : response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_DOCUMENTS_FAILURE });
  }
}

function* fetchTasks() {
  try {
    const responseOpen = yield callApi(() => getRequest('workflow/task?limit=3&status=Open'))
    const responseOngoing = yield callApi(() => getRequest('workflow/task?limit=3&status=Ongoing'))
    const responseDue = yield callApi(() => getRequest('workflow/task?limit=3&status=Due'))
    const responseResolved = yield callApi(() => getRequest('workflow/task?limit=3&status=Resolved'))
    yield put({
      type: actions.FETCH_TASKS_SUCCESS,
      payload_open: responseOpen.data,
      payload_ongoing: responseOngoing.data,
      payload_due: responseDue.data,
      payload_resolved: responseResolved.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_TASKS_FAILURE });
  }
}

function* fetchNotifications() {
  mockAdapter.onGet("/notifications").reply(200, notifications);

  try {
    const response = yield axiosClient.get("/notifications");
    yield put({
      type: actions.FETCH_NOTIFICATIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_NOTIFICATIONS_FAILURE });
  }
}

function* fetchNews() {
  try {
    const response = yield callApi(() => getRequest('cms/news'));
    yield put({
      type: actions.FETCH_NEWS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_NEWS_FAILURE });
  }
}

function* fetchDeviations() {
  try {
    const response = yield callApi(() => getRequest('workflow/deviation?limit=3&status=Closed'))
    yield put({
      type: actions.FETCH_DEVIATIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_DEVIATIONS_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_DOCUMENTS, fetchDocuments)]);
  yield all([takeLatest(actions.FETCH_TASKS, fetchTasks)]);
  yield all([takeLatest(actions.FETCH_NOTIFICATIONS, fetchNotifications)]);
  yield all([takeLatest(actions.FETCH_NEWS, fetchNews)]);
  yield all([takeLatest(actions.FETCH_DEVIATIONS, fetchDeviations)]);
}
