const actions = {
  FETCH_BASIC_DATA_EQUIPMENT: "FETCH_BASIC_DATA_EQUIPMENT",
  FETCH_BASIC_DATA_EQUIPMENT_SUCCESS: "FETCH_BASIC_DATA_EQUIPMENT_SUCCESS",
  FETCH_BASIC_DATA_EQUIPMENT_FAILURE: "FETCH_BASIC_DATA_EQUIPMENT_FAILURE",

  FETCH_BUILDING_INSTALLATION_EQUIPMENT: "FETCH_BUILDING_INSTALLATION_EQUIPMENT",
  FETCH_BUILDING_INSTALLATION_EQUIPMENT_SUCCESS: "FETCH_BUILDING_INSTALLATION_EQUIPMENT_SUCCESS",
  FETCH_BUILDING_INSTALLATION_EQUIPMENT_FAILURE: "FETCH_BUILDING_INSTALLATION_EQUIPMENT_FAILURE",

  CREATE_BUILDING_INSTALLATION_EQUIPMENT: "CREATE_BUILDING_INSTALLATION_EQUIPMENT",
  CREATE_BUILDING_INSTALLATION_EQUIPMENT_SUCCESS: "CREATE_BUILDING_INSTALLATION_EQUIPMENT_SUCCESS",
  CREATE_BUILDING_INSTALLATION_EQUIPMENT_FAILURE: "CREATE_BUILDING_INSTALLATION_EQUIPMENT_FAILURE",

  UPDATE_BUILDING_INSTALLATION_EQUIPMENT: "UPDATE_BUILDING_INSTALLATION_EQUIPMENT",
  UPDATE_BUILDING_INSTALLATION_EQUIPMENT_SUCCESS: "UPDATE_BUILDING_INSTALLATION_EQUIPMENT_SUCCESS",
  UPDATE_BUILDING_INSTALLATION_EQUIPMENT_FAILURE: "UPDATE_BUILDING_INSTALLATION_EQUIPMENT_FAILURE",  
};

export default actions;
