import actions from "./actions";

const initialState = {
  taskComment: [],
  isFetchingTaskComment: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_TASK_COMMENTS:
      return {
        ...state,
        isFetchingTaskComment: true,
      };
    case actions.FETCH_TASK_COMMENTS_SUCCESS:
      return {
        ...state,
        isFetchingTaskComment: false,
        taskComment: action.payload,
      };
    case actions.FETCH_TASK_COMMENTS_FAILURE:
      return { ...state, isFetchingTaskComment: false };

    case actions.EDIT_TASK_COMMENT:
      return {
        ...state,
      };
    case actions.EDIT_TASK_COMMENT_SUCCESS:
      return {
        ...state,
      };
    case actions.EDIT_TASK_COMMENT_FAILURE:
      return { ...state };

    default:
      return state;
  }
}

export default Reducer;
