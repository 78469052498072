const actions = {
  FETCH_USER_NOTIFICATION: "FETCH_USER_NOTIFICATION",
  FETCH_USER_NOTIFICATION_SUCCESS: "FETCH_USER_NOTIFICATION_SUCCESS",
  FETCH_USER_NOTIFICATION_FAILURE: "FETCH_USER_NOTIFICATION_FAILURE",

  CREATE_USER_NOTIFICATION: "CREATE_USER_NOTIFICATION",
  CREATE_USER_NOTIFICATION_SUCCESS: "CREATE_USER_NOTIFICATION_SUCCESS",
  CREATE_USER_NOTIFICATION_FAILURE: "CREATE_USER_NOTIFICATION_FAILURE",

  UPDATE_USER_NOTIFICATION: "UPDATE_USER_NOTIFICATION",
  UPDATE_USER_NOTIFICATION_SUCCESS: "UPDATE_USER_NOTIFICATION_SUCCESS",
  UPDATE_USER_NOTIFICATION_FAILURE: "UPDATE_USER_NOTIFICATION_FAILURE",

  DELETE_USER_NOTIFICATION: "DELETE_USER_NOTIFICATION",
  DELETE_USER_NOTIFICATION_SUCCESS: "DELETE_USER_NOTIFICATION_SUCCESS",
  DELETE_USER_NOTIFICATION_FAILURE: "DELETE_USER_NOTIFICATION_FAILURE",
};

export default actions;
