import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { deleteRequest, getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchMainTheme(action) {
  try {
    const response = yield callApi(() => getRequest('cms/main-theme'))
    yield put({
      type: actions.FETCH_MAIN_THEME_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_MAIN_THEME_FAILURE });
  }
}

function* createMainTheme(action) {
  try {
    const response = yield callApi(() => postRequest('cms/main-theme', action.payload))
    yield put({
      type: actions.CREATE_MAIN_THEME_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_MAIN_THEME_FAILURE });
  }
}

function* updateMainTheme(action) {
  try {
    const response = yield callApi(() => putRequest('cms/main-theme', action.payload))
    yield put({
      type: actions.UPDATE_MAIN_THEME_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_MAIN_THEME_FAILURE });
  }
}

function* deleteMainTheme(action) {
  try {
    const response = yield callApi(() => deleteRequest(`cms/main-theme?id=${action.payload}`))
    yield put({
      type: actions.DELETE_MAIN_THEME_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_MAIN_THEME_FAILURE });
  }
}

function* fetchSubTheme(action) {
  try {
    const response = yield callApi(() => getRequest('cms/sub-theme'))
    yield put({
      type: actions.FETCH_SUB_THEME_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_SUB_THEME_FAILURE });
  }
}

function* createSubTheme(action) {
  try {
    const response = yield callApi(() => postRequest('cms/sub-theme', action.payload))
    yield put({
      type: actions.CREATE_SUB_THEME_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_SUB_THEME_FAILURE });
  }
}

function* updateSubTheme(action) {
  try {
    const response = yield callApi(() => putRequest(`cms/sub-theme?id=${action.id}`, action.payload))
    yield put({
      type: actions.UPDATE_SUB_THEME_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
  }
}

function* deleteSubTheme(action) {
  try {
    const response = yield callApi(() => deleteRequest(`cms/sub-theme?id=${action.payload}`))
    yield put({
      type: actions.DELETE_SUB_THEME_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_SUB_THEME_FAILURE });
  }
}


export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_MAIN_THEME, fetchMainTheme)]);
  yield all([takeLatest(actions.CREATE_MAIN_THEME, createMainTheme)]);
  yield all([takeLatest(actions.UPDATE_MAIN_THEME, updateMainTheme)]);
  yield all([takeLatest(actions.DELETE_MAIN_THEME, deleteMainTheme)]);
  yield all([takeLatest(actions.CREATE_SUB_THEME, createSubTheme)]);
  yield all([takeLatest(actions.FETCH_SUB_THEME, fetchSubTheme)]);
  yield all([takeLatest(actions.UPDATE_SUB_THEME, updateSubTheme)]);
  yield all([takeLatest(actions.DELETE_SUB_THEME, deleteSubTheme)]);
}
