import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* getBuildingThemeDeviation(action) {
  try {
    const response = yield callApi(() =>
      getRequest(
        `building/theme-deviations?theme=${action.theme}&themeRecordId=${action.themeRecordId}`
      )
    );
    yield put({
      type: actions.FETCH_BUILDING_THEME_DEVIATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_THEME_DEVIATION_FAILURE });
  }
}

function* addBuildingThemeDeviation(action) {
  try {
    yield callApi(() =>
      postRequest("building/theme-deviations", action.payload)
    );
    yield put({
      type: actions.ADD_BUILDING_THEME_DEVIATION_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_BUILDING_THEME_DEVIATION_FAILURE });
  }
}

function* editBuildingThemeDeviation(action) {
  try {
    yield callApi(() =>
      putRequest(`building/theme-deviations?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_BUILDING_THEME_DEVIATION_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_THEME_DEVIATION_FAILURE });
  }
}

function* deleteBuildingThemeDeviation(action) {
  try {
    yield callApi(() =>
      deleteRequest(`building/theme-deviations?id=${action.id}`)
    );
    yield put({
      type: actions.DELETE_BUILDING_THEME_DEVIATION_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_BUILDING_THEME_DEVIATION_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      actions.FETCH_BUILDING_THEME_DEVIATION,
      getBuildingThemeDeviation
    ),
  ]);
  yield all([
    takeLatest(actions.ADD_BUILDING_THEME_DEVIATION, addBuildingThemeDeviation),
  ]);
  yield all([
    takeLatest(
      actions.UPDATE_BUILDING_THEME_DEVIATION,
      editBuildingThemeDeviation
    ),
  ]);
  yield all([
    takeLatest(
      actions.DELETE_BUILDING_THEME_DEVIATION,
      deleteBuildingThemeDeviation
    ),
  ]);
}
