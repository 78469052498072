const actions = {
  FETCH_BUILDING_THEME_DEVIATION: "FETCH_BUILDING_THEME_DEVIATION",
  FETCH_BUILDING_THEME_DEVIATION_SUCCESS:
    "FETCH_BUILDING_THEME_DEVIATION_SUCCESS",
  FETCH_BUILDING_THEME_DEVIATION_FAILURE:
    "FETCH_BUILDING_THEME_DEVIATION_FAILURE",

  ADD_BUILDING_THEME_DEVIATION: "ADD_BUILDING_THEME_DEVIATION",
  ADD_BUILDING_THEME_DEVIATION_SUCCESS: "ADD_BUILDING_THEME_DEVIATION_SUCCESS",
  ADD_BUILDING_THEME_DEVIATION_FAILURE: "ADD_BUILDING_THEME_DEVIATION_FAILURE",

  UPDATE_BUILDING_THEME_DEVIATION: "UPDATE_BUILDING_THEME_DEVIATION",
  UPDATE_BUILDING_THEME_DEVIATION_SUCCESS:
    "UPDATE_BUILDING_THEME_DEVIATION_SUCCESS",
  UPDATE_BUILDING_THEME_DEVIATION_FAILURE:
    "UPDATE_BUILDING_THEME_DEVIATION_FAILURE",

  DELETE_BUILDING_THEME_DEVIATION: "DELETE_BUILDING_THEME_DEVIATION",
  DELETE_BUILDING_THEME_DEVIATION_SUCCESS:
    "DELETE_BUILDING_THEME_DEVIATION_SUCCESS",
  DELETE_BUILDING_THEME_DEVIATION_FAILURE:
    "DELETE_BUILDING_THEME_DEVIATION_FAILURE",

  RESET_BUILDING_THEME_DEVIATION: "RESET_BUILDING_THEME_DEVIATION",
};

export default actions;
