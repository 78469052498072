const actions = {
  FETCH_BUILDING_CHECKLIST: "FETCH_BUILDING_CHECKLIST",
  FETCH_BUILDING_CHECKLIST_SUCCESS: "FETCH_BUILDING_CHECKLIST_SUCCESS",
  FETCH_BUILDING_CHECKLIST_FAILURE: "FETCH_BUILDING_CHECKLIST_FAILURE",

  ADD_BUILDING_CHECKLIST: "ADD_BUILDING_CHECKLIST",
  ADD_BUILDING_CHECKLIST_SUCCESS: "ADD_BUILDING_CHECKLIST_SUCCESS",
  ADD_BUILDING_CHECKLIST_FAILURE: "ADD_BUILDING_CHECKLIST_FAILURE",

  UPDATE_BUILDING_CHECKLIST: "UPDATE_BUILDING_CHECKLIST",
  UPDATE_BUILDING_CHECKLIST_SUCCESS: "UPDATE_BUILDING_CHECKLIST_SUCCESS",
  UPDATE_BUILDING_CHECKLIST_FAILURE: "UPDATE_BUILDING_CHECKLIST_FAILURE",

  DELETE_BUILDING_CHECKLIST: "DELETE_BUILDING_CHECKLIST",
  DELETE_BUILDING_CHECKLIST_SUCCESS: "DELETE_BUILDING_CHECKLIST_SUCCESS",
  DELETE_BUILDING_CHECKLIST_FAILURE: "DELETE_BUILDING_CHECKLIST_FAILURE",

  RESET_BUILDING_CHECKLIST: "RESET_BUILDING_CHECKLIST",
};

export default actions;
