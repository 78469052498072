import actions from "./actions";

const initialState = {

};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.CHANGE_PLAN:
      return {
        ...state
      };
    case actions.CHANGE_PLAN_SUCCESS:
      return {
        ...state
      };
    case actions.CHANGE_PLAN_FAILURE:
      return {
        ...state
      }

    case actions.CANCEL_PLAN:
      return {
        ...state
      };
    case actions.CANCEL_PLAN_SUCCESS:
      return {
        ...state
      };
    case actions.CANCEL_PLAN_FAILURE:
      return {
        ...state
      }

    default:
      return state;
  }
}

export default Reducer;
