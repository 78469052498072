import actions from "./actions";

const initialState = {
  isReseted: false,
  user: null,
  isReseting: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.REQUESTRESET:
      return {
        ...state,
        isReseted: true,
      };
    case actions.REQUESTRESET_SUCCESS:
      return {
        ...state,
        isReseted: true,
        isReseting: false,
        user: action.payload,
      };
    case actions.REQUESTRESET_FAILURE:
      return { ...state, isReseted: false, isReseting: false };

    default:
      return state;
  }
}

export default Reducer;
