const actions = {
  FETCH_CMS_EXERCISE_CATEGORY: "FETCH_CMS_EXERCISE_CATEGORY",
  FETCH_CMS_EXERCISE_CATEGORY_SUCCESS: "FETCH_CMS_EXERCISE_CATEGORY_SUCCESS",
  FETCH_CMS_EXERCISE_CATEGORY_FAILURE: "FETCH_CMS_EXERCISE_CATEGORY_FAILURE",

  ADD_CMS_EXERCISE_CATEGORY: "ADD_CMS_EXERCISE_CATEGORY",
  ADD_CMS_EXERCISE_CATEGORY_SUCCESS: "ADD_CMS_EXERCISE_CATEGORY_SUCCESS",
  ADD_CMS_EXERCISE_CATEGORY_FAILURE: "ADD_CMS_EXERCISE_CATEGORY_FAILURE",

  UPDATE_CMS_EXERCISE_CATEGORY: "UPDATE_CMS_EXERCISE_CATEGORY",
  UPDATE_CMS_EXERCISE_CATEGORY_SUCCESS: "UPDATE_CMS_EXERCISE_CATEGORY_SUCCESS",
  UPDATE_CMS_EXERCISE_CATEGORY_FAILURE: "UPDATE_CMS_EXERCISE_CATEGORY_FAILURE",

  DELETE_CMS_EXERCISE_CATEGORY: "DELETE_CMS_EXERCISE_CATEGORY",
  DELETE_CMS_EXERCISE_CATEGORY_SUCCESS: "DELETE_CMS_EXERCISE_CATEGORY_SUCCESS",
  DELETE_CMS_EXERCISE_CATEGORY_FAILURE: "DELETE_CMS_EXERCISE_CATEGORY_FAILURE",

  INIT_STATE: "INIT_STATE",
};

export default actions;
