import actions from "./actions";

const initialState = {
  buildingCondition: [],
  isFetchingBuildingCondition: false,

  isChangedAddBuildingCondition: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_CONDITION:
      return {
        ...state,
        buildingCondition: [],
        isFetchingBuildingCondition: true,
      };
    case actions.FETCH_BUILDING_CONDITION_SUCCESS:
      return {
        ...state,
        isFetchingBuildingCondition: false,
        buildingCondition: action.payload,
      };
    case actions.FETCH_BUILDING_CONDITION_FAILURE:
      return {
        ...state,
        isFetchingBuildingCondition: false,
        buildingCondition: [],
      };

    case actions.ADD_BUILDING_CONDITION:
      return { ...state, isChangedAddBuildingCondition: null };
    case actions.ADD_BUILDING_CONDITION_SUCCESS:
      return { ...state, isChangedAddBuildingCondition: true };
    case actions.ADD_BUILDING_CONDITION_FAILURE:
      return { ...state, isChangedAddBuildingCondition: false };

    case actions.UPDATE_BUILDING_CONDITION:
      return { ...state, isChangedAddBuildingCondition: null };
    case actions.UPDATE_BUILDING_CONDITION_SUCCESS:
      return { ...state, isChangedAddBuildingCondition: true };
    case actions.UPDATE_BUILDING_CONDITION_FAILURE:
      return { ...state, isChangedAddBuildingCondition: false };

    case actions.DELETE_BUILDING_CONDITION:
      return { ...state, isChangedAddBuildingCondition: null };
    case actions.DELETE_BUILDING_CONDITION_SUCCESS:
      return { ...state, isChangedAddBuildingCondition: true };
    case actions.DELETE_BUILDING_CONDITION_FAILURE:
      return { ...state, isChangedAddBuildingCondition: false };

    case actions.RESET_BUILDING_CONDITION:
      return { ...state, isChangedAddBuildingCondition: null };

    default:
      return state;
  }
}

export default Reducer;
