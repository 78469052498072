import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchDeviations(action) {
  try {
    const responseTasks = yield callApi(() => getRequest("workflow/deviation"));
    const responseBuildings = yield callApi(() => getRequest("building"));
    const responseCustomers = yield callApi(() => getRequest("crm/customer"));
    yield put({
      type: actions.FETCH_DEVIATION_DEVIATIONS_SUCCESS,
      payload: responseTasks.data,
      payload_buildings: responseBuildings.data,
      payload_customers: responseCustomers.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_DEVIATION_DEVIATIONS_FAILURE });
  }
}

function* fetchDeviationSatistics(action) {
  try {
    const response = yield callApi(() =>
      getRequest(
        `workflow/deviation/statistics?startedAt=${action.startDate}&endedAt=${action.endDate}&type=${action.fetchType}`,
        action.payload
      )
    );
    yield put({
      type: actions.FETCH_DEVIATION_SATISTICS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_DEVIATION_SATISTICS_FAILURE });
  }
}

function* fetchDeviationSatisticsByDuedate(action) {
  try {
    const response = yield callApi(() =>
      getRequest(
        `workflow/deviation/statistics?startedAt=${action.startDate}&endedAt=${action.endDate}&type=duedate`,
        action.payload
      )
    );
    yield put({
      type: actions.FETCH_DEVIATION_SATISTICS_BY_DUEDATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_DEVIATION_SATISTICS_BY_DUEDATE_FAILURE });
  }
}

function* addDeviation(action) {
  try {
    const response = yield callApi(() =>
      postRequest(`workflow/deviation`, action.payload)
    );
    yield put({
      type: actions.ADD_DEVIATION_DEVIATIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.ADD_DEVIATION_DEVIATIONS_FAILURE });
  }
}

function* updateDeviation(action) {
  try {
    const response = yield callApi(() =>
      putRequest(`workflow/deviation?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_DEVIATION_DEVIATIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_DEVIATION_DEVIATIONS_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_DEVIATION_DEVIATIONS, fetchDeviations)]);
  yield all([
    takeLatest(actions.FETCH_DEVIATION_SATISTICS, fetchDeviationSatistics),
  ]);
  yield all([
    takeLatest(
      actions.FETCH_DEVIATION_SATISTICS_BY_DUEDATE,
      fetchDeviationSatisticsByDuedate
    ),
  ]);
  yield all([takeLatest(actions.ADD_DEVIATION_DEVIATIONS, addDeviation)]);
  yield all([takeLatest(actions.UPDATE_DEVIATION_DEVIATIONS, updateDeviation)]);
}
