const actions = {
  FETCH_EXERCISE: "FETCH_EXERCISE",
  FETCH_EXERCISE_SUCCESS: "FETCH_EXERCISE_SUCCESS",
  FETCH_EXERCISE_FAILURE: "FETCH_EXERCISE_FAILURE",

  CREATE_EXERCISE: "CREATE_EXERCISE",
  CREATE_EXERCISE_SUCCESS: "CREATE_EXERCISE_SUCCESS",
  CREATE_EXERCISE_FAILURE: "CREATE_EXERCISE_FAILURE",

  UPDATE_EXERCISE: "UPDATE_EXERCISE",
  UPDATE_EXERCISE_SUCCESS: "UPDATE_EXERCISE_SUCCESS",
  UPDATE_EXERCISE_FAILURE: "UPDATE_EXERCISE_FAILURE",

  DELETE_EXERCISE: "DELETE_EXERCISE",
  DELETE_EXERCISE_SUCCESS: "DELETE_EXERCISE_SUCCESS",
  DELETE_EXERCISE_FAILURE: "DELETE_EXERCISE_FAILURE",
};

export default actions;
