import actions from "./actions";

const initialState = {
  customers: [],
  customer_plans: [],
  customer_roles: [],
  isFetchingCustomers: false,
  isFetchedCustomers: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_CUSTOMERS:
      return {
        ...state,
        isFetchedCustomers: false,
        isFetchingCustomers: true,
      };
    case actions.FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isFetchingCustomers: false,
        isFetchedCustomers: true,
        customers: action.payload,
        customer_plans: action.payload_plan,
        customer_roles: action.payload_role,
      };
    case actions.FETCH_CUSTOMERS_FAILURE:
      return {
        ...state,
        isFetchingCustomers: false,
        isFetchedCustomers: false
      };

    case actions.FETCH_CUSTOMERS_ONLY_SUCCESS:
      return {
        ...state,
        customers: action.payload
      }

    case actions.UPDATE_CUSTOMERS:
      return { ...state, isFetchingCustomers: true };
    case actions.UPDATE_CUSTOMERS_SUCCESS:
      return { ...state, isFetchingCustomers: false };
    case actions.UPDATE_CUSTOMERS_FAILURE:
      return { ...state, isFetchingCustomers: false };
    default:
      return state;
  }
}

export default Reducer;
