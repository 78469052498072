import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* fetchFireConcepts(action) {
  try {
    console.log("building_id", action.payload);

    const responseCustomer = yield callApi(() => getRequest("crm/customer"));
    const response = yield callApi(() =>
      getRequest(`building/fire-concept?buildingId=${Number(action.payload)}`)
    );
    yield put({
      type: actions.FETCH_BUILDING_FIRE_CONCEPTS_SUCCESS,
      payload: response.data,
      payloadCustomer: responseCustomer.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_FIRE_CONCEPTS_FAILURE });
  }
}

// function* fetchFireAllConcepts(action) {
//   try {
//     const response = yield callApi(() => getRequest(`building/fire-concept?buildingId=${action.payload}`))
//     yield put({
//       type: actions.FETCH_BUILDING_FIRE_HISTORIES_SUCCESS,
//       payload: response.data,
//     });
//   } catch (error) {
//     yield put({ type: actions.FETCH_BUILDING_FIRE_HISTORIES_FAILURE });
//   }
// }

function* addFireConcepts(action) {
  try {
    const response = yield callApi(() =>
      postRequest(
        `building/fire-concept?buildingId=${action.buildingId}`,
        action.payload
      )
    );
    yield put({
      type: actions.CREATE_BUILDING_FIRE_CONCEPTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_BUILDING_FIRE_CONCEPTS_FAILURE });
  }
}

function* updateFireConcepts(action) {
  try {
    const response = yield callApi(() =>
      putRequest("building/fire-concept", action.payload)
    );
    yield put({
      type: actions.UPDATE_BUILDING_FIRE_CONCEPTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_FIRE_CONCEPTS_FAILURE });
  }
}

function* updateFireConceptsFiles(action) {
  try {
    const response = yield callApi(() =>
      putRequest("edit_building_conceptFire_files", action.payload)
    );
    yield put({
      type: actions.UPDATE_BUILDING_FIRE_CONCEPTS_FILES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_FIRE_CONCEPTS_FILES_FAILURE });
  }
}

function* updateFireConceptsFilesConfirm(action) {
  try {
    const response = yield callApi(() =>
      putRequest(`building/fire-concept?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_BUILDING_FIRE_CONCEPTS_CONFIRM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_FIRE_CONCEPTS_CONFIRM_FAILURE });
  }
}

function* deleteFireConcepts(action) {
  try {
    const response = yield callApi(() =>
      deleteRequest(`building/fire-concept?id=${action.id}`)
    );
    yield put({
      type: actions.DELETE_BUILDING_FIRE_CONCEPTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_BUILDING_FIRE_CONCEPTS_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.FETCH_BUILDING_FIRE_CONCEPTS, fetchFireConcepts),
  ]);
  // yield all([takeLatest(actions.FETCH_BUILDING_FIRE_HISTORIES, fetchFireAllConcepts)]);
  yield all([
    takeLatest(actions.CREATE_BUILDING_FIRE_CONCEPTS, addFireConcepts),
  ]);
  yield all([
    takeLatest(actions.UPDATE_BUILDING_FIRE_CONCEPTS, updateFireConcepts),
  ]);
  yield all([
    takeLatest(
      actions.UPDATE_BUILDING_FIRE_CONCEPTS_FILES,
      updateFireConceptsFiles
    ),
  ]);
  yield all([
    takeLatest(
      actions.UPDATE_BUILDING_FIRE_CONCEPTS_CONFIRM,
      updateFireConceptsFilesConfirm
    ),
  ]);
  yield all([
    takeLatest(actions.DELETE_BUILDING_FIRE_CONCEPTS, deleteFireConcepts),
  ]);
}
