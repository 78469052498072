import actions from "./actions";

const initialState = {
  buildingGroup: [],
  isFecthingBuildingGroup: false,

  isChangedBuildingGroup: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_GROUP:
      return {
        ...state,
        isChangedBuildingGroup: null,
        isFecthingBuildingGroup: true,
      };
    case actions.FETCH_BUILDING_GROUP_SUCCESS:
      return {
        ...state,
        isFecthingBuildingGroup: false,
        buildingGroup: action.payload,
      };
    case actions.FETCH_BUILDING_GROUP_FAILURE:
      return { ...state, isFecthingBuildingGroup: false };

    case actions.CREATE_BUILDING_GROUP:
      return {
        ...state,
        isChangedBuildingGroup: null,
      };
    case actions.CREATE_BUILDING_GROUP_SUCCESS:
      return {
        ...state,
        isChangedBuildingGroup: true,
      };
    case actions.CREATE_BUILDING_GROUP_FAILURE:
      return { ...state, isChangedBuildingGroup: false };

    case actions.UPDATE_BUILDING_GROUP:
      return {
        ...state,
        isChangedBuildingGroup: null,
      };
    case actions.UPDATE_BUILDING_GROUP_SUCCESS:
      return {
        ...state,
        isChangedBuildingGroup: true,
      };
    case actions.UPDATE_BUILDING_GROUP_FAILURE:
      return { ...state, isChangedBuildingGroup: false };

    case actions.DELETE_BUILDING_GROUP:
      return {
        ...state,
        isChangedBuildingGroup: null,
      };
    case actions.DELETE_BUILDING_GROUP_SUCCESS:
      return {
        ...state,
        isChangedBuildingGroup: true,
      };
    case actions.DELETE_BUILDING_GROUP_FAILURE:
      return { ...state, isChangedBuildingGroup: false };

    default:
      return state;
  }
}

export default Reducer;
