import actions from "./actions";

const initialState = {
  buildingTenants: [],
  isFecthingBuildingTenants: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_TENANTS:
      return {
        ...state,
        buildingTenants: [],
        isFecthingBuildingTenants: true,
      };
    case actions.FETCH_BUILDING_TENANTS_SUCCESS:
      return {
        ...state,
        isFecthingBuildingTenants: false,
        buildingTenants: action.payload,
      };
    case actions.FETCH_BUILDING_TENANTS_FAILURE:
      return { ...state, isFecthingBuildingTenants: false };

    default:
      return state;
  }
}

export default Reducer;
