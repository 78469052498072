const actions = {
  FETCH_CENTRAL: "FETCH_CENTRAL",
  FETCH_CENTRAL_SUCCESS: "FETCH_CENTRAL_SUCCESS",
  FETCH_CENTRAL_FAILURE: "FETCH_CENTRAL_FAILURE",

  ADD_CENTRAL: "ADD_CENTRAL",
  ADD_CENTRAL_SUCCESS: "ADD_CENTRAL_SUCCESS",
  ADD_CENTRAL_FAILURE: "ADD_CENTRAL_FAILURE",

  UPDATE_CENTRAL: "UPDATE_CENTRAL",
  UPDATE_CENTRAL_SUCCESS: "UPDATE_CENTRAL_SUCCESS",
  UPDATE_CENTRAL_FAILURE: "UPDATE_CENTRAL_FAILURE",

  DELETE_CENTRAL: "DELETE_CENTRAL",
  DELETE_CENTRAL_SUCCESS: "DELETE_CENTRAL_SUCCESS",
  DELETE_CENTRAL_FAILURE: "DELETE_CENTRAL_FAILURE",
};

export default actions;
