import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  getRequestNoToken,
  postRequest,
  postRequestNoToken,
} from "utils/axios-client";

function* createTenantInvite(action) {
  try {
    const response = yield callApi(() =>
      postRequest("user/invitation", action.payload)
    );
    yield put({
      type: actions.CREATE_TENANT_INVITE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_TENANT_INVITE_FAILURE });
  }
}

function* createTenantUserByInvition(action) {
  try {
    const response = yield callApi(() =>
      postRequestNoToken("user/invitation/register", action.payload)
    );
    yield put({
      type: actions.CREATE_TENANT_USER_BY_INVITATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_TENANT_USER_BY_INVITATION_FAILURE });
  }
}

function* fetchInvitedInfo(action) {
  try {
    const response = yield callApi(() =>
      getRequestNoToken(`user/invitation?token=${action.payload}`)
    );
    yield put({
      type: actions.FETCH_INVITED_INFO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_INVITED_INFO_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.CREATE_TENANT_INVITE, createTenantInvite)]);
  yield all([
    takeLatest(
      actions.CREATE_TENANT_USER_BY_INVITATION,
      createTenantUserByInvition
    ),
  ]);
  yield all([takeLatest(actions.FETCH_INVITED_INFO, fetchInvitedInfo)]);
}
