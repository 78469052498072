import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* getCmsExerciseCategory() {
  try {
    const response = yield callApi(() => getRequest("cms/exercise-category"));
    yield put({
      type: actions.FETCH_CMS_EXERCISE_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_CMS_EXERCISE_CATEGORY_FAILURE });
  }
}

function* addCmsExerciseCategory(action) {
  try {
    yield callApi(() => postRequest("cms/exercise-category", action.payload));
    yield put({
      type: actions.ADD_CMS_EXERCISE_CATEGORY_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_CMS_EXERCISE_CATEGORY_FAILURE });
  }
}

function* editCmsExerciseCategory(action) {
  try {
    yield callApi(() =>
      putRequest(`cms/exercise-category?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_CMS_EXERCISE_CATEGORY_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_CMS_EXERCISE_CATEGORY_FAILURE });
  }
}

function* deleteCmsExerciseCategory(action) {
  try {
    yield callApi(() => deleteRequest(`cms/exercise-category?id=${action.id}`));
    yield put({
      type: actions.DELETE_CMS_EXERCISE_CATEGORY_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_CMS_EXERCISE_CATEGORY_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.FETCH_CMS_EXERCISE_CATEGORY, getCmsExerciseCategory),
  ]);
  yield all([
    takeLatest(actions.ADD_CMS_EXERCISE_CATEGORY, addCmsExerciseCategory),
  ]);
  yield all([
    takeLatest(actions.UPDATE_CMS_EXERCISE_CATEGORY, editCmsExerciseCategory),
  ]);
  yield all([
    takeLatest(actions.DELETE_CMS_EXERCISE_CATEGORY, deleteCmsExerciseCategory),
  ]);
}
