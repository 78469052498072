const actions = {
  FETCH_BUILDING_SUPERVISORY_REPORT: "FETCH_BUILDING_SUPERVISORY_REPORT",
  FETCH_BUILDING_SUPERVISORY_REPORT_SUCCESS: "FETCH_BUILDING_SUPERVISORY_REPORT_SUCCESS",
  FETCH_BUILDING_SUPERVISORY_REPORT_FAILURE: "FETCH_BUILDING_SUPERVISORY_REPORT_FAILURE",

  FETCH_BUILDING_FIRE_HISTORIES: "FETCH_BUILDING_FIRE_HISTORIES",
  FETCH_BUILDING_FIRE_HISTORIES_SUCCESS: "FETCH_BUILDING_FIRE_HISTORIES_SUCCESS",
  FETCH_BUILDING_FIRE_HISTORIES_FAILURE: "FETCH_BUILDING_FIRE_HISTORIES_FAILURE",

  CREATE_BUILDING_SUPERVISORY_REPORT: "CREATE_BUILDING_SUPERVISORY_REPORT",
  CREATE_BUILDING_SUPERVISORY_REPORT_SUCCESS: "CREATE_BUILDING_SUPERVISORY_REPORT_SUCCESS",
  CREATE_BUILDING_SUPERVISORY_REPORT_FAILURE: "CREATE_BUILDING_SUPERVISORY_REPORT_FAILURE",

  UPDATE_BUILDING_SUPERVISORY_REPORT: "UPDATE_BUILDING_SUPERVISORY_REPORT",
  UPDATE_BUILDING_SUPERVISORY_REPORT_SUCCESS: "UPDATE_BUILDING_SUPERVISORY_REPORT_SUCCESS",
  UPDATE_BUILDING_SUPERVISORY_REPORT_FAILURE: "UPDATE_BUILDING_SUPERVISORY_REPORT_FAILURE",

  UPDATE_BUILDING_SUPERVISORY_REPORT_FILES: "UPDATE_BUILDING_SUPERVISORY_REPORT_FILES",
  UPDATE_BUILDING_SUPERVISORY_REPORT_FILES_SUCCESS: "UPDATE_BUILDING_SUPERVISORY_REPORT_FILES_SUCCESS",
  UPDATE_BUILDING_SUPERVISORY_REPORT_FILES_FAILURE: "UPDATE_BUILDING_SUPERVISORY_REPORT_FILES_FAILURE",

  UPDATE_BUILDING_SUPERVISORY_REPORT_CONFIRM: "UPDATE_BUILDING_SUPERVISORY_REPORT_CONFIRM",
  UPDATE_BUILDING_SUPERVISORY_REPORT_CONFIRM_SUCCESS: "UPDATE_BUILDING_SUPERVISORY_REPORT_CONFIRM_SUCCESS",
  UPDATE_BUILDING_SUPERVISORY_REPORT_CONFIRM_FAILURE: "UPDATE_BUILDING_SUPERVISORY_REPORT_CONFIRM_FAILURE",
};

export default actions;
