import actions from "./actions";

const initialState = {
  tickets: [],
  isFetchingTickets: false,

  isSuccessCreateTicket: null,
  isSuccessDeleteTicket: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_TICKETS:
      return {
        ...state,
        isSuccessDeleteTicket: null,
        isFetchingTickets: true,
      };
    case actions.FETCH_TICKETS_SUCCESS:
      return {
        ...state,
        isFetchingTickets: false,
        tickets: action.payload,
      };
    case actions.FETCH_TICKETS_FAILURE:
      return { ...state, isFetchingTickets: false, tickets: [] };

    case actions.CREATE_TICKETS:
      return {
        ...state,
        isSuccessCreateTicket: null,
      };
    case actions.CREATE_TICKETS_SUCCESS:
      return {
        ...state,
        isSuccessCreateTicket: true,
      };
    case actions.CREATE_TICKETS_FAILURE:
      return { ...state, isSuccessCreateTicket: false };

    case actions.DELETE_TICKETS:
      return {
        ...state,
        isSuccessDeleteTicket: null,
      };
    case actions.DELETE_TICKETS_SUCCESS:
      return {
        ...state,
        isSuccessDeleteTicket: true,
      };
    case actions.DELETE_TICKETS_FAILURE:
      return { ...state, isSuccessDeleteTicket: false };

    default:
      return state;
  }
}

export default Reducer;
