import actions from "./actions";

const initialState = {
  buildingInstallationEquipment: null,
  extinquishingEquipments: [],
  isFecthingEquipment: false,
  isFetchedBasicData: null,
  isChangedEquipment: null,
  isOccuredError: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BASIC_DATA_EQUIPMENT:
      return {
        ...state,
        extinquishingEquipments: [],
        isFetchedBasicData: null,
      };

    case actions.FETCH_BASIC_DATA_EQUIPMENT_SUCCESS:
      return {
        ...state,
        isFetchedBasicData: true,
        extinquishingEquipments: action.payload,
      };

    case actions.FETCH_BASIC_DATA_EQUIPMENT_FAILURE:
      return {
        ...state,
        isFetchedBasicData: false,
      };

    case actions.FETCH_BUILDING_INSTALLATION_EQUIPMENT:
      return {
        ...state,
        buildingInstallationEquipment: null,
        isFecthingEquipment: true,
        isOccuredError: null,
      };
    case actions.FETCH_BUILDING_INSTALLATION_EQUIPMENT_SUCCESS:
      return {
        ...state,
        isFecthingEquipment: false,
        isOccuredError: false,
        isFetchedBasicData: null,
        isChangedEquipment: null,
        buildingInstallationEquipment: action.payload,
      };
    case actions.FETCH_BUILDING_INSTALLATION_EQUIPMENT_FAILURE:
      return {
        ...state,
        isFecthingEquipment: false,
        isOccuredError: true,
        isFetchedBasicData: null,
      };

    case actions.CREATE_BUILDING_INSTALLATION_EQUIPMENT:
      return { ...state, isChangedEquipment: null };

    case actions.CREATE_BUILDING_INSTALLATION_EQUIPMENT_SUCCESS:
      return { ...state, isChangedEquipment: true };

    case actions.CREATE_BUILDING_INSTALLATION_EQUIPMENT_FAILURE:
      return { ...state, isChangedEquipment: false };

    case actions.UPDATE_BUILDING_INSTALLATION_EQUIPMENT:
      return { ...state, isChangedEquipment: null };

    case actions.UPDATE_BUILDING_INSTALLATION_EQUIPMENT_SUCCESS:
      return { ...state, isChangedEquipment: true };

    case actions.UPDATE_BUILDING_INSTALLATION_EQUIPMENT_FAILURE:
      return { ...state, isChangedEquipment: false };

    default:
      return state;
  }
}

export default Reducer;
