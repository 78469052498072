import actions from "./actions";

const initialState = {
  updated: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_USER_WITH_ONBOARDING_DATA:
      return {
        ...state,
        updated: false,
      };
    case actions.UPDATE_USER_WITH_ONBOARDING_DATA_SUCCESS:
      return {
        ...state,
        updated: true,
      };
    case actions.UPDATE_USER_WITH_ONBOARDING_DATA_FAILURE:
      return { ...state, updated: false };

    default:
      return state;
  }
}

export default Reducer;
