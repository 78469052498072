import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { deleteRequest, getRequest, postRequest, putRequest } from "utils/axios-client";

function* getEquipment() {
  try {
    const response = yield callApi(() => getRequest('cms/extinquishing-equipment'))
    yield put({
      type: actions.FETCH_EQUIPMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_EQUIPMENT_FAILURE });
  }
}

function* addEquipment(action) {
  try {
    yield callApi(() => postRequest('cms/extinquishing-equipment', action.payload))
    yield put({
      type: actions.ADD_EQUIPMENT_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.ADD_EQUIPMENT_FAILURE });
  }
}

function* editEquipment(action) {
  try {
    yield callApi(() => putRequest(`cms/extinquishing-equipment?id=${action.id}`, action.payload))
    yield put({
      type: actions.UPDATE_EQUIPMENT_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_EQUIPMENT_FAILURE });
  }
}

function* deleteEquipment(action) {
  try {
    yield callApi(() => deleteRequest(`cms/extinquishing-equipment?id=${action.id}`))
    yield put({
      type: actions.DELETE_EQUIPMENT_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_EQUIPMENT_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_EQUIPMENT, getEquipment)]);
  yield all([takeLatest(actions.ADD_EQUIPMENT, addEquipment)]);
  yield all([takeLatest(actions.UPDATE_EQUIPMENT, editEquipment)]);
  yield all([takeLatest(actions.DELETE_EQUIPMENT, deleteEquipment)]);

}
