import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  postRequestWithForm,
  putRequest,
} from "utils/axios-client";

function* getExerciseUser(action) {
  try {
    const response = yield callApi(() => getRequest("crm/exercise-user"));
    yield put({
      type: actions.FETCH_EXERCISE_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_EXERCISE_USER_FAILURE });
  }
}

function* addExerciseUserByFile(action) {
  try {
    yield callApi(() =>
      postRequestWithForm("crm/exercise-user-file", action.payload)
    );
    yield put({
      type: actions.CREATE_EXERCISE_USER_BY_FILE_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_EXERCISE_USER_BY_FILE_FAILURE });
  }
}

function* addExerciseUser(action) {
  try {
    yield callApi(() => postRequest("crm/exercise-user", action.payload));
    yield put({
      type: actions.CREATE_EXERCISE_USER_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_EXERCISE_USER_FAILURE });
  }
}

function* editExerciseUser(action) {
  try {
    yield callApi(() => putRequest("crm/exercise-user", action.payload));
    yield put({
      type: actions.UPDATE_EXERCISE_USER_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_EXERCISE_USER_FAILURE });
  }
}

function* deleteExerciseUser(action) {
  try {
    yield callApi(() =>
      deleteRequest(`crm/exercise-user?id=${action.payload}`)
    );
    yield put({
      type: actions.DELETE_EXERCISE_USER_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_EXERCISE_USER_FAILURE });
  }
}

function* downloadExerciseUserFile(action) {
  try {
    yield callApi(() => getRequest("exercise/file"));
    yield put({
      type: actions.FETCH_EXERCISE_USER_FILE_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_EXERCISE_USER_FILE_FAILURE });
  }
}

function* updateAllExerciseUser(action) {
  try {
    yield callApi(() => postRequest("exercise/update_all_exercise"));
    yield put({
      type: actions.UPDATE_ALL_EXERCISE_USER_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_ALL_EXERCISE_USER_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_EXERCISE_USER, getExerciseUser)]);
  yield all([
    takeLatest(actions.CREATE_EXERCISE_USER_BY_FILE, addExerciseUserByFile),
  ]);
  yield all([takeLatest(actions.CREATE_EXERCISE_USER, addExerciseUser)]);
  yield all([takeLatest(actions.UPDATE_EXERCISE_USER, editExerciseUser)]);
  yield all([takeLatest(actions.DELETE_EXERCISE_USER, deleteExerciseUser)]);
  yield all([
    takeLatest(actions.FETCH_EXERCISE_USER_FILE, downloadExerciseUserFile),
  ]);
  yield all([
    takeLatest(actions.UPDATE_ALL_EXERCISE_USER, updateAllExerciseUser),
  ]);
}
