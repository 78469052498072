import actions from "./actions";

const initialState = {
  buildingInstallationFireAlarm: null,
  fireAlarmCentralType: [],
  fireAlarmCoverageArea: [],
  fireAlarmTaker: [],
  isFecthingFireAlarm: false,
  isFetchedBasicData: null,
  isChangedFireAlarm: null,
  isOccuredError: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BASIC_DATA:
      return {
        ...state,
        fireAlarmCentralType: [],
        fireAlarmCoverageArea: [],
        fireAlarmTaker: [],
        isFetchedBasicData: null,
      };

    case actions.FETCH_BASIC_DATA_SUCCESS:
      return {
        ...state,
        isFetchedBasicData: true,
        fireAlarmTaker: action.payloadTaker,
        fireAlarmCentralType: action.payloadCentralType,
        fireAlarmCoverageArea: action.payloadCoverageArea,
      };

    case actions.FETCH_BASIC_DATA_FAILURE:
      return {
        ...state,
        isFetchedBasicData: false,
      };

    case actions.FETCH_BUILDING_INSTALLATION_FIRE_ALARM:
      return {
        ...state,
        buildingInstallationFireAlarm: null,
        isFecthingFireAlarm: true,
        isOccuredError: null,
      };
    case actions.FETCH_BUILDING_INSTALLATION_FIRE_ALARM_SUCCESS:
      return {
        ...state,
        isFecthingFireAlarm: false,
        isOccuredError: false,
        isFetchedBasicData: null,
        isChangedFireAlarm: null,
        buildingInstallationFireAlarm: action.payloadFireAlarm,
      };
    case actions.FETCH_BUILDING_INSTALLATION_FIRE_ALARM_FAILURE:
      return {
        ...state,
        isFecthingFireAlarm: false,
        isOccuredError: true,
        isFetchedBasicData: null,
      };

    case actions.CREATE_BUILDING_INSTALLATION_FIRE_ALARM:
      return { ...state, isChangedFireAlarm: null };

    case actions.CREATE_BUILDING_INSTALLATION_FIRE_ALARM_SUCCESS:
      return { ...state, isChangedFireAlarm: true };

    case actions.CREATE_BUILDING_INSTALLATION_FIRE_ALARM_FAILURE:
      return { ...state, isChangedFireAlarm: false };

    case actions.UPDATE_BUILDING_INSTALLATION_FIRE_ALARM:
      return { ...state, isChangedFireAlarm: null };

    case actions.UPDATE_BUILDING_INSTALLATION_FIRE_ALARM_SUCCESS:
      return { ...state, isChangedFireAlarm: true };

    case actions.UPDATE_BUILDING_INSTALLATION_FIRE_ALARM_FAILURE:
      return { ...state, isChangedFireAlarm: false };

    default:
      return state;
  }
}

export default Reducer;
