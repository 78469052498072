const actions = {
  FETCH_CMS_OTHER_INSTALLATION: "FETCH_CMS_OTHER_INSTALLATION",
  FETCH_CMS_OTHER_INSTALLATION_SUCCESS: "FETCH_CMS_OTHER_INSTALLATION_SUCCESS",
  FETCH_CMS_OTHER_INSTALLATION_FAILURE: "FETCH_CMS_OTHER_INSTALLATION_FAILURE",

  ADD_CMS_OTHER_INSTALLATION: "ADD_CMS_OTHER_INSTALLATION",
  ADD_CMS_OTHER_INSTALLATION_SUCCESS: "ADD_CMS_OTHER_INSTALLATION_SUCCESS",
  ADD_CMS_OTHER_INSTALLATION_FAILURE: "ADD_CMS_OTHER_INSTALLATION_FAILURE",

  UPDATE_CMS_OTHER_INSTALLATION: "UPDATE_CMS_OTHER_INSTALLATION",
  UPDATE_CMS_OTHER_INSTALLATION_SUCCESS: "UPDATE_CMS_OTHER_INSTALLATION_SUCCESS",
  UPDATE_CMS_OTHER_INSTALLATION_FAILURE: "UPDATE_CMS_OTHER_INSTALLATION_FAILURE",

  DELETE_CMS_OTHER_INSTALLATION: "DELETE_CMS_OTHER_INSTALLATION",
  DELETE_CMS_OTHER_INSTALLATION_SUCCESS: "DELETE_CMS_OTHER_INSTALLATION_SUCCESS",
  DELETE_CMS_OTHER_INSTALLATION_FAILURE: "DELETE_CMS_OTHER_INSTALLATION_FAILURE",
};

export default actions;
