import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest, putRequest, putRequestWithForm } from "utils/axios-client";

function* fetchSupervisoryReport(action) {
  try {
    const response = yield callApi(() => getRequest(`building/inspection-report?buildingId=${action.payload}`))
    const responseCustomer = yield callApi(() => getRequest('crm/customer'))
    yield put({
      type: actions.FETCH_BUILDING_SUPERVISORY_REPORT_SUCCESS,
      payload: response.data,
      payloadCustomer: responseCustomer.data
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_SUPERVISORY_REPORT_FAILURE });
  }
}

// function* fetchAllSupervisoryReports() {
//   try {
//     const response = yield callApi(() => getRequest(''))
//     yield put({
//       type: actions.FETCH_BUILDING_FIRE_HISTORIES_SUCCESS,
//       payload: response.data.building_fireconcept,
//     });
//   } catch (error) {
//     yield put({ type: actions.FETCH_BUILDING_FIRE_HISTORIES_FAILURE });
//   }
// }

function* addSupervisoryReport(action) {
  try {
    const response = yield callApi(() => postRequest(`building/inspection-report?buildingId=${action.buildingId}`, action.payload))
    yield put({
      type: actions.CREATE_BUILDING_SUPERVISORY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_BUILDING_SUPERVISORY_REPORT_FAILURE });
  }
}

function* updateSupervisoryReport(action) {
  try {
    const response = yield callApi(() => putRequest(`building/inspection-report?id=${action.id}`, action.payload))
    yield put({
      type: actions.UPDATE_BUILDING_SUPERVISORY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_SUPERVISORY_REPORT_FAILURE });
  }
}

function* updateSupervisoryReportFiles(action) {
  try {
    const response = yield callApi(() => putRequestWithForm('', action.payload))
    yield put({
      type: actions.UPDATE_BUILDING_SUPERVISORY_REPORT_FILES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_SUPERVISORY_REPORT_FILES_FAILURE });
  }
}

function* updateSupervisoryReportFilesConfirm(action) {
  try {
    const response = yield callApi(() => putRequest('', action.payload))
    yield put({
      type: actions.UPDATE_BUILDING_SUPERVISORY_REPORT_CONFIRM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_SUPERVISORY_REPORT_CONFIRM_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_BUILDING_SUPERVISORY_REPORT, fetchSupervisoryReport)]);
  yield all([takeLatest(actions.CREATE_BUILDING_SUPERVISORY_REPORT, addSupervisoryReport)]);
  yield all([takeLatest(actions.UPDATE_BUILDING_SUPERVISORY_REPORT, updateSupervisoryReport)]);
  yield all([takeLatest(actions.UPDATE_BUILDING_SUPERVISORY_REPORT_FILES, updateSupervisoryReportFiles)]);
  yield all([takeLatest(actions.UPDATE_BUILDING_SUPERVISORY_REPORT_CONFIRM, updateSupervisoryReportFilesConfirm)]);
}
