import { all, put, takeLatest } from "redux-saga/effects";
import { actions } from "./actions";
import { callApi } from "states/saga";
import { getFileDownload, postRequestWithForm } from "utils/axios-client";


function* fileUpload(action) {
  try {
    const response = yield callApi(() => postRequestWithForm('utils/file/upload'), action.payload);
    yield put({
      type: actions.FILE_UPLAOD_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.FILE_UPLAOD_FAILURE });
  }
}

function* fileDownload(action) {
  try {
    yield getFileDownload(`utils/file/download?path=${action.payload}`)
      .then(
        (res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          if (typeof window.navigator.msSaveBlob === 'function') {
            window.navigator.msSaveBlob(
              res.data,
              action.fileName
            );
          } else {
            link.setAttribute('download', action.fileName);
            document.body.appendChild(link);
            link.click();
          }
        },
        (error) => {
          alert("Something went wrong");
        }
      )
    yield put({
      type: actions.FILE_DOWNLOAD_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.FILE_DOWNLOAD_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FILE_UPLAOD, fileUpload)]);
  yield all([takeLatest(actions.FILE_DOWNLOAD, fileDownload)]);
}
