import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchVentilation(action) {
  try {
    const responseCustomer = yield callApi(() => getRequest("crm/customer"));
    const responseMainTheme = yield callApi(() => getRequest("cms/main-theme"));
    const responseServicePartner = yield callApi(() =>
      getRequest("cms/service-provider")
    );
    const response = yield callApi(() =>
      getRequest(`building/ventilation?buildingId=${action.payload}`)
    );

    yield put({
      type: actions.FETCH_BUILDING_VENTILATION_SUCCESS,
      payload: response.data,
      payloadCustomer: responseCustomer.data,
      payloadMainTheme: responseMainTheme.data,
      payloadServicePartner: responseServicePartner.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_VENTILATION_FAILURE });
  }
}

function* addVentilation(action) {
  try {
    const response = yield callApi(() =>
      postRequest(`building/ventilation`, action.payload)
    );
    yield put({
      type: actions.CREATE_BUILDING_VENTILATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_BUILDING_VENTILATION_FAILURE });
  }
}

function* updateVentilation(action) {
  try {
    const response = yield callApi(() =>
      putRequest(`building/ventilation?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_BUILDING_VENTILATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_VENTILATION_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_BUILDING_VENTILATION, fetchVentilation)]);
  yield all([takeLatest(actions.CREATE_BUILDING_VENTILATION, addVentilation)]);
  yield all([
    takeLatest(actions.UPDATE_BUILDING_VENTILATION, updateVentilation),
  ]);
}
