import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequestNoToken, putRequest } from "utils/axios-client";

function* getPlan(action) {
  try {
    const url = !action.payload ? "cms/plan" : `cms/plan?id=${action.payload}`;
    const response = yield callApi(() => getRequestNoToken(url));
    yield put({
      type: actions.GETPLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.GETPLAN_FAILURE });
  }
}

function* editPlan(action) {
  try {
    const response = yield callApi(() =>
      putRequest("userChangePlan", action.payload)
    );
    yield put({
      type: actions.EDITPLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.EDITPLAN_FAILURE });
  }
}

function* deletePlan(action) {
  try {
    // const response = yield callApi(() => deleteRequest(`cms/plan?user_id=${action.payload}`))
    yield put({
      type: actions.DELETEPLAN_SUCCESS,
      // payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.DELETEPLAN_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.GETPLAN, getPlan)]);
  yield all([takeLatest(actions.EDITPLAN, editPlan)]);
  yield all([takeLatest(actions.DELETEPLAN, deletePlan)]);
}
