import actions from "./actions";

const initialState = {
  buildingInstallationEmergency: null,
  fetchedCoverageArea: [],
  isFecthingEmergency: false,
  isFetchedBasicDataEmergency: null,
  isChangedEmergency: null,
  isOccuredErrorEmergency: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BASIC_DATA_EMERGENCY:
      return {
        ...state,
        fetchedCoverageArea: [],
        isFetchedBasicDataEmergency: null,
      };

    case actions.FETCH_BASIC_DATA_EMERGENCY_SUCCESS:
      return {
        ...state,
        isFetchedBasicDataEmergency: true,
        fetchedCoverageArea: action.payload,
      };

    case actions.FETCH_BASIC_DATA_EMERGENCY_FAILURE:
      return {
        ...state,
        isFetchedBasicDataEmergency: false,
      };

    case actions.FETCH_BUILDING_INSTALLATION_EMERGENCY:
      return {
        ...state,
        buildingInstallationEmergency: null,
        isFecthingEmergency: true,
        isOccuredErrorEmergency: null,
      };
    case actions.FETCH_BUILDING_INSTALLATION_EMERGENCY_SUCCESS:
      return {
        ...state,
        isFecthingEmergency: false,
        isOccuredErrorEmergency: false,
        isFetchedBasicDataEmergency: null,
        isChangedEmergency: null,
        buildingInstallationEmergency: action.payload,
      };
    case actions.FETCH_BUILDING_INSTALLATION_EMERGENCY_FAILURE:
      return {
        ...state,
        isFecthingEmergency: false,
        isOccuredErrorEmergency: true,
        isFetchedBasicDataEmergency: null,
      };

    case actions.CREATE_BUILDING_INSTALLATION_EMERGENCY:
      return { ...state, isChangedEmergency: null };

    case actions.CREATE_BUILDING_INSTALLATION_EMERGENCY_SUCCESS:
      return { ...state, isChangedEmergency: true };

    case actions.CREATE_BUILDING_INSTALLATION_EMERGENCY_FAILURE:
      return { ...state, isChangedEmergency: false };

    case actions.UPDATE_BUILDING_INSTALLATION_EMERGENCY:
      return { ...state, isChangedEmergency: null };

    case actions.UPDATE_BUILDING_INSTALLATION_EMERGENCY_SUCCESS:
      return { ...state, isChangedEmergency: true };

    case actions.UPDATE_BUILDING_INSTALLATION_EMERGENCY_FAILURE:
      return { ...state, isChangedEmergency: false };

    default:
      return state;
  }
}

export default Reducer;
