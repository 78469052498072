import { combineReducers } from "redux";
// auth reducers
import authenticateReducer from "states/auth/login/reducer";
import signupReducer from "states/auth/signup/reducer";
import requestResetReducer from "states/auth/reqresetpass/reducer";
import resetReducer from "states/auth/reset/reducer";
import accountReducer from "states/auth/account/reducer";
import userStatisticsReducer from "states/auth/statistics/reducer";
import updateUserWithOnboardingDataReducer from "states/auth/user/reducer";

// building reducers
import organizationReducer from "states/building/fire_organization/reducer";
import propertyReducer from "states/building/property/reducer";
import buildingOwnerReducer from "states/building/owner/reducer";
import buildingManagerReducer from "states/building/manager/reducer";
import buildingFireManagerReducer from "states/building/fire_manager/reducer";
import buildingPreparednessReducer from "states/building/preparation/reducer";
import buildingTenantsReducer from "states/building/tenant/reducer";
import buildingGroupReducer from "states/cms/building_group/reducer";
import buildingFireConceptsReducer from "states/building/fire_concept/reducer";
import buildingFireAlarmReducer from "states/building/fire_alarm/reducer";
import buildingInstallationEquipmentReducer from "states/building/extinquishing-equipment/reducer";
import buildingInstallationEmergencyReducer from "states/building/emergency_light/reducer";
import buildingIstallationOtherReducer from "states/building/other_installation/reducer";
import buildingSupervisoryReportReducer from "states/building/inspection/reducer";
import buildingConformityReportReducer from "states/building/conformity/reducer";
import buildingThermographyReportReducer from "states/building/thermography/reducer";
import buildingVentilationReducer from "states/building/ventilation/reducer";
import buildingPressReducer from "states/building/press/reducer";
import buildingNewTenantsReducer from "states/building/new_tenants/reducer";
import buildingFireDrawingReducer from "states/building/fire_drawing/reducer";
import buildingConditionReducer from "states/building/condition/reducer";
import buildingContactReducer from "states/building/contact/reducer";
import buildingSmokeHatchReducer from "states/building/smoke_hatch/reducer";
import buildingSprinklerReducer from "states/building/sprinkler/reducer";
import buildingFirefilesReducer from "states/building/fire-files/reducer";
import buildingChecklistReducer from "states/building/checklist/reducer";
import buildingThemeDeviationReducer from "states/building/theme_deviation/reducer";
import buildingThemeCheckReducer from "states/building/theme_check/reducer";

// cms reducers
import roleReducer from "states/cms/role/reducer";
import planReducer from "states/cms/plan/reducer";
import themeReducer from "states/cms/theme/reducer";
import cmsExtinquishingEquipmentReducer from "states/cms/extinquishing_equipment/reducer";
import cmsCentralTypeReducer from "states/cms/fire_alarm_central_type/reducer";
import cmsCoverageAreaReducer from "states/cms/coverage_area/reducer";
import cmsConnectionReducer from "states/cms/fire_alarm_taker/reducer";
import cmsOtherInstallationReducer from "states/cms/other_installation/reducer";
import cmsLawsReducer from "states/cms/laws/reducer";
import cmsBuildingCheckpointsReducer from "states/cms/building_checkpoints/reducer";
import cmsExerciseCategoryReducer from "states/cms/exercise_category/reducer";
import cmsPositionReducer from "states/cms/position/reducer";
import cmsInformationReducer from "states/cms/information/reducer";
import cmsPackagesReducer from "states/cms/packages/reducer";
import cmsServiceProviderReducer from "states/cms/service_provider/reducer";
import cmsFireAlarmRequirementAccordingReducer from "states/cms/fire_alarm_requirement_according/reducer";

// crm reducers
import customerReducer from "states/crm/customer/reducer";
import subscriptionReducer from "states/crm/subscription/reducer";
import ticketsReducer from "states/crm/ticket/reducer";
import crmExerciseReducer from "states/crm/exercise/reducer";
import crmExerciseUserReducer from "states/crm/exercise_user/reducer";
import vendorReducer from "states/crm/vendors/reducer";
import crmPlanReducer from "states/crm/plan/reducer";

// workflow reducers
import taskReducer from "states/workflow/task/reducer";
import deviationReducer from "states/workflow/deviation/reducer";
import taskCommentReducer from "states/workflow/taskcomment/reducer";
import googleCalendarReducer from "states/workflow/google_calendar/reducer";
import serviceCallReducer from "states/workflow/service_call/reducer";
import yearlyControlReducer from "states/workflow/yearly_control/reducer";

// settings reducers
import settingDueDateReducer from "states/setting/duedate/reducer";

// user reducers
import userInvitationReducer from "states/user/invitation/reducer";
import userNotificationReducer from "states/user/notification/reducer";

// other reducers
import overviewReducer from "states/others/overview/reducer";
import invoiceReducer from "states/others/invoice/reducer";
import fileManagerReducer from "states/others/common_file_manager/reducer";

const rootReducer = combineReducers({
  auth: authenticateReducer, //auth reducers
  signup: signupReducer,
  requestReset: requestResetReducer,
  reset: resetReducer,
  account: accountReducer,
  userStatistics: userStatisticsReducer,
  updateUserWithOnboardingData: updateUserWithOnboardingDataReducer,
  organization: organizationReducer, //building reducers
  property: propertyReducer,
  buildingOwner: buildingOwnerReducer,
  buildingManager: buildingManagerReducer,
  buildingFireManager: buildingFireManagerReducer,
  buildingPreparedness: buildingPreparednessReducer,
  buildingTenants: buildingTenantsReducer,
  buildingGroup: buildingGroupReducer,
  buildingFireConcepts: buildingFireConceptsReducer,
  buildingFireAlarm: buildingFireAlarmReducer,
  buildingInstallationEquipment: buildingInstallationEquipmentReducer,
  buildingInstallationEmergency: buildingInstallationEmergencyReducer,
  buildingInstallationOther: buildingIstallationOtherReducer,
  buildingSupervisoryReport: buildingSupervisoryReportReducer,
  buildingConformityReport: buildingConformityReportReducer,
  buildingThermographyReport: buildingThermographyReportReducer,
  buildingVentilation: buildingVentilationReducer,
  buildingPress: buildingPressReducer,
  buildingNewTenants: buildingNewTenantsReducer,
  buildingFireDrawing: buildingFireDrawingReducer,
  buildingCondition: buildingConditionReducer,
  buildingContact: buildingContactReducer,
  buildingSmokeHatch: buildingSmokeHatchReducer,
  buildingSprinkler: buildingSprinklerReducer,
  buildingFireFiles: buildingFirefilesReducer,
  buildingChecklist: buildingChecklistReducer,
  buildingThemeDeviation: buildingThemeDeviationReducer,
  buildingThemeCheck: buildingThemeCheckReducer,
  role: roleReducer, // cms reducers
  plan: planReducer,
  theme: themeReducer,
  cmsExtinquishingEquipment: cmsExtinquishingEquipmentReducer,
  cmsCentralType: cmsCentralTypeReducer,
  cmsCoverageArea: cmsCoverageAreaReducer,
  cmsConnection: cmsConnectionReducer,
  cmsOtherInstallation: cmsOtherInstallationReducer,
  cmsLaws: cmsLawsReducer,
  cmsBuildingCheckpoints: cmsBuildingCheckpointsReducer,
  cmsExerciseCategory: cmsExerciseCategoryReducer,
  cmsPosition: cmsPositionReducer,
  cmsInformation: cmsInformationReducer,
  cmsPackage: cmsPackagesReducer,
  cmsServiceProvider: cmsServiceProviderReducer,
  cmsFireAlarmRequirementAccording: cmsFireAlarmRequirementAccordingReducer,
  customer: customerReducer, // crm reducers
  subscription: subscriptionReducer,
  ticket: ticketsReducer,
  crmExercise: crmExerciseReducer,
  crmExerciseUser: crmExerciseUserReducer,
  vendor: vendorReducer,
  crmPlan: crmPlanReducer,
  task: taskReducer, // workflow reducers
  deviation: deviationReducer,
  taskComment: taskCommentReducer,
  googleCalendar: googleCalendarReducer,
  workflowServiceCall: serviceCallReducer,
  workflowYearlyControl: yearlyControlReducer,
  settingDueDate: settingDueDateReducer, // setting reducers
  userInvitation: userInvitationReducer, //user reducers
  userNotification: userNotificationReducer,
  overview: overviewReducer, // other reducers
  invoice: invoiceReducer,
  fileManager: fileManagerReducer,
});

export default rootReducer;
