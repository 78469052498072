const actions = {
  FETCH_BUILDING_SMOKE_HATCH: "FETCH_BUILDING_SMOKE_HATCH",
  FETCH_BUILDING_SMOKE_HATCH_SUCCESS: "FETCH_BUILDING_SMOKE_HATCH_SUCCESS",
  FETCH_BUILDING_SMOKE_HATCH_FAILURE: "FETCH_BUILDING_SMOKE_HATCH_FAILURE",

  ADD_BUILDING_SMOKE_HATCH: "ADD_BUILDING_SMOKE_HATCH",
  ADD_BUILDING_SMOKE_HATCH_SUCCESS: "ADD_BUILDING_SMOKE_HATCH_SUCCESS",
  ADD_BUILDING_SMOKE_HATCH_FAILURE: "ADD_BUILDING_SMOKE_HATCH_FAILURE",

  UPDATE_BUILDING_SMOKE_HATCH: "UPDATE_BUILDING_SMOKE_HATCH",
  UPDATE_BUILDING_SMOKE_HATCH_SUCCESS: "UPDATE_BUILDING_SMOKE_HATCH_SUCCESS",
  UPDATE_BUILDING_SMOKE_HATCH_FAILURE: "UPDATE_BUILDING_SMOKE_HATCH_FAILURE",

  DELETE_BUILDING_SMOKE_HATCH: "DELETE_BUILDING_SMOKE_HATCH",
  DELETE_BUILDING_SMOKE_HATCH_SUCCESS: "DELETE_BUILDING_SMOKE_HATCH_SUCCESS",
  DELETE_BUILDING_SMOKE_HATCH_FAILURE: "DELETE_BUILDING_SMOKE_HATCH_FAILURE",

  RESET_BUILDING_SMOKE_HATCH: "RESET_BUILDING_SMOKE_HATCH",
};

export default actions;
