const actions = {
  FETCH_SERVICE_CALL: "FETCH_SERVICE_CALL",
  FETCH_SERVICE_CALL_SUCCESS: "FETCH_SERVICE_CALL_SUCCESS",
  FETCH_SERVICE_CALL_FAILURE: "FETCH_SERVICE_CALL_FAILURE",

  CREATE_SERVICE_CALL: "CREATE_SERVICE_CALL",
  CREATE_SERVICE_CALL_SUCCESS: "CREATE_SERVICE_CALL_SUCCESS",
  CREATE_SERVICE_CALL_FAILURE: "CREATE_SERVICE_CALL_FAILURE",

  UPDATE_SERVICE_CALL: "UPDATE_SERVICE_CALL",
  UPDATE_SERVICE_CALL_SUCCESS: "UPDATE_SERVICE_CALL_SUCCESS",
  UPDATE_SERVICE_CALL_FAILURE: "UPDATE_SERVICE_CALL_FAILURE",

  DELETE_SERVICE_CALL: "DELETE_SERVICE_CALL",
  DELETE_SERVICE_CALL_SUCCESS: "DELETE_SERVICE_CALL_SUCCESS",
  DELETE_SERVICE_CALL_FAILURE: "DELETE_SERVICE_CALL_FAILURE",

  CREATE_SERVICE_CALL_COMMENT: "CREATE_SERVICE_CALL_COMMENT",
  CREATE_SERVICE_CALL_COMMENT_SUCCESS: "CREATE_SERVICE_CALL_COMMENT_SUCCESS",
  CREATE_SERVICE_CALL_COMMENT_FAILURE: "CREATE_SERVICE_CALL_COMMENT_FAILURE",

  UPDATE_SERVICE_CALL_COMMENT: "UPDATE_SERVICE_CALL_COMMENT",
  UPDATE_SERVICE_CALL_COMMENT_SUCCESS: "UPDATE_SERVICE_CALL_COMMENT_SUCCESS",
  UPDATE_SERVICE_CALL_COMMENT_FAILURE: "UPDATE_SERVICE_CALL_COMMENT_FAILURE",
};

export default actions;
