const actions = {
  FETCH_BUILDING_FIRE_CONCEPTS: "FETCH_BUILDING_FIRE_CONCEPTS",
  FETCH_BUILDING_FIRE_CONCEPTS_SUCCESS: "FETCH_BUILDING_FIRE_CONCEPTS_SUCCESS",
  FETCH_BUILDING_FIRE_CONCEPTS_FAILURE: "FETCH_BUILDING_FIRE_CONCEPTS_FAILURE",

  FETCH_BUILDING_FIRE_HISTORIES: "FETCH_BUILDING_FIRE_HISTORIES",
  FETCH_BUILDING_FIRE_HISTORIES_SUCCESS:
    "FETCH_BUILDING_FIRE_HISTORIES_SUCCESS",
  FETCH_BUILDING_FIRE_HISTORIES_FAILURE:
    "FETCH_BUILDING_FIRE_HISTORIES_FAILURE",

  CREATE_BUILDING_FIRE_CONCEPTS: "CREATE_BUILDING_FIRE_CONCEPTS",
  CREATE_BUILDING_FIRE_CONCEPTS_SUCCESS:
    "CREATE_BUILDING_FIRE_CONCEPTS_SUCCESS",
  CREATE_BUILDING_FIRE_CONCEPTS_FAILURE:
    "CREATE_BUILDING_FIRE_CONCEPTS_FAILURE",

  UPDATE_BUILDING_FIRE_CONCEPTS: "UPDATE_BUILDING_FIRE_CONCEPTS",
  UPDATE_BUILDING_FIRE_CONCEPTS_SUCCESS:
    "UPDATE_BUILDING_FIRE_CONCEPTS_SUCCESS",
  UPDATE_BUILDING_FIRE_CONCEPTS_FAILURE:
    "UPDATE_BUILDING_FIRE_CONCEPTS_FAILURE",

  UPDATE_BUILDING_FIRE_CONCEPTS_FILES: "UPDATE_BUILDING_FIRE_CONCEPTS_FILES",
  UPDATE_BUILDING_FIRE_CONCEPTS_FILES_SUCCESS:
    "UPDATE_BUILDING_FIRE_CONCEPTS_FILES_SUCCESS",
  UPDATE_BUILDING_FIRE_CONCEPTS_FILES_FAILURE:
    "UPDATE_BUILDING_FIRE_CONCEPTS_FILES_FAILURE",

  UPDATE_BUILDING_FIRE_CONCEPTS_CONFIRM:
    "UPDATE_BUILDING_FIRE_CONCEPTS_CONFIRM",
  UPDATE_BUILDING_FIRE_CONCEPTS_CONFIRM_SUCCESS:
    "UPDATE_BUILDING_FIRE_CONCEPTS_CONFIRM_SUCCESS",
  UPDATE_BUILDING_FIRE_CONCEPTS_CONFIRM_FAILURE:
    "UPDATE_BUILDING_FIRE_CONCEPTS_CONFIRM_FAILURE",

  DELETE_BUILDING_FIRE_CONCEPTS: "DELETE_BUILDING_FIRE_CONCEPTS",
  DELETE_BUILDING_FIRE_CONCEPTS_SUCCESS:
    "DELETE_BUILDING_FIRE_CONCEPTS_SUCCESS",
  DELETE_BUILDING_FIRE_CONCEPTS_FAILURE:
    "DELETE_BUILDING_FIRE_CONCEPTS_FAILURE",
};

export default actions;
