const actions = {
  FETCH_CMS_INFORMATIONS: "FETCH_CMS_INFORMATIONS",
  FETCH_CMS_INFORMATIONS_SUCCESS: "FETCH_CMS_INFORMATIONS_SUCCESS",
  FETCH_CMS_INFORMATIONS_FAILURE: "FETCH_CMS_INFORMATIONS_FAILURE",

  ADD_CMS_INFORMATIONS: "ADD_CMS_INFORMATIONS",
  ADD_CMS_INFORMATIONS_SUCCESS: "ADD_CMS_INFORMATIONS_SUCCESS",
  ADD_CMS_INFORMATIONS_FAILURE: "ADD_CMS_INFORMATIONS_FAILURE",

  UPDATE_CMS_INFORMATIONS: "UPDATE_CMS_INFORMATIONS",
  UPDATE_CMS_INFORMATIONS_SUCCESS: "UPDATE_CMS_INFORMATIONS_SUCCESS",
  UPDATE_CMS_INFORMATIONS_FAILURE: "UPDATE_CMS_INFORMATIONS_FAILURE",

  DELETE_CMS_INFORMATIONS: "DELETE_CMS_INFORMATIONS",
  DELETE_CMS_INFORMATIONS_SUCCESS: "DELETE_CMS_INFORMATIONS_SUCCESS",
  DELETE_CMS_INFORMATIONS_FAILURE: "DELETE_CMS_INFORMATIONS_FAILURE",

  INIT_STATE: "INIT_STATE",
};

export default actions;
