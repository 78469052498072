import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* fetchYearlyControl(action) {
  try {
    const uri = action.id
      ? `workflow/yearly-control?id=${action.id}`
      : "workflow/yearly-control";
    const response = yield callApi(() => getRequest(uri));
    yield put({
      type: actions.FETCH_YEARLY_CONTROL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_YEARLY_CONTROL_FAILURE });
  }
}

function* createYearlyControl(action) {
  try {
    const response = yield callApi(() =>
      postRequest("workflow/yearly-control", action.payload)
    );
    yield put({
      type: actions.CREATE_YEARLY_CONTROL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_YEARLY_CONTROL_FAILURE });
  }
}

function* updateYearlyControl(action) {
  try {
    const response = yield callApi(() =>
      putRequest(`workflow/yearly-control?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_YEARLY_CONTROL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_YEARLY_CONTROL_FAILURE });
  }
}

function* deleteYearlyControl(action) {
  try {
    const response = yield callApi(() =>
      deleteRequest(`workflow/yearly-control?id=${action.id}`)
    );
    yield put({
      type: actions.DELETE_YEARLY_CONTROL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_YEARLY_CONTROL_FAILURE });
  }
}

function* createYearlyControlComment(action) {
  try {
    const response = yield callApi(() =>
      postRequest(
        `workflow/yearly-control/comment?id=${action.id}`,
        action.payload
      )
    );
    yield put({
      type: actions.CREATE_YEARLY_CONTROL_COMMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_YEARLY_CONTROL_COMMENT_FAILURE });
  }
}

function* updateYearlyControlComment(action) {
  try {
    const response = yield callApi(() =>
      putRequest(
        `workflow/yearly-control/comment?id=${action.id}`,
        action.payload
      )
    );
    yield put({
      type: actions.UPDATE_YEARLY_CONTROL_COMMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_YEARLY_CONTROL_COMMENT_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_YEARLY_CONTROL, fetchYearlyControl)]);
  yield all([takeLatest(actions.UPDATE_YEARLY_CONTROL, updateYearlyControl)]);
  yield all([takeLatest(actions.DELETE_YEARLY_CONTROL, deleteYearlyControl)]);
  yield all([takeLatest(actions.CREATE_YEARLY_CONTROL, createYearlyControl)]);
  yield all([
    takeLatest(
      actions.CREATE_YEARLY_CONTROL_COMMENT,
      createYearlyControlComment
    ),
  ]);
  yield all([
    takeLatest(
      actions.UPDATE_YEARLY_CONTROL_COMMENT,
      updateYearlyControlComment
    ),
  ]);
}
