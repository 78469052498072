import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { deleteRequest, getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchAlldataForCreateVendor() {
  try {
    const response_industries = yield callApi(() => getRequest('cms/industry'));
    const response_categories = yield callApi(() => getRequest('cms/main-theme'));
    const response_buildings = yield callApi(() => getRequest('building'));
    const response_building_groups = yield callApi(() => getRequest('cms/building-group'));
    const response_customers = yield callApi(() => getRequest('crm/customer'));
    const response_contact = yield callApi(() => getRequest('crm/contact'));

    yield put({
      type: actions.FETCH_ALL_DATA_FOR_CREATE_VENDOR_SUCCESS,
      payload_contacts: response_contact.data,
      payload_industries: response_industries.data,
      payload_categories: response_categories.data,
      payload_buildings: response_buildings.data,
      payload_building_groups: response_building_groups.data,
      payload_customers: response_customers.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_ALL_DATA_FOR_CREATE_VENDOR_FAILURE });
  }
}

function* fetchVendors() {
  try {
    const response = yield callApi(() => getRequest('crm/vendor'));
    const res_theme = yield callApi(() => getRequest('cms/main-theme'));

    yield put({
      type: actions.FETCH_VENDORS_SUCCESS,
      payload: response.data,
      mainTheme: res_theme.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_VENDORS_FAILURE });
  }
}

function* fetchAddress(action) {
  try {
    const response = yield callApi(() => getRequest(`crm/address?vendorId=${action.payload}`));

    yield put({
      type: actions.FETCH_ADDRESS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_ADDRESS_FAILURE });
  }
}

function* createContactForVendor(action) {
  try {
    const response = yield callApi(() => postRequest('crm/contact', action.payload));
    yield put({
      type: actions.CREATE_CONTACT_FOR_VENDOR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_CONTACT_FOR_VENDOR_FAILURE });
  }
}

function* deleteContactForVendor(action) {
  try {
    const response = yield callApi(() => deleteRequest(`crm/contact?id=${action.payload}`));
    yield put({
      type: actions.DELETE_CONTACT_FOR_VENDOR_SUCCESS,
      payload: response.data.id,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_CONTACT_FOR_VENDOR_FAILURE });
  }
}

function* updateContactForVendor(action) {
  try {
    const response = yield callApi(() => putRequest(`crm/contact?id=${action.id}`, action.payload));
    console.log('response', response);
    yield put({
      type: actions.UPDATE_CONTACT_FOR_VENDOR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_CONTACT_FOR_VENDOR_FAILURE });
  }
}



function* createNewBuildingGroup(action) {
  try {
    const response = yield callApi(() => postRequest('cms/building-group', action.payload));
    yield put({
      type: actions.CREATE_BUILDING_GROUP_FOR_VENDOR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_BUILDING_GROUP_FOR_VENDOR_FAILURE });
  }
}

function* createNewGeneralVendor(action) {
  try {
    const response = yield callApi(() => postRequest('crm/vendor', action.payload));
    yield put({
      type: actions.CREATE_VENDOR_GENERAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_VENDOR_GENERAL_FAILURE });
  }
}


function* updateGeneralVendor(action) {
  try {
    const response = yield callApi(() => putRequest(`crm/vendor?id=${action.id}`, action.payload));
    console.log('response', response);
    yield put({
      type: actions.UPDATE_VENDOR_GENERAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_VENDOR_GENERAL_FAILURE });
  }
}

function* createNewAddressVendor(action) {
  try {
    const response = yield callApi(() => postRequest(`crm/address?vendorId=${action.vendorId}`, action.payload));
    yield put({
      type: actions.CREATE_VENDOR_ADDRESS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_VENDOR_ADDRESS_FAILURE });
  }
}

function* updateAddressVendor(action) {
  try {
    const response = yield callApi(() => putRequest(`crm/address?id=${action.id}`, action.payload));
    console.log('response', response);
    yield put({
      type: actions.UPDATE_VENDOR_ADDRESS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_VENDOR_ADDRESS_FAILURE });
  }
}
export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_ALL_DATA_FOR_CREATE_VENDOR, fetchAlldataForCreateVendor)]);
  yield all([takeLatest(actions.FETCH_VENDORS, fetchVendors)]);
  yield all([takeLatest(actions.CREATE_CONTACT_FOR_VENDOR, createContactForVendor)]);
  yield all([takeLatest(actions.DELETE_CONTACT_FOR_VENDOR, deleteContactForVendor)]);
  yield all([takeLatest(actions.UPDATE_CONTACT_FOR_VENDOR, updateContactForVendor)]);
  yield all([takeLatest(actions.CREATE_VENDOR_GENERAL, createNewGeneralVendor)]);
  yield all([takeLatest(actions.CREATE_VENDOR_ADDRESS, createNewAddressVendor)]);
  yield all([takeLatest(actions.CREATE_BUILDING_GROUP_FOR_VENDOR, createNewBuildingGroup)]);
  yield all([takeLatest(actions.UPDATE_VENDOR_GENERAL, updateGeneralVendor)]);
  yield all([takeLatest(actions.UPDATE_VENDOR_ADDRESS, updateAddressVendor)]);
  yield all([takeLatest(actions.FETCH_ADDRESS, fetchAddress)]);
}
