import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchBuildingTenants(action) {
  try {
    const response = yield callApi(() => getRequest(`building/tenant?building_id=${action.payload}`))
    yield put({
      type: actions.FETCH_BUILDING_TENANTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_TENANTS_FAILURE });
  }
}

function* createBuildingTenants(action) {
  try {
    const response = yield callApi(() => postRequest('building/tenant', action.payload))
    yield put({
      type: actions.CREATE_BUILDING_TENANTS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.CREATE_BUILDING_TENANTS_FAILURE });
  }
}

function* updateBuildingTenants(action) {
  try {
    const response = yield callApi(() => putRequest('building/tenant', action.payload))
    yield put({
      type: actions.UPDATE_BUILDING_TENANTS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_TENANTS_FAILURE });
  }
}


export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_BUILDING_TENANTS, fetchBuildingTenants)]);
  yield all([takeLatest(actions.CREATE_BUILDING_TENANTS, createBuildingTenants)]);
  yield all([takeLatest(actions.UPDATE_BUILDING_TENANTS, updateBuildingTenants)]);
}
