import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest, putRequest } from "utils/axios-client";

function* fetchBuildingFireManager(action) {
  try {
    const response = yield callApi(() => getRequest(`building/fire-manager?buildingId=${action.payload}`))
    yield put({
      type: actions.FETCH_BUILDING_FIRE_MANAGER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_FIRE_MANAGER_FAILURE });
  }
}

function* createBuildingFireManager(action) {
  try {
    const response = yield callApi(() => postRequest('building/fire-manager', action.payload))
    yield put({
      type: actions.CREATE_BUILDING_FIRE_MANAGER_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.CREATE_BUILDING_FIRE_MANAGER_FAILURE });
  }
}

function* updateBuildingFireManager(action) {
  try {
    const response = yield callApi(() => putRequest('building/fire-manager', action.payload))
    yield put({
      type: actions.UPDATE_BUILDING_FIRE_MANAGER_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_BUILDING_FIRE_MANAGER_FAILURE });
  }
}


export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_BUILDING_FIRE_MANAGER, fetchBuildingFireManager)]);
  yield all([takeLatest(actions.CREATE_BUILDING_FIRE_MANAGER, createBuildingFireManager)]);
  yield all([takeLatest(actions.UPDATE_BUILDING_FIRE_MANAGER, updateBuildingFireManager)]);
}
