const actions = {
  FETCH_EQUIPMENT: "FETCH_EQUIPMENT",
  FETCH_EQUIPMENT_SUCCESS: "FETCH_EQUIPMENT_SUCCESS",
  FETCH_EQUIPMENT_FAILURE: "FETCH_EQUIPMENT_FAILURE",

  ADD_EQUIPMENT: "ADD_EQUIPMENT",
  ADD_EQUIPMENT_SUCCESS: "ADD_EQUIPMENT_SUCCESS",
  ADD_EQUIPMENT_FAILURE: "ADD_EQUIPMENT_FAILURE",

  UPDATE_EQUIPMENT: "UPDATE_EQUIPMENT",
  UPDATE_EQUIPMENT_SUCCESS: "UPDATE_EQUIPMENT_SUCCESS",
  UPDATE_EQUIPMENT_FAILURE: "UPDATE_EQUIPMENT_FAILURE",

  DELETE_EQUIPMENT: "DELETE_EQUIPMENT",
  DELETE_EQUIPMENT_SUCCESS: "DELETE_EQUIPMENT_SUCCESS",
  DELETE_EQUIPMENT_FAILURE: "DELETE_EQUIPMENT_FAILURE",
};

export default actions;
