import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest } from "utils/axios-client";

function* getBuildingCheckpoint() {
  try {
    const response = yield callApi(() => getRequest('cms/building-checkpoint'))
    yield put({
      type: actions.FETCH_BUILDING_CHECKPOINTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_BUILDING_CHECKPOINTS_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_BUILDING_CHECKPOINTS, getBuildingCheckpoint)]);
}
