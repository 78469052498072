import actions from "./actions";

const initialState = {
  buildingSmokeHatch: [],
  isFetchingBuildingSmokeHatch: false,
  isChangedAddBuildingSmokeHatch: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_BUILDING_SMOKE_HATCH:
      return {
        ...state,
        buildingSmokeHatch: [],
        isFetchingBuildingSmokeHatch: true,
      };
    case actions.FETCH_BUILDING_SMOKE_HATCH_SUCCESS:
      return {
        ...state,
        isFetchingBuildingSmokeHatch: false,
        buildingSmokeHatch: action.payload,
      };
    case actions.FETCH_BUILDING_SMOKE_HATCH_FAILURE:
      return { ...state, isFetchingBuildingSmokeHatch: false };

    case actions.ADD_BUILDING_SMOKE_HATCH:
      return { ...state, isChangedAddBuildingSmokeHatch: null };
    case actions.ADD_BUILDING_SMOKE_HATCH_SUCCESS:
      return { ...state, isChangedAddBuildingSmokeHatch: true };
    case actions.ADD_BUILDING_SMOKE_HATCH_FAILURE:
      return { ...state, isChangedAddBuildingSmokeHatch: false };

    case actions.UPDATE_BUILDING_SMOKE_HATCH:
      return { ...state, isChangedAddBuildingSmokeHatch: null };
    case actions.UPDATE_BUILDING_SMOKE_HATCH_SUCCESS:
      return { ...state, isChangedAddBuildingSmokeHatch: true };
    case actions.UPDATE_BUILDING_SMOKE_HATCH_FAILURE:
      return { ...state, isChangedAddBuildingSmokeHatch: false };

    case actions.DELETE_BUILDING_SMOKE_HATCH:
      return { ...state, isChangedAddBuildingSmokeHatch: null };
    case actions.DELETE_BUILDING_SMOKE_HATCH_SUCCESS:
      return { ...state, isChangedAddBuildingSmokeHatch: true };
    case actions.DELETE_BUILDING_SMOKE_HATCH_FAILURE:
      return { ...state, isChangedAddBuildingSmokeHatch: false };

    case actions.RESET_BUILDING_SMOKE_HATCH:
      return { ...state, isChangedAddBuildingSmokeHatch: null };

    default:
      return state;
  }
}

export default Reducer;
