const actions = {
  FETCH_BUILDING_PREPAREDNESS: "FETCH_BUILDING_PREPAREDNESS",
  FETCH_BUILDING_PREPAREDNESS_SUCCESS: "FETCH_BUILDING_PREPAREDNESS_SUCCESS",
  FETCH_BUILDING_PREPAREDNESS_FAILURE: "FETCH_BUILDING_PREPAREDNESS_FAILURE",

  CREATE_BUILDING_PREPAREDNESS: "CREATE_BUILDING_PREPAREDNESS",
  CREATE_BUILDING_PREPAREDNESS_SUCCESS: "CREATE_BUILDING_PREPAREDNESS_SUCCESS",
  CREATE_BUILDING_PREPAREDNESS_FAILURE: "CREATE_BUILDING_PREPAREDNESS_FAILURE",

  UPDATE_BUILDING_PREPAREDNESS: "UPDATE_BUILDING_PREPAREDNESS",
  UPDATE_BUILDING_PREPAREDNESS_SUCCESS: "UPDATE_BUILDING_PREPAREDNESS_SUCCESS",
  UPDATE_BUILDING_PREPAREDNESS_FAILURE: "UPDATE_BUILDING_PREPAREDNESS_FAILURE",  
};

export default actions;
