import { all, call, put } from "redux-saga/effects";
import { notification } from "antd";

// auth sagas
import authSaga from "states/auth/login/saga";
import signupSaga from "states/auth/signup/saga";
import requestResetSaga from "states/auth/reqresetpass/saga";
import resetSaga from "states/auth/reset/saga";
import accountSaga from "states/auth/account/saga";
import userStatisticsSaga from "states/auth/statistics/saga";
import updateUserWithOnboardingDataSaga from "states/auth/user/saga";

// building sagas
import organizationSaga from "states/building/fire_organization/saga";
import propertySaga from "states/building/property/saga";
import buildingOwnerSaga from "states/building/owner/saga";
import buildingManagerSaga from "states/building/manager/saga";
import buildingFireManagerSaga from "states/building/fire_manager/saga";
import buildingPreparednessSaga from "states/building/preparation/saga";
import buildingTenantsSaga from "states/building/tenant/saga";
import buildingGroupSaga from "states/cms/building_group/saga";
import buildingFireConceptsSaga from "states/building/fire_concept/saga";
import buildingFireAlarmSaga from "states/building/fire_alarm/saga";
import buildingInstallationEquipmentSaga from "states/building/extinquishing-equipment/saga";
import buildingInstallationEmergencySaga from "states/building/emergency_light/saga";
import buildingInstallationOtherSaga from "states/building/other_installation/saga";
import buildingNewTenantsSaga from "states/building/new_tenants/saga";
import buildingSupervisoryReportSaga from "states/building/inspection/saga";
import buildingConformityReportSaga from "states/building/conformity/saga";
import buildingThermographyReportSaga from "states/building/thermography/saga";
import buildingVentilationSaga from "states/building/ventilation/saga";
import buildingPressSaga from "states/building/press/saga";
import buildingFireDrawingSaga from "states/building/fire_drawing/saga";
import buildingConditionSaga from "states/building/condition/saga";
import buildingContactSaga from "states/building/contact/saga";
import buildingSmokeHatchSaga from "states/building/smoke_hatch/saga";
import buildingSprinklerSaga from "states/building/sprinkler/saga";
import buildingFireFilesSaga from "states/building/fire-files/saga";
import buildingChecklistSaga from "states/building/checklist/saga";
import buildingThemeDeviationSaga from "states/building/theme_deviation/saga";
import buildingThemeCheckSaga from "states/building/theme_check/saga";
// cms sagas
import roleSaga from "states/cms/role/saga";
import planSaga from "states/cms/plan/saga";
import themeSaga from "states/cms/theme/saga";
import cmsExtinquishingEquipmentSaga from "states/cms/extinquishing_equipment/saga";
import cmsCentralTypeSaga from "states/cms/fire_alarm_central_type/saga";
import cmsCoverageAreaSaga from "states/cms/coverage_area/saga";
import cmsConnectionSaga from "states/cms/fire_alarm_taker/saga";
import cmsOtherInstallationSaga from "states/cms/other_installation/saga";
import cmsLawsSaga from "states/cms/laws/saga";
import cmsBuildingCheckpointsSaga from "states/cms/building_checkpoints/saga";
import cmsExerciseCategorySaga from "states/cms/exercise_category/saga";
import cmsPositionSaga from "states/cms/position/saga";
import cmsInformationSaga from "states/cms/information/saga";
import cmsPackagesSaga from "states/cms/packages/saga";
import cmsServiceProviderSaga from "states/cms/service_provider/saga";
import cmsFireAlarmRequirementAccordingSaga from "states/cms/fire_alarm_requirement_according/saga";

// crm sagas
import customerSaga from "states/crm/customer/saga";
import subscriptionSaga from "states/crm/subscription/saga";
import ticketSaga from "states/crm/ticket/saga";
import crmExercise from "states/crm/exercise/saga";
import crmExerciseUser from "states/crm/exercise_user/saga";
import vendorSaga from "states/crm/vendors/saga";
import crmPlanSaga from "states/crm/plan/saga";

// settings sagas
import settingDueDateSaga from "states/setting/duedate/saga";

// workflow sagas
import taskSaga from "states/workflow/task/saga";
import deviationSaga from "states/workflow/deviation/saga";
import taskCommentSaga from "states/workflow/taskcomment/saga";
import googleCalendarSaga from "states/workflow/google_calendar/saga";
import serviceCallSaga from "states/workflow/service_call/saga";
import yearlyControlSaga from "states/workflow/yearly_control/saga";

// user sagas
import userInvitationSaga from "states/user/invitation/saga";
import userNotificationSaga from "states/user/notification/saga";

// other sagas
import overviewSaga from "states/others/overview/saga";
import invoiceSaga from "states/others/invoice/saga";
import fileManagerSaga from "states/others/common_file_manager/saga";

import actions from "./auth/login/actions";

// Here you can include all the saga which you write for components
export default function* rootSaga() {
  yield all([
    authSaga(), //auth sagas
    signupSaga(),
    requestResetSaga(),
    resetSaga(),
    accountSaga(),
    userStatisticsSaga(),
    updateUserWithOnboardingDataSaga(),
    organizationSaga(), //building sagas
    propertySaga(),
    buildingOwnerSaga(),
    buildingManagerSaga(),
    buildingFireManagerSaga(),
    buildingPreparednessSaga(),
    buildingTenantsSaga(),
    buildingGroupSaga(),
    buildingFireConceptsSaga(),
    buildingFireAlarmSaga(),
    buildingInstallationEquipmentSaga(),
    buildingInstallationEmergencySaga(),
    buildingInstallationOtherSaga(),
    buildingNewTenantsSaga(),
    buildingSupervisoryReportSaga(),
    buildingConformityReportSaga(),
    buildingThermographyReportSaga(),
    buildingVentilationSaga(),
    buildingPressSaga(),
    buildingFireDrawingSaga(),
    buildingConditionSaga(),
    buildingContactSaga(),
    buildingSmokeHatchSaga(),
    buildingSprinklerSaga(),
    buildingFireFilesSaga(),
    buildingChecklistSaga(),
    buildingThemeDeviationSaga(),
    buildingThemeCheckSaga(),
    roleSaga(), //cms sagas
    planSaga(),
    themeSaga(),
    cmsExtinquishingEquipmentSaga(),
    cmsCentralTypeSaga(),
    cmsCoverageAreaSaga(),
    cmsConnectionSaga(),
    cmsOtherInstallationSaga(),
    cmsLawsSaga(),
    cmsBuildingCheckpointsSaga(),
    cmsExerciseCategorySaga(),
    cmsPositionSaga(),
    cmsInformationSaga(),
    cmsPackagesSaga(),
    cmsServiceProviderSaga(),
    cmsFireAlarmRequirementAccordingSaga(),
    customerSaga(), //crm sagas
    subscriptionSaga(),
    ticketSaga(),
    crmExercise(),
    crmExerciseUser(),
    vendorSaga(),
    crmPlanSaga(),
    settingDueDateSaga(), // setting sagas
    taskSaga(), // workflow sagas
    deviationSaga(),
    taskCommentSaga(),
    googleCalendarSaga(),
    serviceCallSaga(),
    yearlyControlSaga(),
    userInvitationSaga(), //user sagas
    userNotificationSaga(),
    overviewSaga(), //other sagas
    invoiceSaga(),
    fileManagerSaga(),
  ]);
}

export function* callApi(apiFunction, ...args) {
  try {
    // yield call(() => getCustomRequest('sanctum/csrf-cookie'));
    const response = yield call(apiFunction, ...args);
    handleSuccessfulResponse(response);
    return response.data;
  } catch (error) {
    if (error.response.status === 401) yield put({ type: actions.LOGOUT });
    if (error.response.data.error.includes("createdBy"))
      yield put({ type: actions.LOGOUT });
    handleError(error);
    throw error;
  }
}

function handleSuccessfulResponse(response) {
  if (response.status === 200 && response.data.message) {
    showNotification("success", "Success!", response.data.message);
  }
}

function handleError(error) {
  if (error.response) {
    const errorHandlerMapping = {
      401: () =>
        showNotification(
          "error",
          "No Authorization",
          "You did not authorizate"
        ),
      404: () => {
        // const errorMessages = error.response.data.message;
        // showNotification(
        //   'warning',
        //   '404 feil',
        //   `Det er ingen gyldige data. Opprett nye data.`
        // );
      },
      403: () => {
        // const errorMessages = error.response.data.message;
        // showNotification(
        //   'warning',
        //   '403 feil',
        //   `Det er ingen gyldige data. Opprett nye data.`
        // );
      },
      500: () => {
        console.log("error message", error.response.data.error);
        if (error.response.data.error.includes("createdBy")) {
          showNotification(
            "error",
            "No Authorization",
            "You did not authorizate"
          );
        } else {
          showNotification(
            "error",
            "Midlertidig serverproblem",
            "Vi opplever for øyeblikket noen tekniske problemer. Våre ingeniører jobber med saken, og vi håper å ha det løst snart. Vennligst prøv igjen senere."
          );
        }
      },
      422: () => {
        const validationErrors = error.response.data.message;
        const errorMessages = Object.values(validationErrors).flat().join("\n");

        showNotification(
          "error",
          "Validering mislyktes",
          `Det ser ut til at det var et problem med informasjonen som ble gitt: ${errorMessages} Vennligst sjekk og prøv igjen.`
        );
      },
    };
    const errorHandler =
      errorHandlerMapping[error.response.status] ||
      (() =>
        showNotification(
          "error",
          "Uventet feil",
          `Oops, noe gikk galt: ${error.response.data.message}. Vennligst prøv igjen, eller kontakt support hvis problemet vedvarer.`
        ));
    errorHandler();
  }
}

function showNotification(type, title, message) {
  notification[type]({
    duration: 5,
    message: title,
    description: message,
  });
}
