const actions = {
  FETCH_COVERAGE_AREA: "FETCH_COVERAGE_AREA",
  FETCH_COVERAGE_AREA_SUCCESS: "FETCH_COVERAGE_AREA_SUCCESS",
  FETCH_COVERAGE_AREA_FAILURE: "FETCH_COVERAGE_AREA_FAILURE",

  ADD_COVERAGE_AREA: "ADD_COVERAGE_AREA",
  ADD_COVERAGE_AREA_SUCCESS: "ADD_COVERAGE_AREA_SUCCESS",
  ADD_COVERAGE_AREA_FAILURE: "ADD_COVERAGE_AREA_FAILURE",

  UPDATE_COVERAGE_AREA: "UPDATE_COVERAGE_AREA",
  UPDATE_COVERAGE_AREA_SUCCESS: "UPDATE_COVERAGE_AREA_SUCCESS",
  UPDATE_COVERAGE_AREA_FAILURE: "UPDATE_COVERAGE_AREA_FAILURE",

  DELETE_COVERAGE_AREA: "DELETE_COVERAGE_AREA",
  DELETE_COVERAGE_AREA_SUCCESS: "DELETE_COVERAGE_AREA_SUCCESS",
  DELETE_COVERAGE_AREA_FAILURE: "DELETE_COVERAGE_AREA_FAILURE",
};

export default actions;
