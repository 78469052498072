import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { postRequest } from "utils/axios-client";

function* createToken(action) {
  try {
    const response = yield callApi(() => postRequest('update_google_calendar_refresh_token', action.payload));

    yield put({
      type: actions.CREATE_TOKENS_SUCCESS,
      payload: response.data,
    });

  } catch (error) {
    yield put({ type: actions.CREATE_TOKENS_FAILURE });
  }
}

function* createEvent(action) {
  try {
    yield callApi(() => postRequest('create_google_calendar_event', action.payload));

    yield put({
      type: actions.CREATE_EVENT_SUCCESS,
    });
  }
  catch (error) {
    yield put({ type: actions.CREATE_EVENT_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.CREATE_TOKENS, createToken)]);
  yield all([takeLatest(actions.CREATE_EVENT, createEvent)]);
}
