const actions = {
  FETCH_REQUIREMENT_ACCORDING: "FETCH_REQUIREMENT_ACCORDING",
  FETCH_REQUIREMENT_ACCORDING_SUCCESS: "FETCH_REQUIREMENT_ACCORDING_SUCCESS",
  FETCH_REQUIREMENT_ACCORDING_FAILURE: "FETCH_REQUIREMENT_ACCORDING_FAILURE",

  ADD_REQUIREMENT_ACCORDING: "ADD_REQUIREMENT_ACCORDING",
  ADD_REQUIREMENT_ACCORDING_SUCCESS: "ADD_REQUIREMENT_ACCORDING_SUCCESS",
  ADD_REQUIREMENT_ACCORDING_FAILURE: "ADD_REQUIREMENT_ACCORDING_FAILURE",

  UPDATE_REQUIREMENT_ACCORDING: "UPDATE_REQUIREMENT_ACCORDING",
  UPDATE_REQUIREMENT_ACCORDING_SUCCESS: "UPDATE_REQUIREMENT_ACCORDING_SUCCESS",
  UPDATE_REQUIREMENT_ACCORDING_FAILURE: "UPDATE_REQUIREMENT_ACCORDING_FAILURE",

  DELETE_REQUIREMENT_ACCORDING: "DELETE_REQUIREMENT_ACCORDING",
  DELETE_REQUIREMENT_ACCORDING_SUCCESS: "DELETE_REQUIREMENT_ACCORDING_SUCCESS",
  DELETE_REQUIREMENT_ACCORDING_FAILURE: "DELETE_REQUIREMENT_ACCORDING_FAILURE",
};

export default actions;
