const actions = {
  FETCH_BUILDING_THEME_CHECK: "FETCH_BUILDING_THEME_CHECK",
  FETCH_BUILDING_THEME_CHECK_SUCCESS: "FETCH_BUILDING_THEME_CHECK_SUCCESS",
  FETCH_BUILDING_THEME_CHECK_FAILURE: "FETCH_BUILDING_THEME_CHECK_FAILURE",

  ADD_BUILDING_THEME_CHECK: "ADD_BUILDING_THEME_CHECK",
  ADD_BUILDING_THEME_CHECK_SUCCESS: "ADD_BUILDING_THEME_CHECK_SUCCESS",
  ADD_BUILDING_THEME_CHECK_FAILURE: "ADD_BUILDING_THEME_CHECK_FAILURE",

  UPDATE_BUILDING_THEME_CHECK: "UPDATE_BUILDING_THEME_CHECK",
  UPDATE_BUILDING_THEME_CHECK_SUCCESS: "UPDATE_BUILDING_THEME_CHECK_SUCCESS",
  UPDATE_BUILDING_THEME_CHECK_FAILURE: "UPDATE_BUILDING_THEME_CHECK_FAILURE",

  DELETE_BUILDING_THEME_CHECK: "DELETE_BUILDING_THEME_CHECK",
  DELETE_BUILDING_THEME_CHECK_SUCCESS: "DELETE_BUILDING_THEME_CHECK_SUCCESS",
  DELETE_BUILDING_THEME_CHECK_FAILURE: "DELETE_BUILDING_THEME_CHECK_FAILURE",

  RESET_BUILDING_THEME_CHECK: "RESET_BUILDING_THEME_CHECK",
};

export default actions;
