import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest, putRequest } from "utils/axios-client";

function* getrole() {
  let payload = {};

  try {
    const response = yield callApi(() => getRequest('cms/role'));
    const responesePermission = yield callApi(() => getRequest('cms/permission'));
    const reponseRolePermission = yield callApi(() => getRequest('cms/role-permission'));

    payload = { roles: response.data, permissions: responesePermission.data, role_permission: reponseRolePermission.data };

    yield put({
      type: actions.GETROLE_SUCCESS,
      payload: payload,
    });
  } catch (error) {
    yield put({ type: actions.GETROLE_FAILURE });
  }
}

function* addrole(action) {
  try {
    const response = yield callApi(() => postRequest('cms/role', action.payload))
    yield put({
      type: actions.ADDROLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.ADDROLE_FAILURE });
  }
}

function* editRole(action) {
  try {
    const response = yield callApi(() => putRequest('cms/role', action.payload))
    yield put({
      type: actions.EDITROLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.EDITPERMISSION_SUCCESS });
  }
}


function* addPermission(action) {
  try {
    const response = yield callApi(() => postRequest('cms/permission', action.payload))
    yield put({
      type: actions.ADDPERMISSION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.ADDPERMISSION_FAILURE });
  }
}

function* editPermission(action) {
  try {
    const response = yield callApi(() => putRequest('cms/permission', action.payload))
    yield put({
      type: actions.EDITPERMISSION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.EDITPERMISSION_FAILURE });
  }
}

function* addRolePermission(action) {
  try {
    const response = yield callApi(() => postRequest('cms/role-permission', action.payload))
    yield put({
      type: actions.ADDROLEPERMISSION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.ADDROLEPERMISSION_FAILURE });
  }
}


export default function* rootSaga() {
  yield all([takeLatest(actions.GETROLE, getrole)]);
  yield all([takeLatest(actions.ADDROLE, addrole)]);
  yield all([takeLatest(actions.ADDPERMISSION, addPermission)]);
  yield all([takeLatest(actions.ADDROLEPERMISSION, addRolePermission)]);
  yield all([takeLatest(actions.EDITPERMISSION, editPermission)]);
  yield all([takeLatest(actions.EDITROLE, editRole)]);
}
